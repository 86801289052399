import React, { useState } from "react";
import { Group, Radio } from "@mantine/core";
import Cropper from "react-easy-crop";
import { Button } from "antd";

function ImageCropper({ image, onCropDone, onCropCancel }) {
  // Define state variables
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState();

  // Callback when cropping is completed
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    // Store the cropped area in pixels
    setCroppedArea(croppedAreaPixels);
  };

  // Callback when the user changes the aspect ratio
  const onAspectRatioChange = (value) => {
    setAspectRatio(value);
  };

  return (
    <div
      className="cropper"
      style={{ position: "relative", width: "100%", height: "70vh" }}
    >
      <div>
        {/* Image Cropper component */}
        <Cropper
          image={image}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: "100%",
              height: "80%",
              backgroundColor: "#fff",
            },
          }}
        />
      </div>

      <div
        className="action-btns"
        style={{
          position: "absolute",
          bottom: "0",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Aspect ratio selection */}
        <Group onChange={(event) => onAspectRatioChange(event.target.value)}>
          <Radio label="1:1" value={1 / 1} name="ratio" />
          <Radio label="5:4" value={5 / 4} name="ratio" />
          <Radio label="4:3" value={4 / 3} name="ratio" />
          <Radio label="3:2" value={3 / 2} name="ratio" />
          <Radio label="5:3" value={5 / 3} name="ratio" />
          <Radio label="16:9" value={16 / 9} name="ratio" />
          <Radio label="3:1" value={3 / 1} name="ratio" />
        </Group>

        {/* Buttons for canceling or applying the crop */}
        <div
          className="btn-container !pt-5"
          style={{ display: "flex", gap: "0.5rem" }}
        >
          <Button className="btn btn-outline" onClick={onCropCancel}>
            Cancel
          </Button>

          <Button
            className="btn"
            onClick={() => {
              onCropDone(croppedArea);
            }}
          >
            Crop & Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ImageCropper;
