import { configureStore } from "@reduxjs/toolkit";
import {
  primeUserSlice,
  userLoginSlice,
} from "../Slice/UserLogin/UserLoginSlice";
import { selectedTypeSlice } from "../Slice/SelectedType/SelectedTypeSlice";
import { userDataSlice } from "../Slice/userData/userDataSlice";

export const store = configureStore({
  reducer: {
    userLogin: userLoginSlice.reducer,
    selectedType: selectedTypeSlice.reducer,
    userData: userDataSlice.reducer,
    primeUser: primeUserSlice.reducer,
  },
});
