import React from "react";

// CSS
import "./BackDropFilter.css";

// Mantine Core
import { Button, Flex, Text, Title } from "@mantine/core";

// Tabler ICOns
import { ArrowRight } from "tabler-icons-react";

// Images
import QrCode from "../../Assets/Footer.png";

// Mantine Media Query
import { useMediaQuery } from "@mantine/hooks";

const BackDropFilter = () => {
  // Media Query
  const media1200px = useMediaQuery("(max-width:1400px)");
  const media650px = useMediaQuery("(max-width:650px)");

  return (
    <div className="backdropfilter-container-dhiyanesh">
      <div className="backdropfilter-container-withimage">
        <div className="backdropfilter-container-image">
          <img src={QrCode} alt="QRCOde" />
        </div>
        <div className="backdropfilter-container">
          <div className="backdropfilter-container-content">
            <Title order={media1200px ? 3 : 1}>Get started</Title>
            <Text>
              Super easy & highly customizable
              <br />
              static or dynamic QR codes.
            </Text>
          </div>
          <div className="backdropfilter-container-button">
            <Button radius="md" size={media650px ? "xs" : "lg"}>
              <Flex gap={"0.3rem"} align={"center"}>
                <Text size="xs">Sign up</Text>
                <ArrowRight size={media650px && "1rem"} />
              </Flex>
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottom: "2px solid black",
          marginBottom: "1rem",
          marginTop: "-0.4rem",
        }}
      ></div>
    </div>
  );
};

export default BackDropFilter;
