import React, { useEffect, useRef, useState, memo } from "react";
import QRCodeStyling from "qr-code-styling";
import { DeleteFilled, DownloadOutlined, EditFilled } from "@ant-design/icons";
import { Button } from "antd";

const CustomVcard = (properties) => {
  const { res, color, bg } = properties;

  const qrCodeRef = useRef(null);
  const qrCodeInstanceRef = useRef(null);
  //   const [dummy, setDummy] = useState(false);

  useEffect(() => {
    qrCodeInstanceRef.current = new QRCodeStyling({
      width: 200,
      height: 200,
      data: res.data,
      dotsOptions: JSON.parse(res.qr_style).dotsOptions,
      backgroundOptions: JSON.parse(res.qr_style).backgroundOptions,
      image: JSON.parse(res.qr_style).image,
    });

    qrCodeInstanceRef.current.append(qrCodeRef.current);
    qrCodeInstanceRef.current.update({
      //   width: "90%",
      //   height: 200,
      data: res.data,
      dotsOptions: JSON.parse(res.qr_style).dotsOptions,
      backgroundOptions: JSON.parse(res.qr_style).backgroundOptions,
      image: JSON.parse(res.qr_style).image,
    });
  }, [res.data]);

  const handleDownload = () => {
    qrCodeInstanceRef.current.download({ extension: "png" });
  };

  return (
    <div className="!w-[90%] havala flex flex-col gap-y-2">
      <div className="shadow-inner border-2 border-inherit">
        <div ref={qrCodeRef} className="!w-full"></div>
      </div>
      <Button
        block
        style={{ background: `${bg}` }}
        className={`vcard3_button `}
        onClick={handleDownload}
      >
        <p style={{color:color}}>Download</p>
      </Button>
    </div>
  );
};

export default CustomVcard;
