import React from "react";

// Components
import ServicesComponents from "../ServicesComponents/ServicesComponents";

const RefundPolicy = () => {
  // Scroll to TOP
  window.scrollTo(0, 0);
  // Arry of Lists
  const listArray = [
    {
      list: [
        `1. Annual Plans– 7 days`,
        `2. Lifetime Plans – 30 days`,
        `Weboney holds the right to cancel or suspend any service at any time. If the cancellation or suspension was made because of the violation of our TOS, no refund would be provided. Refund is only applicable for Managed shared QR Generator users only incase they cancel services within the 7 days. You can cancel your account or service within 7 days of purchase to get the refund. `,
        `You may cancel services by sending a support ticket or by using the cancellation form within your client area. If you cancel your account and your payments continue to recur for any reason such as:.`,
        `1. By fault of Weboney staff`,
        `2. By fault of the credit card processor`,
        `3. By fault of any other payment collection company, we are to be held free of liability for any overdraft fees that are issued by your financial institution.`,
        `4. All Sales done with promotions are final and are not covered under No Questions Asked Refund Policy. However, a refund will be issued if the mistake is from Weboney’s end.`,
        `
    Our 7 days refund policy is only applicable to Managed Shared QR Generating plans only. The refund policy is terminated if purchases are made with promotions.`,
        `Sales made during Black Friday / Cyber Monday or such events are not applicable for refunds until any mistake was there from our end.`,
      ],
    },
  ];

  return (
    <div>
      <ServicesComponents listArray={listArray} title={"Refund Policy"} />
    </div>
  );
};

export default RefundPolicy;
