import axios from "axios";

// Server
import config from "../../Server/Server";

export const getUser = () => {
  return axios.get(`${config.baseAPI}/all_users.php`);
};

export const registerUser = async (body) => {
  return await axios.post(`${config.baseAPI}/register.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const loginUser = (body) => {
  return axios.post(`${config.baseAPI}/login.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createUser = async (body) => {
  return await axios.post(`${config.baseAPI}/create_user.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updatePaymentUser = async (body) => {
  return await axios.post(`${config.baseAPI}/create_user.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getUserDataById = async ({ queryKey }) => {
  const [_, id] = queryKey;
  return await axios.get(`${config.baseAPI}/fetch_user.php?id=${id}`);
};

export const updateUserDownloadCount = async (id) => {
  return await axios.post(`${config.baseAPI}/user_download_count.php`, id);
};

export const sendEmail = (body) => {
  return axios.post(`${config.baseAPI}/send_mail.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};


export const updateVcardStatus = async (formData) => {
  return await axios.post(`${config.baseAPI}/update_vcard_status.php`, formData);
};