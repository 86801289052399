import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../../helper/QRtypes";

const AboutmeForm = () => {
  return (
    <Form.Item
      className="w-[500px] text-area20"
      label="Enter about yourself"
      rules={[basicRules("Enter about yourself")]}
      name={"business_about_me"}
    >
      <Input.TextArea rows={10} placeholder="Enter about yourself." />
    </Form.Item>
  );
};

export default AboutmeForm;
