import React from "react";
import { Form, Input, Select } from "antd";
import { basicRules } from "../../../helper/QRtypes";

const WifiForm = () => {
  return (
    <>
      <Form.Item
        className="w-[400px]"
        label="Wifi Name (SSID)"
        rules={[basicRules("Wifi Name")]}
        name={"wifi_name"}
      >
        <Input rows={10} placeholder="Wifi Name" className="!h-[100px]" />
      </Form.Item>
      <Form.Item
        className="w-[400px]"
        label="Encryption"
        rules={[basicRules("Select encryption type")]}
        name={"wifi_entype"}
      >
        <Select className="!border-2 !rounded-lg">
          <Select.Option value="WEP">WEP</Select.Option>
          <Select.Option value="WPA/WPA2">WPA/WPA2</Select.Option>
          <Select.Option value="none">none</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className="w-[400px]"
        label="Password"
        rules={[basicRules("Password")]}
        name={"wifi_password"}
      >
        <Input rows={10} placeholder="Wifi Password" className="!h-[100px]" />
      </Form.Item>
    </>
  );
};

export default WifiForm;
