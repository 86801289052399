import React from "react";

// CSS
import "./LandingPage.css";

// Components
import Navbar from "../../Layouts/Navbar/Navbar";
import WelcomeBanner from "../../Components/WelcomeBanner/WelcomeBanner";
import Cardstype from "../../Components/Cardstype/Cardstype";
import Static_DynamicQrCode from "../../Components/Statcic&DynamicQrCode/Static_DynamicQrCode";
import QrTypes from "../../Components/QrTypes/QrTypes";
import BackDropFilter from "../../Components/BackDropFilter/BackDropFilter";

// Images
import EditQR from "../../Assets/WelcomeImage/1723.png";

// Mantine Core
import { Group } from "@mantine/core";

// Cards Data
import { cardState } from "../../StateHandler/State/CardState/CardState";
import { Static_DynamicQrCodeData } from "../../StateHandler/State/Static&DynamicQrCode/Static_DynamicQrCode";

// Layouts
import Footer from "../../Layouts/Footer/Footer";

const LandingPage = () => {
  // Scroll to TOP
  window.scrollTo(0, 0);

  return (
    <div>
      <div className="app-nav-bar">
        <div
          style={{
            position: "sticky",
            top: "0",
            backgroundColor: "white",
            zIndex: "1234",
          }}
        >
          <Navbar />
        </div>
        <div style={{ margin: "2rem 0 0rem 0" }}>
          <WelcomeBanner value={"Multi"} btn={true} />
        </div>
        <div className="welcome-edit-qr-image">
          <img src={EditQR} alt="Edit Qr Code" />
        </div>
        <Group mt={"2rem"} mb={"6rem"}>
          <Cardstype data={cardState} />
        </Group>
        <Static_DynamicQrCode data={Static_DynamicQrCodeData} />
        <QrTypes />
        <div style={{ margin: "2rem 0 3rem 0" }}>
          <BackDropFilter />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
