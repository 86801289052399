import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../../helper/QRtypes";

const BasicInfo = () => {
  return (
    <div className="flex flex-wrap gap-4">
      <Form.Item
        className="w-[200px]"
        label="First Name"
        rules={[basicRules("Enter First Name")]}
        name={"business_first_name"}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        className="w-[200px]"
        label="Last Name"
        rules={[basicRules("Enter Your Last Name")]}
        name={"business_last_name"}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        className="w-[200px]"
        label="Phone Number"
        rules={[basicRules("Enter Your Phone Number")]}
        name={"business_phone_number"}
      >
        <Input placeholder="Phone Number" />
      </Form.Item>
      <Form.Item
        className="w-[200px]"
        label="Email Address"
        rules={[basicRules("Enter Your Email Address")]}
        name={"business_email_address"}
      >
        <Input placeholder="Email Address" />
      </Form.Item>

      <Form.Item
        className="w-[200px]"
        label="Company"
        rules={[basicRules("Enter Your Company Name")]}
        name={"business_company_name"}
      >
        <Input placeholder="Company Name" />
      </Form.Item>
      <Form.Item
        className="w-[200px]"
        label="Job Title"
        rules={[basicRules("Enter Your Job Title")]}
        name={"business_job_title"}
      >
        <Input placeholder="Job Title" />
      </Form.Item>
      <Form.Item
        className="w-[200px]"
        label="Website Url"
        rules={[basicRules("Enter Your Job Title")]}
        name={"business_web_url"}
      >
        <Input placeholder="Website Url" />
      </Form.Item>
    </div>
  );
};

export default BasicInfo;
