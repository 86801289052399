import { toast } from "react-toastify";

let options = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    
};

export const errorNotification = (message) => toast.error(message, options);
export const successNotification = (message) => toast.success(message, options);
