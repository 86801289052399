import { Form, Image, Input, Select, Upload } from "antd";
import _ from "lodash";

import React from "react";

const Mediaoptions = (properties) => {
  const { setQrStyle, qrstyle, handleUpload, qr_images,handleRemoveImage } = properties;
  const handleChnage = (e) => {
    if (e?.target?.files[0]) {
      handleUpload(e, "qr_logo");
    }
  };
  
  return (
    <div className="flex items-center gap-x-4">
      <Form.Item name="qr_image">
        {qr_images ? (
          <div className="!rounded-ms relative w-[100px] group h-[100px] !border-none center_div  cursor-pointer ">
            <Image
              src={qr_images}
              alt=""
              className="!w-[100px] !h-[100px] !rounded-lg"
            />

            <div
              onClick={() => {
                handleRemoveImage("qr_logo");
              }}
              className="text-white center_div invisible group-hover:visible !font-Poppins cursor-pointer absolute left-0 w-[100px] rounded-b-lg h-[20px] bg-red-500 -bottom-1"
            >
              Remove
            </div>
          </div>
        ) : (
          <Upload
            listType="picture-card"
            onChange={_.debounce((e) => {
              handleUpload(e, "qr_logo");
            }, 500)}
            maxCount={1}
            showUploadList={false}
          >
            +
          </Upload>
        )}
      </Form.Item>
    </div>
  );
};

export default Mediaoptions;
