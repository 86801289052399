import {
  addProject,
  allProjects,
  deleteProject,
  fetchProject,
  updateProject,
} from "../../Config/Quries/Project/ProjectQueries";

import { ThemeIcon } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { CircleCheck, X } from "tabler-icons-react";

export const TotalProjects = async (user_id, setProjectData) => {
  var body = {
    user_id: user_id,
  };
  allProjects(body)
    .then((result) => {
      setProjectData(result.data);
    })
    .catch((err) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Fetching users Project Data",
      });
    });
};

// Create New Project
export const AddProjects = async (
  user_id,
  projDet,
  close,
  setProjDet,
  setRefetchProjectData
) => {
  const { project_name, project_color } = projDet;
  const body = {
    project: project_name,
    colorcode: project_color,
    user_id: user_id,
  };
  addProject(body)
    .then((result) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="green">
            <CircleCheck color="green" />
          </ThemeIcon>
        ),
        message: "New Project Created Successfully",
      });
      setProjDet({
        ...projDet,
        project_name: "",
        project_color: "",
      });
      setRefetchProjectData(false);
      close();
    })
    .catch((err) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Creating New Project",
      });
    });
};
export const UpdateProjects = async (
  editData,
  editclose,
  setEditData,
  setRefetchProjectData
) => {
  var body = {
    id: editData.id,
    project: editData.project_name,
    colorcode: editData.project_color,
  };
  updateProject(body)
    .then((result) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="green">
            <CircleCheck color="green" />
          </ThemeIcon>
        ),
        message: "Project Updated Successfully",
      });
      setRefetchProjectData(false);
      setEditData({
        ...editData,
        id: "",
        project_name: "",
        project_color: "",
      });
      editclose();
    })
    .catch((err) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Updating Project",
      });
    });
};
export const FetchProjects = async (id) => {
  var body = {
    id: id,
  };
  fetchProject(body).then((result) => {});
};
export const DeleteProjects = async (id, setRefetchProjectData) => {
  var body = {
    id: id,
  };
  deleteProject(body)
    .then((result) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="green">
            <CircleCheck color="green" />
          </ThemeIcon>
        ),
        message: "Project Deleted Successfully",
      });
      setRefetchProjectData(false);
    })
    .catch((err) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Deleting Project",
      });
    });
};
