import { createSlice } from "@reduxjs/toolkit";
import { userDataState } from "../../State/userData/userData";

export const userDataSlice = createSlice({
  name: "userData",
  initialState: userDataState,
  reducers: {
    setUserData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUserData } = userDataSlice.actions;
