import { createSlice } from "@reduxjs/toolkit";
import {
  primeUserState,
  userLoginState,
} from "../../State/UserLogin/UserLoginState";

export const userLoginSlice = createSlice({
  name: "userLogin",
  initialState: userLoginState,
  reducers: {
    setUserLogin: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const primeUserSlice = createSlice({
  name: "primseUser",
  initialState: primeUserState,
  reducers: {
    setPrimeUser: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUserLogin } = userLoginSlice.actions;
export const { setPrimeUser } = primeUserSlice.actions;
