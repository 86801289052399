import React from "react";

// CSS
import "./Footer.css";

// Images
import BrandLogo from "../../Assets/BrandLogo.png";

// Mantine Core
import { Center, Text } from "@mantine/core";
import { Link } from "react-router-dom";

const Footer = () => {
  const listData = [
    {
      title: "Contact us",
      path: "/contact-us",
    },
    {
      title: "Terms of Service",
      path: "/terms-of-services",
    },
    {
      title: "Privacy Policy",
      path: "/privacy-policy",
    },
    {
      title: "Refund Policy",
      path: "/refund-policy",
    },
    {
      title: "Software by Weboney",
      path: "www.weboney.in",
    },
  ];
  return (
    <div>
      <div className="footer-container">
        <div className="footer-container-logo">
          <Center>
            <Link to={"/"}>
              <img src={BrandLogo} alt="Brand_Logo" />
            </Link>
          </Center>
        </div>
        <div>
          <Text mt={"lg"}>Copyright © 2023 Weboney.</Text>
        </div>
        <div className="footer-links">
          <ul>
            {listData.map((value, index) => {
              return (
                <li key={index}>
                  {value.title === "Software by Weboney" ? (
                    <a
                      style={{ textDecoration: "none", color: "#1357be" }}
                      href="https://www.weboney.com"
                      target="_blank"
                    >
                      {value.title}
                    </a>
                  ) : (
                    <Text component={Link} to={value.path}>
                      {value.title}
                    </Text>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Footer;
