import React, { useState, useRef } from "react";

// CSS
import "./Contact.css";

// Mantine Core
import {
  Button,
  Card,
  Input,
  Text,
  Textarea,
  Title,
  ThemeIcon,
} from "@mantine/core";

// Layouts
import Navbar from "../../Layouts/Navbar/Navbar";
import Footer from "../../Layouts/Footer/Footer";

// Tabler ICons
import { InfoCircle, CircleCheck, X } from "tabler-icons-react";

// EMail Js
import emailjs from "@emailjs/browser";

// Mantine Notification
import { showNotification } from "@mantine/notifications";

const Contact = () => {
  // Scroll Top
  window.scrollTo(0, 0);

  // Form Ref
  const form = useRef();

  // State Value
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  //   Email Funtion
  const handleSendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_7yqdmar",
        "template_lyxmp8m",
        form.current,
        "s8FmRwNIUuiQqeUow"
      )
      .then((res) => {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="green">
              <CircleCheck color="green" />
            </ThemeIcon>
          ),
          message: "Email Send Successfully",
        });
        setFormData({
          ...formData,
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      })
      .catch((err) => {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "Error Sending Email",
        });
      });
  };
  return (
    <div>
     <div
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: "white",
          zIndex: "1234",
        }}
      >
        <Navbar />
      </div>
      <div className="contact-container">
        <Title style={{ color: "#1357be" }} pb={"xl"} pt={"xl"}>
          Contact us <InfoCircle />
        </Title>
        <div className="contact-container-form">
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <form ref={form}>
              <Input.Wrapper pb={"lg"} label={<Text pb={"sm"}>Name</Text>}>
                <Input
                  name="user_name"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    });
                  }}
                  value={formData.name}
                  placeholder="Name"
                />
              </Input.Wrapper>
              <Input.Wrapper pb={"lg"} label={<Text pb={"sm"}>Email</Text>}>
                <Input
                  name="user_mail"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    });
                  }}
                  value={formData.email}
                  placeholder="Email Address"
                />
              </Input.Wrapper>
              <Input.Wrapper pb={"lg"} label={<Text pb={"sm"}>Subject</Text>}>
                <Input
                  name="user_subject"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      subject: e.target.value,
                    });
                  }}
                  value={formData.subject}
                  placeholder="Subject"
                />
              </Input.Wrapper>
              <Input.Wrapper pb={"lg"} label={<Text pb={"sm"}>Message</Text>}>
                <Textarea
                  name="user_message"
                  rows={3}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      message: e.target.value,
                    });
                  }}
                  value={formData.message}
                  placeholder="Type your query"
                />
              </Input.Wrapper>
              <Button
                onClick={handleSendEmail}
                style={{ backgroundColor: "#1357be" }}
                mb={"lg"}
                mt={"lg"}
                w={"100px"}
              >
                Submit
              </Button>
            </form>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
