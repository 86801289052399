import axios from "axios";

// Server
import config from "../../Server/Server";

export const getAllUserCount = () => {
  return axios.get(`${config.baseAPI}/all_user_count.php`);
};

export const getAllUserQr = () => {
  return axios.get(`${config.baseAPI}/all_qr.php`);
};

export const getAllUserLinks = () => {
  return axios.get(`${config.baseAPI}/all_links.php`);
};

export const getAllUserPayments = () => {
  return axios.get(`${config.baseAPI}/all_payment.php`);
};
