import React, { useEffect, useState } from "react";
import { Form, Input, Divider, Collapse, Spin } from "antd";
import { basicRules } from "../../../helper/QRtypes";

// icons
import { LuStepForward } from "react-icons/lu";
import BasicInfo from "./business/BasicInfo";
import AddressInfo from "./business/AddressInfo";
import ImagesInfo from "./business/ImagesInfo";
import AboutmeForm from "./business/AboutmeForm";
import ServicesForm from "./business/ServicesForm";
import TestimonialsForm from "./business/TestimonialsForm";
import BusinessHoursForm from "./business/BusinessHoursForm";
import SocialMediaForm from "./business/SocialMediaForm";

const BusinessForm = (properties) => {
  const {
    businessImages,
    setBusinessImages,
    handleUpload,
    currentId,
    setCurrentId,
    formData,
    handleBusinessHoursChange,
    handleRemoveImage,
    loading,
    setLoading,
  } = properties;

  const getItems = () => [
    {
      key: "1",
      label: "Basic Info",
      children: <BasicInfo />,
    },
    {
      key: "2",
      label: "Address",
      children: <AddressInfo />,
    },
    {
      key: "3",
      label: "Images",
      children: (
        <ImagesInfo
          businessImages={businessImages}
          setBusinessImages={setBusinessImages}
          handleUpload={handleUpload}
          currentId={currentId}
          setCurrentId={setCurrentId}
          formData={formData}
          handleRemoveImage={handleRemoveImage}
        />
      ),
    },
    {
      key: "4",
      label: "About Me",
      children: <AboutmeForm />,
    },
    {
      key: "5",
      label: "Business Hours",
      children: (
        <BusinessHoursForm
          handleBusinessHoursChange={handleBusinessHoursChange}
          formData={formData}
        />
      ),
    },
    {
      key: "6",
      label: "Services",
      children: (
        <ServicesForm
          handleUpload={handleUpload}
          currentId={currentId}
          setCurrentId={setCurrentId}
          formData={formData}
          handleRemoveImage={handleRemoveImage}
        />
      ),
    },
    {
      key: "7",
      label: "Testimonials",
      children: (
        <TestimonialsForm
          handleUpload={handleUpload}
          currentId={currentId}
          setCurrentId={setCurrentId}
          formData={formData}
          handleRemoveImage={handleRemoveImage}
        />
      ),
    },
    {
      key: "8",
      label: "Social Media",
      children: <SocialMediaForm />,
    },
  ];

  return (
    <Spin spinning={loading} className="w-full">
      <Collapse
        collapsible={"disabled"}
        bordered={false}
        defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
        expandIcon={({ isActive }) => (
          <LuStepForward rotate={isActive ? 90 : 0} />
        )}
        items={getItems()}
        className="!bg-white"
      />
    </Spin>
  );
};

export default BusinessForm;
