import React from "react";
import {
  FaExternalLinkAlt,
  FaRegBuilding,
  FaRegCopy,
  FaRegSave,
} from "react-icons/fa";
import { PiSuitcaseSimpleBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import {
  Image,
  Card,
  Modal,
  Form,
  Input,
  DatePicker,
  TimePicker,
  Button,
  QRCode,
} from "antd";
import _ from "lodash";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { RxDotFilled } from "react-icons/rx";
import Vector from "../../Assets/vcard03.png";
import { useState } from "react";
import CustomDisplay from "../../Pages/SavedQr/CustomDisplay";
import CustomVcard from "./CustomVcard";

const Vcard3 = (properties) => {
  const {
    handleSaveVCard,
    vCardData,
    base_URL,
    location,
    handleOkay,
    handleApoinmentFinish,
    handleCopy,
    downloadQRCode,
    form,
    form1,
    contact,
    social,
    read,
    setRead,
    copy,
    setCopy,
    reads,
    setReads,
    handleRead,
    vCardDataUI,
    getSocialIcon,
    getAMPM,
  } = properties.data;

  return (
    <div className="w-screen min-h-screen flex flex-col vcard3 gap-y-6 items-center !bg-white !text-black pb-10 !font-Poppins">
      {/* hero */}
      <div className="center_div flex-col  w-full items-center gap-y-2">
        <div
          style={{
            background: `url(${base_URL}/${_.get(vCardDataUI, "business_banner_image", "")})`,
          }}
          className={`w-[100%] h-[30vh] relative !bg-no-repeat bg-center !bg-cover   `}
        >
          <img src={Vector} alt="" className="w-full absolute bottom-0  h-[100px]" />
        </div>
        <div className="w-[100px] h-[100px]  -mt-[110px] ml-5 z-50  border-white">
          <Image src={`${base_URL}/${_.get(vCardDataUI, "business_profile_image", "")}`} alt="" className="!w-[100px] !h-[100px] border-4 rounded-lg border-white" />
        </div>

        <div className=" !text-vcard3PrimaryText items-center z-50 flex flex-col gap-y-2 py-2">
          <p className="text-xl font-bold capitalize !font-Poppins !text-ellipsis !line-clamp-1">
            {_.get(vCardDataUI, "business_first_name", "")}&nbsp;
            {_.get(vCardDataUI, "business_last_name", "")}
          </p>
          <p className="text-md flex items-center !text-ellipsis gap-x-2 capitalize text-black">
            <PiSuitcaseSimpleBold />
            {_.get(vCardDataUI, "business_job_title", "")}
          </p>
          <p className="text-md !text-ellipsis flex items-center gap-x-2 capitalize text-black">
            <FaRegBuilding />
            {_.get(vCardDataUI, "business_company_name", "")}
          </p>
        </div>
        {/* social */}
        <div className="px-4 flex  gap-x-5 pt-2  gap-y-2">
          {_.get(vCardDataUI, "social_media", "").map((res, index) => {
            return (
              <Link to={res.to} target="_blank" key={index} className="size-[25px]  text-white shadow-2xl bg-vcard3PrimaryText rounded center_div">
                {getSocialIcon(res.social)[0].icon}
              </Link>
            );
          })}
        </div>

        <span className="!leading-loose  text-black text-center  !text-sm px-4 pt-2">
          {_.get(vCardDataUI, "business_about_me", "")?.slice(0, read ? _.get(vCardDataUI, "business_about_me", "").length : 200)}
          &nbsp;
          <span
            onClick={() => {
              setRead(!read);
            }}
            className={`!border-none  ${_.get(vCardDataUI, "business_about_me", "").length > 200 ? "visible" : "visible"} !bg-transparent !text-sm !text-vcard3PrimaryText`}
          >
            Read {!read ? "more" : "less"}
          </span>
        </span>
      </div>
      {/* Contact */}
      <div className="vcard3_heading text-center">Contact US</div>
      <div className="flex flex-col w-[90%]  items-center  mx-auto">
        {contact.map((res, index) => {
          return (
            <div key={index} className="!w-full  border-2 gap-x-2 p-2 text-black  border-white flex relative items-start">
              <div className="rounded-md center_div bg-vcard3PrimaryText size-[30px]">
                <res.icon className="!text-xl !text-white " />
              </div>
              <div className=" flex flex-col gap-y-1 ">
                <p className="!text-ellipsis  !line-clamp-1">{res.title}</p>
                <p className="!text-sm !leading-loose !text-ellipsis  !font-normal !font-sans text-start whitespace-pre-line">{res.subTitle}&nbsp;</p>
              </div>
            </div>
          );
        })}
      </div>
      {/* services  */}
      <div className="vcard3_heading">Our Services</div>
      <div className="w-full flex items-center min-h-[20vh] ">
        <Swiper modules={[Pagination, Autoplay]} className="mySwiper pt-10  w-[100%]" autoplay={true} slidesPerView={2} spaceBetween={10} loop centeredSlides>
          {_.get(vCardDataUI, "services", []).map((res, index) => {
            return (
              <SwiperSlide key={index} className="!w-[80%]">
                <div className="!w-full h-[320px] relative py-2 bg-white shadow-inner border-2 rounded-lg border-white">
                  <div className="center_div w-full">
                    <img src={`${base_URL}/${res.img}`} alt="" className="!h-[100px]  right-0 !w-[100px] !object-cover rounded-full" />
                  </div>
                  <div className="p-2 flex flex-col gap-y-2">
                    <p className="!text-[16px] pt-2 text-black font-bold tracking-wide flex items-center ">
                      <RxDotFilled className="!text-black !text-2xl" />
                      &nbsp;
                      <p className="!text-ellipsis  !line-clamp-1">{res.name}</p>
                    </p>
                    <p className="!text-sm !leading-loose text-black pl-2  whitespace-pre-line">
                      {res.description.slice(0, 150)}&nbsp;{" "}
                      <span
                        className="!text-[10px] !text-vcard3PrimaryText"
                        onClick={() => {
                          handleRead(res);
                        }}
                      >
                        Read more
                      </span>
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {/* Testimonials */}
      <div className="vcard3_heading">Textimonials</div>
      <div className="w-full flex items-center min-h-[20vh] ">
        <Swiper modules={[Pagination, Autoplay]} className="mySwiper  w-[100%]" autoplay={true} slidesPerView={2} spaceBetween={10} loop centeredSlides>
          {_.get(vCardDataUI, "testimonials", []).map((res, index) => {
            return (
              <SwiperSlide key={index} className="!w-[80%]">
                <div className="!w-full h-[200px] bg-white  shadow-inner border-2 rounded-lg border-white flex items-start p-2 relative">
                  <div className=" flex flex-col gap-y-2">
                    <p className="!text-[16px] pt-2 font-bold tracking-wide flex items-center ">
                      &nbsp;
                      <p className="!text-ellipsis  !line-clamp-1">{res.name}</p>
                    </p>
                    <p className="!text-sm !leading-loose text-black pl-2 text-start whitespace-pre-line">
                      {res.review.slice(0, 100)}&nbsp;
                      <span
                        className="!text-[10px] !text-vcard3PrimaryText"
                        onClick={() => {
                          handleRead(res, "testimonials");
                        }}
                      >
                        Read more
                      </span>
                    </p>
                  </div>
                  <img src={`${base_URL}/${res.img}`} alt="" className="!size-[50px]  !object-cover rounded-full" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {/* Business Hours */}
      <div className="vcard3_heading">Business Hours</div>
      <div className="w-[90%] bg-white min-h-[100px]  shadow-md border-2 border-vcard3PrimaryText py-2 flex flex-col gap-y-5  rounded-lg divide-y-1">
        {_.get(vCardDataUI, "business_hours", []).map((res, index) => {
          return (
            <div className="flex items-center justify-between px-4">
              <div className=" tracking-wide !text-center text-black uppercase">{res.day?.slice(0, 3)}</div>

              {res.status ? (
                <div className="flex gap-x-2 text-black">
                  <div>{getAMPM(res.from)}</div>
                  <p>-</p>
                  <div>{getAMPM(res.to)}</div>
                </div>
              ) : (
                <div>Closed</div>
              )}
            </div>
          );
        })}
      </div>
      {/* Make an Appoinment */}
      <div className="vcard3_heading">Make an Appoinment</div>
      <div className="w-[90%] min-h-[100px] appoinment">
        <Form form={form1} className="w-full mx-auto" layout="vertical" onFinish={handleApoinmentFinish}>
          <Form.Item label={<span className="!text-sm  !font-sans !text-black">Name</span>} name="name" rules={[{ required: true, message: "Enter name" }]}>
            <Input placeholder="name" className="!w-full !bg-white !shadow-inner !h-[35px] placeholder:!text-gray-200 placeholder:!font-sans" />
          </Form.Item>
          <Form.Item label={<span className="!text-sm  !font-sans !text-black">Mobile Number</span>} name="contact_number" rules={[{ required: true, message: "Enter mobile number" }]}>
            <Input placeholder="mobile number" className="!w-full !bg-white !shadow-inner !h-[35px] placeholder:!text-gray-200 placeholder:!font-sans" />
          </Form.Item>

          <Form.Item
            label={
              <span className="!text-sm  !font-sans !text-black">
                <span className="text-red-500">*</span> Select Date
              </span>
            }
            name="appoinment_date"
          >
            <DatePicker
              onOk={(e) => {
                handleOkay(e, "appoinment_date");
              }}
              needConfirm
              className="!w-full !bg-white !h-[35px] placeholder:!text-black !shadow-inner"
              format={"DD/MM/YYYY"}
            />
          </Form.Item>
          <Form.Item
            label={
              <span className="!text-sm  !font-sans !text-black">
                <span className="text-red-500">*</span> Select Time
              </span>
            }
            name="appoinment_time"
          >
            <TimePicker
              onOk={(e) => {
                handleOkay(e, "appoinment_time");
              }}
              className="!w-full !bg-white !h-[35px] placeholder:!text-black !shadow-inner"
              format={"h:mm a"}
            />
          </Form.Item>
          <Form.Item className="pt-4">
            <Button block htmlType="submit" className="vcard3_button !bg-vcard3PrimaryText">
              <h1 className="!text-white">Make an Appoinment</h1>
            </Button>
          </Form.Item>
        </Form>
      </div>
      {/* QR Download */}
      <div className="vcard3_heading">My QR Code</div>
      <div className="flex !w-full items-center flex-col gap-y-4 ">
        <div id="myqrcode" className="!w-full center_div">
          {/* <QRCode
            value={`${_.get(vCardData, "data", "")}`}
            className="!bg-white !size-[90%] !border-4 !border-vcard3PrimaryText"
          /> */}
          <CustomVcard res={vCardData} color={"#fff"} bg={"#ea580c"} />
        </div>
      </div>
      {/* create Card */}
      <div className="vcard3_heading">Create Your Card</div>
      <div className="w-[90%] bg-white h-[50px] shadow-inner rounded-lg  center_div gap-x-4">
        <p className="!text-sm">https://www.qrcodeweboney.in/</p>
        <Link to="https://www.qrcodeweboney.in/" target="_blank">
          <FaExternalLinkAlt className="!text-black !" />
        </Link>
      </div>
      {/* share & download */}
      <div className="!flex items-center justify-center w-screen flex-col gap-y-4">
        <Button onClick={handleCopy} className={`!h-[50px] justify-between  !font-Poppins tracking-wide ${copy ? "!border-green-500 !text-green-500" : "!border-vcabg-vcard3PrimaryText !text-vcabg-vcard3PrimaryText"}   !bg-white center_div w-[90%] !text-[16px]`}>
          <p className="!text-black">Share</p>
          <FaRegCopy className="!text-black" />
        </Button>
        <Button className="vcard3_button !bg-vcard3PrimaryText flex items-center gap-x-2 w-[90%] justify-between" onClick={handleSaveVCard}>
          <h1 className="text-white"> Save To Contact</h1>
          <FaRegSave />
        </Button>
      </div>
    </div>
  );
};

export default Vcard3;
