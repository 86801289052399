import { qrAdd, vcardAdd } from "../../../Config/Quries/Qrcode/QrcodeQuries";

// Mantine Core
import { ThemeIcon } from "@mantine/core";

// Notification
import { showNotification } from "@mantine/notifications";

// Tabler ICons
import { CircleCheck, X } from "tabler-icons-react";

export const QrcodeAddControl = (
  qrDetails,
  customQr,
  datatype,
  navigate,
  setVcardId
) => {
  const { data } = customQr;
  const formData = new FormData();
  formData.append("image", customQr.image.path);
  formData.append("qrdetails", JSON.stringify(qrDetails));
  formData.append("qrstyle", JSON.stringify(customQr));
  formData.append("qrtype", datatype);
  formData.append("data", data);
  formData.append("userID", localStorage.getItem("user_id"));
  formData.append("vcard_image", qrDetails.vcard.image);
  formData.append("vcardLogoImg", qrDetails.vcard.logoimage);
  formData.append("vcard_banner_image", qrDetails.vcard.vcard_banner_image);
  vcardAdd(formData)
    .then((result) => {
     
      setVcardId(result.data.id); 
    
      if (result.data.status === "error") {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "Upload UserImage Less than 1mb",
        });
      } else {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="green">
              <CircleCheck color="green" />
            </ThemeIcon>
          ),
          message: "Data saved",
        });
      }
    })
    .catch((err) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Occured in Saving Data",
      });
    });
};
export const QrcodeAddControl1 = (qrDetails, customQr, datatype, navigate) => {
  const { data } = customQr;
  const formData = new FormData();
  formData.append("image", customQr.image.path);
  formData.append("qrdetails", JSON.stringify(qrDetails));
  formData.append("qrstyle", JSON.stringify(customQr));
  formData.append("qrtype", datatype);
  formData.append("data", data);
  formData.append("userID", localStorage.getItem("user_id"));
  formData.append("vcard_image", qrDetails.vcard.image);
  formData.append("vcardLogoImg", qrDetails.vcard.logoimage);
  qrAdd(formData)
    .then((result) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="green">
            <CircleCheck color="green" />
          </ThemeIcon>
        ),
        message: "Data saved",
      });
    })
    .catch((err) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Occured in Saving Data",
      });
    });
};
