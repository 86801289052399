import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// CSS
import "./WelcomeBanner.css";

// Mantine Core
import { Button, Center, Flex, Title } from "@mantine/core";

// Tabler ICons
import { CirclePlus, UserPlus } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";

// Redux State

const WelcomeBanner = ({ value, btn, reader, para }) => {
  const media340Query = useMediaQuery("(max-width:340px)");

  const [userDetails, setUserDetails] = useState("");

  useEffect(() => {
    if (localStorage.getItem("user_name")) {
      setUserDetails(localStorage.getItem("user_name"));
    }
  });

  return (
    <div>
      <div className="welcome-banner-container">
        <div className="welcome-banner-container-title">
          <Title order={1}>
            {value}
            <span className="welcome-banner-container-title-qr"> QR</span>{" "}
            Code&nbsp;
            {reader ? reader : "Generator"}
          </Title>
          {para ? (
            <p>{para}</p>
          ) : (
            <p>Generate easy & customizable QR codes in minutes.</p>
          )}
        </div>
        {btn ? (
          <Center mb={"lg"}>
            <div className="welcome-banner-container-btns">
              <Flex gap={"1rem"}>
                <Button
                  className="welcome-btn-style"
                  component={Link}
                  to={"/generate-qr-code"}
                  radius={"md"}
                  size="lg"
                  style={{ backgroundColor: "#1357be" }}
                >
                  <Flex align={"center"} gap={"0.2rem"}>
                    <CirclePlus
                      size={media340Query ? "1rem" : "1.5rem"}
                      className="welcome-banner-icon"
                    />
                    Generate QR codes
                  </Flex>
                </Button>
                {!userDetails && (
                  <Button
                    className="welcome-btn-style"
                    component={Link}
                    to={"/register"}
                    radius={"md"}
                    size="lg"
                    style={{ backgroundColor: "#1357be" }}
                  >
                    <Flex align={"center"} gap={"0.2rem"}>
                      <UserPlus
                        size={media340Query ? "1rem" : "1.5rem"}
                        className="welcome-banner-icon"
                      />
                      Sign up
                    </Flex>
                  </Button>
                )}
              </Flex>
            </div>
          </Center>
        ) : (
          ""
        )}
      </div>
     
    </div>
  );
};

export default WelcomeBanner;
