import React from "react";

// Mantine Core
import { Input, Text, Textarea } from "@mantine/core";

const TextQr = ({ qrDetails, setQrDetails }) => {
  return (
    <div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper label={<Text pb={"sm"}>Text Content</Text>}>
          <Textarea
            radius={"md"}
            autosize
            minRows={4}
            onChange={(e) => {
              setQrDetails({
                ...qrDetails,
                text: {
                  ...qrDetails.text,
                  text_content: e.target.value,
                },
              });
            }}
            value={qrDetails.text.text_content}
          />
        </Input.Wrapper>
      </div>
    </div>
  );
};

export default TextQr;
