import { createSlice } from "@reduxjs/toolkit";
import { selectedType } from "../../State/SelectedType/SelectedType";

export const selectedTypeSlice = createSlice({
  name: "SelectedType",
  initialState: selectedType,
  reducers: {
    setSelectedType: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setSelectedType } = selectedTypeSlice.actions;
