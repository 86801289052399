import React, { useEffect, useRef, useState, memo } from "react";
import QRCodeStyling from "qr-code-styling";
import { DeleteFilled, DownloadOutlined, EditFilled } from "@ant-design/icons";
import { Tag } from "antd";

const CustomDisplay = (properties) => {
  const { res, hanldeDeleteQrCode, handleEdit } = properties;

  const qrCodeRef = useRef(null);
  const qrCodeInstanceRef = useRef(null);
  //   const [dummy, setDummy] = useState(false);

  useEffect(() => {
    qrCodeInstanceRef.current = new QRCodeStyling({
      width: 200,
      height: 200,
      data: res.data,
      dotsOptions: JSON.parse(res.qr_style).dotsOptions,
      backgroundOptions: JSON.parse(res.qr_style).backgroundOptions,
      image: JSON.parse(res.qr_style).image,
    });

    qrCodeInstanceRef.current.append(qrCodeRef.current);
    qrCodeInstanceRef.current.update({
      width: 200,
      height: 200,
      data: res.data,
      dotsOptions: JSON.parse(res.qr_style).dotsOptions,
      backgroundOptions: JSON.parse(res.qr_style).backgroundOptions,
      image: JSON.parse(res.qr_style).image,
    });
  }, [res.data]);

  const handleDownload = () => {
    qrCodeInstanceRef.current.download({ extension: "png" });
  };

  return (
    <div>
      <div ref={qrCodeRef}></div>
      <Tag className="line-clamp-1 !mt-1 !bg-white !border-transparent !rounded-none  overflow-hidden text-ellipsis pl-2 !font-medium capitalize">{res.name}</Tag>

      <div className="w-[200px] flex justify-between gap-y-2 pt-4">
        <DownloadOutlined onClick={handleDownload} key="ellipsis" className="!cursor-pointer hover:text-blue-500" />
        <DeleteFilled
          onClick={() => {
            hanldeDeleteQrCode(res.id);
          }}
          key="setting"
          className="!cursor-pointer hover:!text-red-500"
        />
        <EditFilled
          onClick={() => {
            handleEdit(res);
          }}
          key="edit"
          className="!cursor-pointer hover:text-green-500"
        />
      </div>
    </div>
  );
};

export default CustomDisplay;
