import React, { useEffect, useRef, useState } from "react";
import "./admin.css";
import TopNavbar from "./navbar/TopNavbar";
import SideNavbar from "./navbar/SideNavbar";
import { IoAddOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { TbUsersGroup } from "react-icons/tb";
import {
  Tag,
  Table,
  Modal,
  Form,
  Input,
  Select,
  Button,
  Spin,
  QRCode,
  Avatar,
  Card,
} from "antd";
import {
  createUser,
  getUser,
  registerUser,
  sendEmail,
} from "../Config/Quries/User/userQuries";
import _ from "lodash";
import { successNotification } from "../Components/Notification/notify";
import { TbSend } from "react-icons/tb";
import { CiMail } from "react-icons/ci";
import moment from "moment";
import {
  getAllUserLinks,
  getAllUserPayments,
  getAllUserQr,
} from "../Config/Quries/superAdmin/adminQuery";

// icons
import { MdOutlineCurrencyRupee } from "react-icons/md";

const Payment = () => {
  const [Qrs, setAllQrs] = useState([]);

  const [open, close] = useState(false);
  const [loading, setLoading] = useState(false);

  const [datas, setDatas] = useState("");

  const [form] = Form.useForm();

  var currentDate = new Date();

  const ref = useRef();

  // Add one year to the current date

  const fetchData = async () => {
    try {
      const result = await getAllUserPayments();
      const values = _.get(result, "data.payments", []);

      setAllQrs(values);
    } catch (err) {}
  };
  console.log(Qrs);
  useEffect(() => {
    fetchData();
  }, []);

  const handleSendMail = async (value) => {
    setLoading(true);
    const body = {
      toemail: value.email,
      toname: value.firstname,
      password: value.alise_passwords,
    };
    const result = await sendEmail(body);
    successNotification("Successfully Mail Sended");
    setLoading(false);
  };

  const handleDownload = (value) => {
    try {
      close(true);
      setDatas(value);
      setTimeout(() => {
        const canvas = document
          .getElementById("myqrcode")
          ?.querySelector("canvas");
        console.log(canvas);
        if (canvas) {
          const url = canvas.toDataURL();
          const a = document.createElement("a");
          a.download = "QRCode.png";
          a.href = url;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }, [4000]);

      //   close(false);
      //   setDatas("");
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "firstname",
      render: (name, data, index) => {
        return <div className="capitalize">{index + 1}</div>;
      },
      width: 100,
      align: "center",
    },
    {
      title: "User Details",
      dataIndex: "firstname",
      render: (name, data) => {
        return (
          <div className="!flex !items-center !justify-start">
            <QRCode
              value={_.get(data, "data", "")}
              className="!w-[100px] !h-[100px]"
            />
            <div className="flex flex-col items-start px-2">
              <div className="text-xl capitalize" l>
                {name}
              </div>
              <div>{_.get(data, "email", "")}</div>
            </div>
          </div>
        );
      },
    },

    {
      title: "Payment Type",
      dataIndex: "payment_type",
      align: "center",
      render: (link_name) => {
        return <span className="">{link_name}</span>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "center",
      render: (amount) => {
        return <div className="flex justify-center">₹ {amount}</div>;
      },
    },
    {
      title: "start Date",
      dataIndex: "from_date",
      align: "center",
      render: (date) => {
        return (
          <div className="flex justify-center">
            {moment(date).format("llll")}
          </div>
        );
      },
    },
    {
      title: "Expire Date",
      dataIndex: "to_date",
      align: "center",
      render: (date) => {
        return (
          <div className="flex justify-center">
            {date === "∞" ? "∞" : moment(date).format("llll")}
          </div>
        );
      },
    },
  ];

  return (
    <div
      spning={true}
      className="flex w-screen h-screen overflow-hidden !bg-white"
    >
      <SideNavbar />
      <div className="flex flex-col">
        <TopNavbar />
        <div className="w-[85vw] px-10 py-10 pt-[100px] overflow-y-scroll">
          <div className="flex items-center justify-between pb-10">
            <h1 className="text-xl  font-bold  flex items-center gap-x-2">
              <MdOutlineCurrencyRupee className="!text-black" />
              All Payments
            </h1>
          </div>
          {/* table */}
          <Table
            ref={ref}
            loading={loading}
            columns={columns}
            dataSource={Qrs}
            pagination={{
              pageSize: 8,
              position: ["bottomRight"],
              size: "small",
            }}
          />
        </div>
      </div>
      <Modal
        destroyOnClose
        open={open}
        onCancel={() => {
          close(!open);
          setDatas("");
        }}
        footer={false}
        closable={false}
        id="myqrcode"
        className="!flex !items-center !justify-center "
      >
        <QRCode value={datas} className="!w-[400px] !h-[400px]" />
      </Modal>
    </div>
  );
};

export default Payment;
