import { Input, Text, Textarea } from "@mantine/core";
import React from "react";

const LocationQr = ({ qrDetails, setQrDetails }) => {
  return (
    <div>
      <div>
        <Input.Wrapper
          label={<Text pb={"sm"}>Location</Text>}
        >
          <Textarea
            autosize
            minRows={4}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  location: {
                    ...qrDetails.location,
                    location_address: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.location.location_address}
          />
        </Input.Wrapper>
      </div>
    </div>
  );
};

export default LocationQr;
