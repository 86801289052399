import React, { useEffect, useState } from "react";
import "./admin.css";
import TopNavbar from "./navbar/TopNavbar";
import SideNavbar from "./navbar/SideNavbar";
import { IoAddOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { TbUsersGroup } from "react-icons/tb";
import { Tag, Table, Modal, Form, Input, Select, Button, Spin } from "antd";
import {
  createUser,
  getUser,
  registerUser,
  sendEmail,
  updatePaymentUser,
} from "../Config/Quries/User/userQuries";
import _ from "lodash";
import { successNotification } from "../Components/Notification/notify";
import { TbSend } from "react-icons/tb";
import { CiMail } from "react-icons/ci";
import moment from "moment";

const Users = () => {
  const [users, setAllUsers] = useState([]);

  const [open, close] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  var currentDate = new Date();

  // Add one year to the current date

  const fetchData = async () => {
    try {
      const result = await getUser();
      const values = _.get(result, "data.users", []);
      let rest = values.filter((res) => {
        return res.role !== "admin";
      });
      setAllUsers(rest);
    } catch (err) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSendMail = async (value) => {
    setLoading(true);
    const body = {
      toemail: value.email,
      toname: value.firstname,
      password: value.alise_passwords,
    };
    const result = await sendEmail(body);
    successNotification("Successfully Mail Sended");
    setLoading(false);
  };

  const getIcon = (value) => {
    if (value === 1) {
      return (
        <Tag className="text-white text-center bg-gray-500 min-w-[60px]">
          Free
        </Tag>
      );
    } else if (value === 2) {
      return (
        <Tag className="bg-yellow-500 text-white min-w-[60px]">Annual</Tag>
      );
    } else if (value === 3) {
      return (
        <Tag className="bg-green-500 text-white min-w-[60px]">LifeTime</Tag>
      );
    }
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "firstname",
      render: (name, data, index) => {
        return <div className="capitalize">{index + 1}</div>;
      },
      width: 100,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "firstname",
      render: (name) => {
        return <div className="capitalize">{name}</div>;
      },
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 300,
    },
    {
      title: "Contact",
      dataIndex: "phone",
    },

    {
      title: "Payment Given",
      dataIndex: "payment_type",
      align: "center",
    },
    {
      title: "Download Limit",
      dataIndex: "download_limit",
      align: "center",
      render: (value, data) => {
        return (
          <span
            className={`${
              Number(value) === -1 ? "text-green-500" : "text-black"
            }`}
          >
            {Number(value) === -1 ? "∞" : value}
          </span>
        );
      },
    },
    {
      title: "Download Done",
      dataIndex: "download_achived",
      align: "center",
      render: (value, data) => {
        return (
          <span
            className={`${
              Number(value) === Number(_.get(data, "download_limit", 0))
                ? "text-red-600"
                : "text-black"
            }`}
          >
            {value}
          </span>
        );
      },
    },

    {
      ellipsis: true,
      width: 200,
      title: "Plan Started Date",
      dataIndex: "plan_started_date",
      render: (value, data) => {
        return <h1>{moment(value).format("lll")}</h1>;
      },
    },
    {
      ellipsis: true,
      width: 200,
      title: "Plan Expired Date",
      dataIndex: "plan_expire_date",
      fixed: "right",
      render: (value, data) => {
        return <h1>{value === "∞" ? "∞":moment(value).format("lll")}</h1>;
      },
    },
    {
      title: "Plan",
      dataIndex: "prime_user",
      align: "center",
      fixed: "right",
      render: (plan_type) => {
        return <span className="p-2">{getIcon(Number(plan_type))}</span>;
      },
    },
    {
      title: "Mail",
      fixed: "right",
      width: 150,
      dataIndex: "download_achived",
      align: "center",

      render: (value, data) => {
        return (
          <Tag
            onClick={() => {
              handleSendMail(data);
            }}
            className="flex px-3 py-1 !text-white !bg-color items-center gap-x-2 w-[100px] cursor-pointer justify-around"
          >
            Send Mail <TbSend />
          </Tag>
        );
      },
    },
  ];

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      let body = { ...values };
      body.otp = 1;
      body.payment_type = "offline";
      body.plan_start_date = new Date();
      

      if (values.prime_user === 1) {
        body.download_limit = 3;
      } else if (values.prime_user === 2) {
        body.download_limit = 100;
        body.amount = 999;
        body.plan_expire_date = new Date(
          currentDate.setFullYear(currentDate.getFullYear() + 1)
        );
      } else {
        body.download_limit = -1;
        body.amount = 5999;
        body.plan_expire_date = "∞"

      }
      const result = await createUser(body);
      // await updatePaymentUser(body);
      successNotification("Successfully User Registered");
      close(false);
      form.resetFields();
      fetchData();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div
      spning={true}
      className="flex w-screen h-screen overflow-hidden !bg-white"
    >
      <SideNavbar />
      <div className="flex flex-col">
        <TopNavbar />
        <div className="w-[85vw] px-10 py-10 pt-[100px] overflow-y-scroll">
          <div className="flex items-center justify-between pb-10">
            <h1 className="text-xl  font-bold  flex items-center gap-x-2">
              <TbUsersGroup className="!text-black" /> All Users
            </h1>
            <Tag
              onClick={() => {
                close(true);
              }}
              className="capitalize px-2 py-1 text-md font-bold  shadow-2xl cursor-pointer  rounded-md bg-color text-white"
            >
              create user
            </Tag>
          </div>
          {/* table */}
          <Table
            loading={loading}
            columns={columns}
            dataSource={users}
            pagination={{
              pageSize: 5,
              position: ["bottomRight"],
              size: "small",
            }}
            scroll={{ x: 2000 }}
          />
        </div>
      </div>
      <Modal
        title={
          <span className="bg-text-color uppercase">
            Create a new User Account
          </span>
        }
        open={open}
        onCancel={() => {
          close(false);
          form.resetFields();
        }}
        footer={false}
        closeIcon={false}
        destroyOnClose
      >
        <Form
          layout="vertical"
          onFinish={handleFinish}
          form={form}
          className="!pt-4"
        >
          <Form.Item
            name="firstname"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter user name",
              },
            ]}
          >
            <Input placeholder="Enter User Name" />
          </Form.Item>
          <Form.Item
            name={"email"}
            label="Email"
            rules={[
              {
                required: true,
                message: "Please enter user email",
              },
            ]}
          >
            <Input placeholder="Enter User Email" />
          </Form.Item>
          <Form.Item
            name={"password"}
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter user password",
              },
            ]}
          >
            <Input placeholder="Enter User Password" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Contact"
            rules={[
              {
                required: true,
                message: "Please enter user contact",
              },
            ]}
          >
            <Input placeholder="Enter User Contact" />
          </Form.Item>
          <Form.Item
            label={
              <div>
                {" "}
                <span className="text-red-400">*</span> Plan Type
              </div>
            }
            name={"prime_user"}
          >
            <Select>
              <Select.Option value={1}>Free</Select.Option>
              <Select.Option value={2}>Annual</Select.Option>
              <Select.Option value={3}>LifeTime</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              htmlType="submit"
              block
              className="bg-color !text-white !h-[40px]"
            >
              Create User
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Users;
