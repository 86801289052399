import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../helper/QRtypes";

const PhoneForm = () => {
  return (
    <>
      <Form.Item
        className="w-[400px]"
        label="Phone Number"
        rules={[basicRules("Enter Phone Number")]}
        name={"phone_phone_number"}
      >
        <Input placeholder="Enter Phone Number" />
      </Form.Item>
    </>
  );
};

export default PhoneForm;
