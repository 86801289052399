import React, { useState } from "react";
import { FaRegSave } from "react-icons/fa";

import useClipboard from "react-use-clipboard";
import config from "../../Config/Server/Server";
import _ from "lodash";
import { PiSuitcaseSimpleBold } from "react-icons/pi";
import { FaGlobeAmericas, FaLinkedin, FaRegBuilding, FaWhatsapp } from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
import { MdFacebook, MdOutlineMail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { GrLocation } from "react-icons/gr";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";
import { QRCode, Image, Card, Descriptions, Avatar, Form, Input, Button, notification, DatePicker, TimePicker, Tag } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { FaRegCopy } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { HiOutlineGlobeAlt } from "react-icons/hi";

import "swiper/css";
import "swiper/css/pagination";
import { FaExternalLinkAlt } from "react-icons/fa";
import { makeAppoinment, sendMessageFromVcardProfile } from "../../Config/Quries/Vacrd/VcardQuery";
import moment from "moment";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import CustomVcard from "./CustomVcard";

const Vcard1 = (properties) => {
  const { handleSaveVCard, vCardData, base_URL, location, handleOkay, handleApoinmentFinish, handleCopy, downloadQRCode, form, form1, contact, social, read, setRead, copy, setCopy, vCardDataUI, getSocialIcon, getAMPM } = properties.data;

  return (
    <div className="w-screen min-h-screen !bg-[#f7f7f7]  pb-10 flex flex-col gap-y-10 !font-Poppins">
      {/* <Hero /> */}
      <div className="center_div flex-col w-full ">
        <div
          style={{
            background: `url(${base_URL}/${_.get(vCardDataUI, "business_banner_image", "")})`,
          }}
          className={`w-[100%] h-[30vh] !bg-no-repeat bg-center !bg-cover !rounded-lg  !bg-white `}
        ></div>
        <div className="w-[150px] h-[150px]  rounded-full -mt-[80px] z-50">
          <Image src={`${base_URL}/${_.get(vCardDataUI, "business_profile_image", "")}`} alt="" className="!w-[150px] !h-[150px] !border-4 !rounded-lg border-white !object-cover" />
        </div>
        <div className="center_div py-4 text-black flex-col gap-y-3">
          <h1 className="text-3xl font-bold capitalize !font-Poppins">
            {_.get(vCardDataUI, "business_first_name", "")}&nbsp;
            {_.get(vCardDataUI, "business_last_name", "")}
          </h1>
          <h1 className="text-md font-semibold flex items-center gap-x-2 capitalize">
            {" "}
            <PiSuitcaseSimpleBold />
            {_.get(vCardDataUI, "business_job_title", "")}
          </h1>
          <h1 className="text-md font-semibold flex items-center gap-x-2 capitalize">
            {" "}
            <FaRegBuilding />
            {_.get(vCardDataUI, "business_company_name", "")}
          </h1>
        </div>
        {/* social */}
        <div className="center_div py-4 gap-x-4">
          {_.get(vCardDataUI, "social_media", "").map((res, index) => {
            return (
              <Link to={res.to} target="_blank" key={index} className="size-[25px]  text-white rounded-full gradient center_div">
                {getSocialIcon(res.social)[0].icon}
              </Link>
            );
          })}
        </div>
      </div>
      {/* <about me /> */}
      <div className="vcard1_header">
        <div className="vcard1_title -mt-4">About US</div>

        <div className="flex items-center justify-center flex-wrap pt-2 !text-sm gap-y-4 w-full px-5">
          <span className="!leading-loose text-center ">
            {_.get(vCardDataUI, "business_about_me", "")?.slice(0, read ? _.get(vCardDataUI, "business_about_me", "").length : 200)}
            &nbsp;
            <span
              onClick={() => {
                setRead(!read);
              }}
              className={`!border-none text-primaryColor ${_.get(vCardDataUI, "business_about_me", "").length > 200 ? "visible" : "visible"} !bg-transparent`}
            >
              Read {!read ? "more" : "less"}
            </span>
          </span>
        </div>
      </div>
      {/* <Services /> */}
      <div className="vcard1_header">
        <div className="vcard1_title">our Services</div>
        {/* services */}
        <div className="flex items-center justify-center flex-wrap pt-4 gap-y-4 w-full">
          <div className="w-full flex items-center min-h-[20vh] pt-4 ">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper  w-[100%]"
              autoplay={true}
              slidesPerView={2}
              spaceBetween={10}
              loop
              centeredSlides
            >
              {_.get(vCardDataUI, "services", []).map((res, index) => {
                return (
                  <SwiperSlide key={index} className="!w-[80%]  rounded-lg ">
                    <Card className=" !bg-white shadow-inner h-[400px]" key={index} cover={<img className="!h-[200px]" src={`${base_URL}/${res.img}`} />}>
                      <Card.Meta title={<div className="!text-[16px] !font-Poppins !text-ellipsis !w-[100px]">{res.name}</div>} description={<span className="line-clamp-5 text-black">{res.description}</span>} />
                    </Card>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
      {/* Testimonials */}
      <div className="vcard1_header">
        <div className="vcard1_title">Testimonials</div>
        <div className="w-full flex items-center min-h-[20vh] pt-4 ">
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper  w-[100%]"
            autoplay={true}
            slidesPerView={2}
            spaceBetween={10}
            loop
            centeredSlides
          >
            {_.get(vCardDataUI, "testimonials", []).map((res, index) => {
              return (
                <SwiperSlide key={index} className="!w-[80%]  rounded-lg">
                  <Card className="flex items-center !bg-white shadow-inner !w-full" key={index}>
                    <Card.Meta avatar={<Avatar src={`${base_URL}/${res.img}`} />} title={<div className="!text-[16px] !font-Poppins !overflow-ellipsis !w-[100px]">{res.name}</div>} description={<span className="line-clamp-4 text-black">{res.review}</span>} />
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      {/* business Hours */}
      <div className="vcard1_header">
        <div className="vcard1_title">Business Hours</div>
        <div className="w-screen bg-white min-h-[100px] flex flex-col gap-y-5 py-5 mt-4 divide-y-1">
          {_.get(vCardDataUI, "business_hours", []).map((res, index) => {
            return (
              <div className="flex items-center justify-between px-10">
                <div className="font-semibold  tracking-wide !text-center uppercase">{res.day?.slice(0, 3)}</div>

                {res.status ? (
                  <div className="flex gap-x-2 text-black">
                    <div>{getAMPM(res.from)}</div>
                    <p>-</p>
                    <div>{getAMPM(res.to)}</div>
                  </div>
                ) : (
                  <div>Closed</div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* make Appoinment */}
      <div className="vcard1_header">
        <div className="vcard1_title">Make An Appointment</div>
        <div className="w-full min-h-[100px] py-4  bg-white mt-4">
          <Form form={form1} className="w-[90%] mx-auto pt-4" layout="vertical" onFinish={handleApoinmentFinish}>
            <Form.Item label={<span className="!text-[16px] !font-Poppins">Name</span>} name="name" rules={[{ required: true, message: "Enter name" }]}>
              <Input placeholder="Full Name" className="!bg-white-500 !h-[35px] " />
            </Form.Item>
            <Form.Item label={<span className="!text-[16px] !font-Poppins">Mobile Number</span>} name="contact_number" rules={[{ required: true, message: "Enter mobile number" }]}>
              <Input placeholder="Mobile Number" className="!bg-white-500 " />
            </Form.Item>

            <Form.Item
              label={
                <span className="!text-[16px] !font-Poppins">
                  <span className="text-red-500">*</span> Select Date
                </span>
              }
              name="appoinment_date"
            >
              <DatePicker
                onOk={(e) => {
                  handleOkay(e, "appoinment_date");
                }}
                needConfirm
                className="!w-full !h-[39px] "
                format={"DD/MM/YYYY"}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="!text-[16px] !font-Poppins">
                  <span className="text-red-500">*</span> Select Time
                </span>
              }
              name="appoinment_time"
            >
              <TimePicker
                onOk={(e) => {
                  handleOkay(e, "appoinment_time");
                }}
                className="!w-full !h-[39px] "
                format={"h:mm a"}
              />
            </Form.Item>
            <Form.Item className="pt-4">
              <Button block htmlType="submit" className="!h-[50px] !bg-primaryColor !text-white">
                <h1 className="!text-white">Make</h1>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      {/* contact */}
      <div className="vcard1_header">
        <div className="vcard1_title">Contact US</div>
        <div className="flex flex-col w-[90%] gap-y-2 item-center pt-4  mx-auto">
          {contact.map((res, index) => {
            return (
              <Card className="flex items-center w-full !bg-white shadow-inner" key={index}>
                <Card.Meta avatar={<res.icon className="!text-2xl !text-primaryColor" />} title={<span className="!text-[16px] !font-Poppins">{res.title}</span>} description={res.subTitle} />
              </Card>
            );
          })}
        </div>
      </div>

      {/* <qr code /> */}
      <div className="vcard1_header">
        <div className="vcard1_title">My QR Code</div>

        <div className="flex !w-full items-center flex-col gap-y-4 pt-4">
          <div id="myqrcode" className="!w-full center_div">
            <CustomVcard res={vCardData} color={"#fff"} bg={"#1357be"} />
          </div>
        </div>
      </div>
      <div className="vcard1_header">
        <div className="vcard1_title">Create Your Card</div>
        <div className="w-[90%] bg-white h-[50px] shadow-inner rounded-lg mt-2 center_div gap-x-4">
          <p className="!text-sm">https://www.qrcodeweboney.in/</p>
          <Link to="https://www.qrcodeweboney.in/" target="_blank">
            <FaExternalLinkAlt className="!text-primaryColor !" />
          </Link>
        </div>
      </div>

      <div className=" !flex items-center justify-center w-screen flex-col gap-y-4">
        <Button onClick={handleCopy} className={`!h-[50px] justify-between !text-black !font-Poppins tracking-wide ${copy ? "!border-green-500 !text-green-500" : "!border-primaryColor !text-primaryColor"}   !bg-white !text-black center_div w-[90%] !text-[16px]`}>
          <p className="text-black">Share</p>
          <FaRegCopy className="!text-black" />
        </Button>
        <Button className="!h-[50px] !text-[16px] !font-Poppins  !bg-primaryColor justify-between tracking-wide !text-white center_div w-[90%]" onClick={handleSaveVCard}>
          <h1 className="text-white">Save To Contact</h1>
          <FaRegSave />
        </Button>
      </div>
    </div>
  );
};

export default Vcard1;
