import React, { useEffect, useState } from "react";

// Layout
import Navbar from "../../Layouts/Navbar/Navbar";

// Components
import WelcomeBanner from "../../Components/WelcomeBanner/WelcomeBanner";
import TypeBtns from "../../Components/TypeBtns/TypeBtns";
import Footer from "../../Layouts/Footer/Footer";
import QrCodeGenerator from "../../Components/QrCodeGenerator/QrCodeGenerator";

// Redux State
import { useSelector } from "react-redux";

const GenerateQrCode = () => {
  // Scroll to TOP
  window.scrollTo(0, 0);

  // Redux State
  const SelectedType = useSelector((state) => state.selectedType.value);
  const primeMember = useSelector((state) => state.primeUser.value);
  const userData = useSelector((state) => state.userData.value);

  // State
  const [premiumMember, setPremiumMember] = useState(false);

  // useEffect
  useEffect(() => {
    if (primeMember) {
      setPremiumMember(true);
    }
  }, [primeMember]);

  return (
    <div>
      <div className="">
        <Navbar />
      </div>
      <div style={{ margin: "2rem 0 0 0" }}>
        <WelcomeBanner value={"Multi"} />
      </div>
      <TypeBtns premiumMember={premiumMember} />
      <div style={{ marginTop: "2rem" }}>
        <QrCodeGenerator
          userData={userData}
          datatype={SelectedType.toUpperCase()}
        />
      </div>
      {/*
      <Footer /> */}
    </div>
  );
};

export default GenerateQrCode;
