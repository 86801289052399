import axios from "axios";
// Server
import config from "../../Server/Server";

export const qrAdd = async (formData) => {
  return await axios.post(`${config.baseAPI}/qr_code.php`, formData, {
    headers: {
    //   "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};
export const vcardAdd = async (formData) => {
  return await axios.post(`${config.baseAPI}/vcard_qr.php`, formData, {
    headers: {
    //   "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const UploadImage = async (formData) => {
  return await axios.post(`${config.baseAPI}/upload_image.php`, formData, {
    headers: {
    //   "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};
