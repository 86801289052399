import React, { useEffect, useState } from "react";
import DashboardImg from "../../Assets/icons/dashboard.png";
import {
  addDynLinks,
  addLink,
  allDynamicLinks,
  deleteDynLinks,
  updateDynLinks,
  updateLinksfuk,
} from "../../Config/Quries/DynamicLinks/DynamicLinksQuries";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Button, Form, Input, Modal, Table } from "antd";
import { basicRules } from "../../helper/QRtypes";
import { successNotification } from "../../Components/Notification/notify";
import { DeleteFilled, EditFilled } from "@ant-design/icons";

const DynamicLinks = () => {
  const userData = useSelector((state) => state.userData.value);
  const [myLinks, setMyLinks] = useState([]);
  const [open, setOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const fetchData = async () => {
    try {
      let formData = {
        user_id: _.get(userData, "id", ""),
      };
      const result = await allDynamicLinks(formData);
      setMyLinks(_.get(result, "data", []));
    } catch (err) {

    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await deleteDynLinks({ id: id });
      successNotification("Link Successfully Deleted");
      fetchData();
      setLoading(false);
    } catch (err) {
      setLoading(false);


    }
  };

  const handleEdit = (data) => {
    setUpdateId(data.id);

    form.setFieldsValue(data);
    setOpen(true);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      render: (value, allvalue, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "Link",
      dataIndex: "url_alias",
      render: (data, value) => {
        return (
          <a href={value.destination_url} target="_blank">
            {data}
          </a>
        );
      },
    },
    {
      title: "Note",
      dataIndex: "link_name",
    },
    {
      title: "Actions",
      align: "right",
      render: (data) => {
        return (
          <div className="flex items-center justify-end gap-x-3">
            <EditFilled
              className="!cursor-pointer hover:text-green-500"
              onClick={() => {
                handleEdit(data);
              }}
            />
            <DeleteFilled
              className="!cursor-pointer hover:text-red-500"
              onClick={() => {
                handleDelete(data.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      let formData = {
        ...values,
        user_id: _.get(userData, "id", ""),
      };
      if (updateId) {
        await updateLinksfuk(formData);
        successNotification("Link Successfully Updated");
        setUpdateId("");
      } else {
        await addLink(formData);
        successNotification("Link Successfully Created");
      }
      form.resetFields();
      setOpen(false);
      fetchData();
      setLoading(false);
    } catch (err) {
      setLoading(false);
     
    }
  };

  return (
    <div className="p-5 !font-Poppins">
      <div className="flex items-center justify-between shadow gap-x-2 bg-white w-full h-[50px] rounded">
        <div className="center_div gap-x-2">
          <img src={DashboardImg} alt="" className="!w-[50px]" />
          <h1 className="text-2xl font-bold up">Links</h1>
        </div>
        <div className="px-5">
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="min-w-[100px] px-3 py-1 bg-white text-primaryColor cursor-pointer center_div rounded shadow-inner"
          >
            Create
          </div>
        </div>
      </div>
      {/* body */}
      <div className="pt-2">
        <Table
          loading={loading}
          columns={columns}
          dataSource={myLinks}
          className="!shadow-sm"
        />
      </div>
      <Modal
        destroyOnClose
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
        open={open}
        closable={false}
        footer={false}
        title={updateId ? "Update Link" : "Create Link"}
      >
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Form.Item
            name="destination_url"
            label={"Destination URL"}
            rules={[basicRules("Enter Destination Url")]}
          >
            <Input placeholder="Enter Destination Url" />
          </Form.Item>

          <Form.Item
            name="link_name"
            label={"Alise Name"}
            rules={[basicRules("Enter Alise Name")]}
          >
            <Input placeholder="Enter Alise Name" />
          </Form.Item>
          <Form.Item
            name="url_alias"
            label={"Note"}
            className="text-area2 !w-full"
          >
            <Input.TextArea placeholder="Enter Note" className="!w-full" />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              block
              className="!bg-primaryColor !text-white !font-Poppins !h-[50px]"
            >
              {updateId ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DynamicLinks;
