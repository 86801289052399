import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// CSS
import "./Signin.css";

// Images
import Logo from "../../../Assets/BrandLogo.png";

// Mantaine Core
import {
  Center,
  Flex,
  Input,
  PasswordInput,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { loginUser } from "../../../Config/Quries/User/userQuries";
import { showNotification } from "@mantine/notifications";
import { CircleCheck, X } from "tabler-icons-react";

// lodash
import _ from "lodash";

// React Redux
import { useSelector } from "react-redux";
import { successNotification } from "../../../Components/Notification/notify";

const Signin = () => {
  // Scroll to TOP
  window.scrollTo(0, 0);
  // Navigate
  const navigate = useNavigate();

  // React Redux
  const userData = useSelector((state) => state.userData.value);

  // States
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  });

  const handleLogin = async () => {
    var userDetails = {
      email: loginDetails.username,
      password: loginDetails.password,
    };
    await loginUser(userDetails).then((result) => {
      if (result.data.status === "error") {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: result.data.message,
        });
      } else {
        successNotification("Start Your Journey");

        localStorage.setItem("user_id", result.data.userDetails.id);
        localStorage.setItem("user_name", result.data.userDetails.firstname);
        localStorage.setItem("user_email", result.data.userDetails.email);
        localStorage.setItem("user_vcard", result.data.userDetails.vcard_id);
        _.get(result, "data.userDetails.role", false)
          ? navigate("/")
          : navigate("/dashboard");
      }
    });
  };

  // Navigate to Home
  useEffect(() => {
    if (localStorage.getItem("user_email") && userData) {
      navigate("/");
    }
  }, [userData]);

  return (
    <div>
      <div className="login-div">
        <div className="login-div-card">
          <div className="login-div-card-logo-div">
            <Link to={"/"}>
              <img src={Logo} alt="website logo" />
            </Link>
          </div>
          <Center>
            <Title order={2} style={{ color: " #1357be" }}>
              Login
            </Title>
          </Center>
          <div className="login-div-card-form">
            <div className="login-div-card-form-individual">
              <Input.Wrapper label="Email">
                <Input
                  placeholder="Email"
                  onChange={(e) => {
                    setLoginDetails({
                      ...loginDetails,
                      username: e.target.value,
                    });
                  }}
                  value={loginDetails.username}
                />
              </Input.Wrapper>
            </div>
            <div className="login-div-card-form-individual">
              <Input.Wrapper label="Password">
                <PasswordInput
                  placeholder="Password"
                  onChange={(e) => {
                    setLoginDetails({
                      ...loginDetails,
                      password: e.target.value,
                    });
                  }}
                  value={loginDetails.password}
                />
              </Input.Wrapper>
            </div>
            <div className="login-div-card-form-individual-btn">
              <button onClick={handleLogin}>Signin</button>
            </div>
            <div className="login-div-card-form-individual-btn">
              <Center>
                <Flex gap={"0.5rem"}>
                  Don't have an account ?
                  <Text
                    component={Link}
                    to={"/register"}
                    style={{ color: "blue" }}
                  >
                    Sign up
                  </Text>
                </Flex>
              </Center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
