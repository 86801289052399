import { MdOutlineTextFields } from "react-icons/md";
import { FaLink } from "react-icons/fa6";
import { MdOutlineSms } from "react-icons/md";
import { TfiEmail } from "react-icons/tfi";
import { SiWhatsapp } from "react-icons/si";
import { IoVideocamOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { CiWifiOn } from "react-icons/ci";
import { IoCalendarOutline } from "react-icons/io5";
import { BsPersonVcard } from "react-icons/bs";
import { RiSecurePaymentLine } from "react-icons/ri";
import { MdOutlineFeedback } from "react-icons/md";
import { FaRegStar } from "react-icons/fa";
import { RiCoupon4Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { UploadImage } from "../Config/Quries/Qrcode/QrcodeQuries";
import {
  errorNotification,
  successNotification,
} from "../Components/Notification/notify";

// images
import Call from "../Assets/icons/call.png";
import Business from "../Assets/icons/contact.png";
import Coupon from "../Assets/icons/coupon.png";
import Event from "../Assets/icons/event.png";
import Facebook from "../Assets/icons/facebook.png";
import Facetime from "../Assets/icons/facetime.png";
import Gmail from "../Assets/icons/gmail.png";
import GoogleMap from "../Assets/icons/google.png";
import Payment from "../Assets/icons/payment.png";
import Pdf from "../Assets/icons/pdf.png";
import Ratings from "../Assets/icons/ratings.png";
import Sms from "../Assets/icons/sms.png";
import Text from "../Assets/icons/text.png";
import Url from "../Assets/icons/url.png";
import Whatsapp from "../Assets/icons/whatsapp.png";
import Wifi from "../Assets/icons/wifi.png";
import Review from "../Assets/icons/review.png";

export const QRTypes = [
  { id: 1, name: "Text", icon: Text },
  { id: 2, name: "Whatsapp", icon: Whatsapp },
  { id: 3, name: "Link", icon: Url },
  { id: 4, name: "SMS", icon: Sms },
  { id: 5, name: "Email", icon: Gmail },
  { id: 6, name: "Phone", icon: Call },
  { id: 7, name: "Facetime", icon: Facetime },
  { id: 8, name: "Location", icon: GoogleMap },
  { id: 9, name: "Wifi", icon: Wifi },
  { id: 10, name: "Event", icon: Event },
  { id: 11, name: "Business", icon: Business },
  { id: 12, name: "Payment", icon: Payment },
  { id: 12, name: "Google Review", icon: Review },
  // { id: 13, name: "Feedback", icon: Facebook },
  // { id: 14, name: "Ratings", icon: Ratings },
  // { id: 15, name: "Coupon", icon: Coupon },
  // { id: 16, name: "PDF", icon: Pdf },
];

export const basicRules = (msg) => {
  return {
    required: true,
    message: msg,
  };
};

export const handleBeforeUpload = (file) => {
  if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
    errorNotification(`
    Unsupported file type. Only PNG, JPEG, and JPG are allowed for upload.`);
    return true;
  } else if (file.size > 4000000) {
    errorNotification("The size of the image must not exceed 4 MB");
    return true;
  }
  return false;
};

export const handleImageUpload = async (formData) => {
  try {
    const results = await UploadImage(formData);
    return results;
  } catch (err) {}
};

