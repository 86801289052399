import { Input, Text } from "@mantine/core";
import React from "react";

const PhoneQr = ({ qrDetails, setQrDetails }) => {
  return (
    <div>
      <Input.Wrapper label={<Text pb={"sm"}>Phone Number</Text>}>
        <Input
          onChange={(e) => {
            {
              setQrDetails({
                ...qrDetails,
                phone: {
                  ...qrDetails.phone,
                  phone_number: e.target.value,
                },
              });
            }
          }}
          value={qrDetails.phone.phone_number}
        />
      </Input.Wrapper>
      {/* <Input.Wrapper pt={"lg"} label={<Text pb={"sm"}>Name</Text>}>
        <Input />
      </Input.Wrapper> */}
    </div>
  );
};

export default PhoneQr;
