import { Button, Drawer, Form, Input, Space } from "antd";
import Upload from "antd/es/upload/Upload";
import React from "react";
import { useState } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { message, Divider, Image } from "antd";
import {
  handleBeforeUpload,
  handleImageUpload,
} from "../../../../helper/QRtypes";
import {
  errorNotification,
  successNotification,
} from "../../../../Components/Notification/notify";
import _ from "lodash";
import config from "../../../../Config/Server/Server";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const ServicesForm = (properties) => {
  const {
    handleUpload,
    currentId,
    setCurrentId,
    props,
    formData,
    handleRemoveImage,
  } = properties;
  const [open, setOpen] = useState(false);

  let base_URL = config.baseAPI;

  // console.log(formData);
  return (
    <div className="relative">
      <Form.List name="services" className="flex">
        {(fields, { add, remove }) => (
          <>
            <div className="!w-full flex justify-end">
              <Form.Item className="!w-fit px-3">
                <Button onClick={() => add()} block icon={<PlusOutlined />} className="!bg-primaryColor !text-white !font-Poppins">
                  <span className="!text-white"> Add new</span>
                </Button>
              </Form.Item>
            </div>
            <div className="flex gap-x-4 flex-wrap gap-y-4">
              {fields.map(({ key, name, ...restField }, index) => (
                <>
                  <div className="flex items-start w-[300px]  gap-x-2 flex-col justify-start flex-wrap p-4 rounded-lg shadow-lg bg-white">
                    <div>
                      <Form.Item
                        label={<span>Service Image</span>}
                        rules={[
                          {
                            required: true,
                            message: "Upload Service Image",
                          },
                        ]}
                        className=""
                        name={[name, "img"]}
                        {...restField}
                      >
                        {_.get(formData, `services.[${index}].img`, "none") !== "none" && _.get(formData, `services.[${index}]`, "") !== undefined && typeof _.get(formData, `services.[${index}].img`, "none") !== "object" ? (
                          <div className="relative w-[200px] h-[100px] group">
                            <Image src={`${base_URL}/${_.get(formData, `services.[${index}].img`)}`} className="!w-[100px] !h-[100px] !rounded-lg" />
                            <div
                              onClick={() => {
                                handleRemoveImage("services", index);
                              }}
                              className="text-white center_div invisible group-hover:visible !font-Poppins cursor-pointer absolute left-0 w-[100px] rounded-b-lg h-[20px] bg-red-500 -bottom-1"
                            >
                              Remove
                            </div>
                          </div>
                        ) : (
                          <Upload
                            showUploadList={false}
                            onChange={_.debounce((e) => {
                              handleUpload(e, "services", index);
                            }, 500)}
                            maxCount={1}
                          >
                            <div className=" w-[100px] h-[100px] border border-dashed text-sm !rounded-2xl  center_div cursor-pointer ">+ image</div>
                          </Upload>
                        )}
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        label="Service Name"
                        {...restField}
                        name={[name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Enter Service Name",
                          },
                        ]}
                      >
                        <Input placeholder="Service Name" className="!w-[250px] !h-[100px] " />
                      </Form.Item>

                      <Form.Item
                        label="Service Description"
                        {...restField}
                        name={[name, "description"]}
                        rules={[
                          {
                            required: true,
                            message: "Enter Description",
                          },
                        ]}
                        className="text-area-service"
                      >
                        <Input.TextArea placeholder="Service Description" className="!w-[250px] !h-[300px]" />
                      </Form.Item>
                    </div>
                    <div onClick={() => remove(name)} className="!h-[30px] !w-[250px] relative duration-700 group transition-all   !bg-white  !center_div !shadow-inner cursor-pointer !font-Poppins">
                      <p className="text-black group-hover:text-white z-10">Remove</p>
                      <div className="group-hover:h-full w-0 group-hover:w-full left-0 transition-all duration-500 absolute bottom-0 bg-red-500 h-0"></div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ServicesForm;
