import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../helper/QRtypes";

const SMSForm = () => {
  return (
    <>
      <Form.Item
        className="w-[400px]"
        label="Phone Number"
        rules={[basicRules("Enter Phone Number")]}
        name={"sms_phone_number"}
      >
        <Input placeholder="Enter Phone Number" />
      </Form.Item>
      <Form.Item
        className="w-[400px] text-area"
        label="Pre-Filled Message"
        rules={[basicRules("Enter Message")]}
        name={"sms_message"}
      >
        <Input.TextArea
          rows={10}
          placeholder="Enter Message Here"
          className="!h-[100px]"
        />
      </Form.Item>
    </>
  );
};

export default SMSForm;
