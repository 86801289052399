import React, { useEffect, useState } from "react";
import { Avatar, Badge, Card, Divider, Image, QRCode, Tag } from "antd";
import { DeleteFilled, DownloadOutlined, EditFilled } from "@ant-design/icons";
import QR from "../../Assets/icons/qr.png";
import { useSelector } from "react-redux";
import config from "../../Config/Server/Server";
import { getSavedQrs } from "../../Config/Quries/Vacrd/VcardQuery";
import _ from "lodash";
import {
  errorNotification,
  successNotification,
} from "../../Components/Notification/notify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BasicLoading from "../../helper/BasicLoading";
import BasicQR from "../../Assets/BrandLogo/basicqr.png";
import CustomDisplay from "./CustomDisplay";

const SavedQr = () => {
  const userData = useSelector((state) => state.userData.value);
  const [myQrs, setMyQrs] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      let formData = {
        user_id: _.get(userData, "id", ""),
      };
      const result = await getSavedQrs(formData);
      setMyQrs(_.get(result, "data", []));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const hanldeDeleteQrCode = async (qrId) => {
    setLoading(true);

    const body = {
      id: qrId,
    };
    await axios
      .post(`${config.baseAPI}/delete_qrcode.php`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        successNotification("Qr Successfully Deleted");
        fetchData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        errorNotification("Failed to Delete");
      });
  };

  const handleEdit = (value) => {
    navigate("/dashboard/generate_qr", { state: value });
  };

  const downloadQRCode = () => {
    setLoading(true);
    const canvas = document.getElementById("myqrcode")?.querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setLoading(false);
    }
  };

  return (
    <div className="p-5 !font-Poppins">
      <div className="flex items-center shadow gap-x-2 bg-white w-full h-[50px] rounded">
        <img src={QR} alt="" className="!w-[50px]" />
        <h1 className="text-2xl font-bold up">Saved QR</h1>
      </div>

      <div className="flex flex-wrap gap-6  pt-2">
        {myQrs.map((res, index) => {
          return (
            <Badge.Ribbon text={res.type} placement="end">
              <div className="!w-[230px] !bg-white !shadow-lg !rounded-lg !flex flex-col items-center justify-center gap-2 py-4 !pt-8">
                <CustomDisplay
                  res={res}
                  hanldeDeleteQrCode={hanldeDeleteQrCode}
                  handleEdit={handleEdit}
                />
              </div>
            </Badge.Ribbon>
          );
        })}
      </div>
    </div>
  );
};

export default SavedQr;
