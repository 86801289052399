import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../helper/QRtypes";

const EvenetForm = () => {
  return (
    <div className="flex flex-wrap items-start gap-3 ">
      <Form.Item
        className="w-[400px]"
        label="Event Name"
        rules={[basicRules("Enter Event Name")]}
        name={"event_name"}
      >
        <Input placeholder="Enter Event Name" />
      </Form.Item>
      <Form.Item
        className="w-[400px]"
        label="GEO Location"
        rules={[basicRules("Enter GEO Location")]}
        name={"event_geo_location"}
      >
        <Input placeholder="Enter GEO Location" />
      </Form.Item>
    
      <Form.Item
        className="w-[400px]"
        label="Starting Date & Time"
        rules={[basicRules("Select Starting Date & Time")]}
        name={"event_start_date"}
      >
        <Input
          type="datetime-local"
          rows={10}
          placeholder="Select Starting Date & Time"
          className="!h-[100px]"
        />
      </Form.Item>
      <Form.Item
        className="w-[400px]"
        label="Ending Date & Time"
        rules={[basicRules("Select Ending Date & Time")]}
        name={"event_end_date"}
      >
        <Input
          type="datetime-local"
          rows={10}
          placeholder="Select Ending Date & Time"
          className="!h-[100px]"
        />
      </Form.Item>
      <Form.Item
        className="w-[400px] text-area"
        label="Notes"
        rules={[basicRules("Enter Notes")]}
        name={"event_notes"}
      >
        <Input.TextArea
          rows={10}
          placeholder="Enter Message Here"
          className="!h-[100px]"
        />
      </Form.Item>
    </div>
  );
};

export default EvenetForm;
