import React from "react";

// CSS
import "./FrequentlyAsked.css";

// Mantine Core
import { Accordion, Center, Title } from "@mantine/core";

// Mantine Media Query
import { useMediaQuery } from "@mantine/hooks";

const FrequentlyAsked = () => {
  // media Query
  const media1000px = useMediaQuery("(max-width:1000px)");
  const media400px = useMediaQuery("(max-width:400px)");
  return (
    <div>
      <div className="frequently-asked-container">
        <div className="frequently-asked-container-title">
          <Title order={media400px && 3} style={{ textAlign: media1000px && "center" }}>
            Frequently asked questions
          </Title>
        </div>
        <div className="frequently-asked-container-accordion">
          <Accordion variant="contained">
            <Accordion.Item value={"1"}>
              <Accordion.Control>
                What forms of payment do you accept ?
              </Accordion.Control>
              <Accordion.Panel>
                We Accept payments via credit cards, PayPal & crypto as well.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value={"2"}>
              <Accordion.Control>
                Can I cancel my subscription ?
              </Accordion.Control>
              <Accordion.Panel>
                Yes, you definitely can cancel your subscription and, you will
                still have access to all the features that you already paid for
                untill the end of paid date range
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value={"3"}>
              <Accordion.Control>Will I get an invoice ?</Accordion.Control>
              <Accordion.Panel>
                Yes, an invoice will be automatically generated for you after
                any payments from your account.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value={"4"}>
              <Accordion.Control>Can I have a refund ?</Accordion.Control>
              <Accordion.Panel>
                We do offer refunds in case something goes wrong or you change
                your mind, simply contact us if needed
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FrequentlyAsked;
