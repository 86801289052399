import React, { useEffect, useRef, useState, memo } from "react";
import QRCodeStyling from "qr-code-styling";

const CustomQRCode = (properties) => {
  const { qrValue, qrstyle, formData, qr_images } = properties;

  const qrCodeRef = useRef(null);
  const qrCodeInstanceRef = useRef(null);
  const [dummy, setDummy] = useState(false);

  useEffect(() => {
    qrCodeInstanceRef.current = new QRCodeStyling({
      width: 200,
      height: 200,
      data: qrValue,
    });

    qrCodeInstanceRef.current.append(qrCodeRef.current);
  }, [qrValue]);

  useEffect(() => {
    qrCodeInstanceRef.current.update({
      data: qrValue,
      dotsOptions: qrstyle.dotsOptions,
      backgroundOptions: qrstyle.backgroundOptions,
      image: qrstyle.image,
    });
    setDummy((prevState) => !prevState);
  }, [qrValue, qrstyle]);

  const handleDownload = () => {
    qrCodeInstanceRef.current.download({ extension: "png" });
  };

  return (
    <div className="center_div">
      <div className="mockup-phone w-[300px]  !h-[500px]">
        <div className="camera"></div>
        <div className="display bg-white h-full center_div relative items-center overflow-y-scroll">
          <div className="flex flex-col gap-y-6  items-center">
            <div ref={qrCodeRef} className="!w-[200px] !h-[200px] "></div>
            <div
              onClick={handleDownload}
              className="z-20 min-w-[200px] cursor-pointer px-3 py-2 center_div bg-primaryColor text-white rounded-lg"
            >
              Download
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomQRCode;
