import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../helper/QRtypes";

const GoogleReview = () => {
  return (
    <Form.Item
      className="w-[400px]"
      label="Enter Link"
      rules={[basicRules("Enter Your Link")]}
      name={"google_review_link"}
    >
      <Input rows={10} placeholder="Enter Your Google My business Share review form url" />
    </Form.Item>
  );
};

export default GoogleReview;
