import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

// CSS
import "./Router.css";

// Pages
import LandingPage from "../Pages/LandingPage/LandingPage";
import Signin from "../Pages/Login/Signin/Signin";
import Signup from "../Pages/Login/Signup/Signup";
import Pricing from "../Pages/Pricing/Pricing";
import GenerateQrCode from "../Pages/GenerateQrCode/GenerateQrCode";
import QrCodeReader from "../Components/QrCodeReader/QrCodeReader";
import Vcard from "../Components/Vcard/Vcard";
import AdminRouters from "../AdminRouters/AdminRouters";
import TermsOfServices from "../Pages/TermsOfServices/TermsOfServices";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "../Components/RefundPolicy/RefundPolicy";
import Contact from "../Pages/Contact/Contact";
import { useSelector } from "react-redux";
// Axios
import axios from "axios";

// Config
import config from "../Config/Server/Server";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import { ThemeIcon } from "@mantine/core";
import { X } from "tabler-icons-react";
import UploadImage from "../Pages/UploadImage/UploadImage";
import { __esModule } from "react-datepicker";
import SuperAdmin from "../SuperAdmin";
import NotFound from "../SuperAdmin/NotFound";
import Users from "../SuperAdmin/Users";
import Qr from "../SuperAdmin/Qr";
import Links from "../SuperAdmin/Links";
import Payment from "../SuperAdmin/Paymentsdetails";


const Router = () => {
  const userdata = useSelector((state) => state.userData.value);
  const [subscriptionEnd, setSubscriptionEnd] = useState();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const current = `${year}-${month}-${day}`;

  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() + 10);
  const futureYear = futureDate.getFullYear();
  const futureMonth = String(futureDate.getMonth() + 1).padStart(2, "0");
  const futureDay = String(futureDate.getDate()).padStart(2, "0");
  const future = `${futureYear}-${futureMonth}-${futureDay}`;

  // UseEffect
  useEffect(() => {
    hanldeFetchSubscription(userdata?.id);
  }, [userdata]);

  const hanldeFetchSubscription = async (id) => {
    try {
      const res = await axios.get(
        `${config.baseAPI}/fetch_current_transaction.php?id=${id}`
      );
      const firstTransaction = res.data[0];

      if (firstTransaction && firstTransaction.to_date) {
        setSubscriptionEnd(firstTransaction.to_date);

        if (firstTransaction.to_date === current) {
          const res = await axios
            .get(`${config.baseAPI}/deactivate_plan.php?id=${id}`)
            .then((res) => {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "Your Plan is Ended",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (firstTransaction.to_date === future) {
          const res = await axios
            .get(`${config.baseAPI}/deactivate_email.php?id=${id}`)
            .then((res) => {
              showNotification({
                icon: (
                  <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                    <X color="red" />
                  </ThemeIcon>
                ),
                message: "Check Your Registered Email",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Routes>
        {_.get(userdata, "role", "") === "admin" ? (
          <>
            <Route path="/" element={<SuperAdmin />} />
            <Route path="/user" element={<Users />} />
            <Route path="/qrcodes" element={<Qr />} />
            <Route path="/links" element={<Links />} />
            <Route path="/payment" element={<Payment />} />
          </>
        ) : (
          <>
            <Route exact path="/" element={<LandingPage />} />
            <Route path="/login" element={<Signin />} />
            <Route path="/login/demo" element={<Signin />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/pricedetails" element={<Pricing />} />
            <Route path="/generate-qr-code" element={<GenerateQrCode />} />
            <Route path="/read-qr-code" element={<QrCodeReader />} />
            <Route path="/:name/:id" element={<Vcard />} />
            <Route path="/dashboard" element={<AdminRouters />} />
            <Route path="/dashboard/generate_qr" element={<AdminRouters />} />
            <Route path="/dashboard/admin_profile" element={<AdminRouters />} />
            <Route
              path="/dashboard/change_password"
              element={<AdminRouters />}
            />
            <Route path="/dashboard/saved_qr" element={<AdminRouters />} />
            <Route path="/dashboard/dynamic_links" element={<AdminRouters />} />
            <Route path="/dashboard/projects" element={<AdminRouters />} />
            <Route path="/dashboard/qrcode_reader" element={<AdminRouters />} />
            <Route path="/dashboard/transactions" element={<AdminRouters />} />
            <Route path="/dashboard/gallery" element={<AdminRouters />} />
            <Route path="/terms-of-services" element={<TermsOfServices />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/uploadimage" element={<UploadImage />} />
            <Route path="/dashboard/templates" element={<AdminRouters />} />
            <Route path="/dashboard/appoinment" element={<AdminRouters />} />
          </>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default Router;
