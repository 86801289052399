import { Input, Text } from "@mantine/core";
import React from "react";

const PaymentQr = ({ qrDetails, setQrDetails }) => {
  return (
    <div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>UPI ID</Text>}>
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  payment: {
                    ...qrDetails.payment,
                    upi_id: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.payment.upi_id}
          />
        </Input.Wrapper>
      </div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>Amount</Text>}>
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  payment: {
                    ...qrDetails.payment,
                    amount: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.payment.amount}
          />
        </Input.Wrapper>
      </div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper
          pb={"md"}
          label={<Text pb={"sm"}>Transaction Note</Text>}
        >
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  payment: {
                    ...qrDetails.payment,
                    transaction_note: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.payment.transaction_note}
          />
        </Input.Wrapper>
      </div>
    </div>
  );
};

export default PaymentQr;
