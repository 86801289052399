import React from "react";

// CSS
import "./ServicesComponents.css";

// Layouts
import Navbar from "../../Layouts/Navbar/Navbar";
import Footer from "../../Layouts/Footer/Footer";

// Mantine Cre
import { List, Text, Title } from "@mantine/core";
import { CircleCheck } from "tabler-icons-react";

const ServicesComponents = ({ listArray, title, desc }) => {
  return (
    <div>
      <div
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: "white",
          zIndex: "1234",
        }}
      >
        <Navbar />
      </div>
      <div className="terms-of-services-container">
        <div className="terms-of-services-container-title">
          <Title pt={"md"} pb={"md"}>
            {title}
          </Title>
        </div>
        <div>
          <Text pt={"xs"} pb={"xs"}>
            {desc}
          </Text>
        </div>
        <div className="terms-of-services-container-list">
          <List icon={<CircleCheck size={"1rem"} />}>
            {listArray.map((value, index) => {
              return (
                <div key={index}>
                  <Title order={5} p={"xs"}>
                    {value.title}
                  </Title>
                  {value.list.map((valueList, indexList) => {
                    return (
                      <List.Item size="lg" p={"xs"} withPadding key={indexList}>
                        {valueList}
                      </List.Item>
                    );
                  })}
                </div>
              );
            })}
          </List>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServicesComponents;
