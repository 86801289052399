import {
  Button,
  FileInput,
  Input,
  Text,
  Textarea,
  ThemeIcon,
} from "@mantine/core";
import React from "react";
import { useState } from "react";
import "./UploadImage.css";
// Axios
import axios from "axios";

// Config
import config from "../../Config/Server/Server";
import { showNotification } from "@mantine/notifications";
import { CircleCheck, X } from "tabler-icons-react";
const UploadImage = () => {
  const user_id = localStorage.getItem("user_id");
  const [uploadFile, setUploadFile] = useState({
    content: "",
    imagefile: null,
    user_id: user_id,
  });
  const handileFileUpload = async () => {
    const formData = new FormData();
    formData.append("image", uploadFile.imagefile);
    formData.append("remaining", JSON.stringify(uploadFile));
    return await axios
      .post(`${config.baseAPI}/uploadimges.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.status == 0) {
          showNotification({
            icon: (
              <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                <CircleCheck color="green" />
              </ThemeIcon>
            ),
            message: "Image Uploaded Successfully",
          });
        } else {
          showNotification({
            icon: (
              <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                <X color="red" />
              </ThemeIcon>
            ),
            // message: "Error in Uploading Image",
            message: "Error in Uploading Image",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div className="uploadimage-div">
        <div className="uploadimage-div-form">
          <div className="uploadimage-div-form-div">
            <Input.Wrapper
              pb={"sm"}
              label={<Text pb={"sm"}>Name / Content</Text>}
            >
              <Textarea
                autosize
                minRows={4}
                onChange={(e) => {
                  setUploadFile({ ...uploadFile, content: e.target.value });
                }}
                value={uploadFile.content}
              />
            </Input.Wrapper>
            <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>Image</Text>}>
              <FileInput
                onChange={(e) => {
                  setUploadFile({ ...uploadFile, imagefile: e });
                }}
                value={uploadFile.imagefile}
                placeholder="Click to upload image"
                clearable
              />
            </Input.Wrapper>
            <Button onClick={handileFileUpload}>Submit</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
