import { Button, Drawer, Form, Input, Space, Switch } from "antd";
import Upload from "antd/es/upload/Upload";
import React, { useEffect } from "react";
import { useState } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { message, Divider, Select } from "antd";
import {
  handleBeforeUpload,
  handleImageUpload,
} from "../../../../helper/QRtypes";
import {
  errorNotification,
  successNotification,
} from "../../../../Components/Notification/notify";
import _ from "lodash";
import moment from "moment";

const BusinessHoursForm = (properties) => {
  const { formData, handleBusinessHoursChange } = properties;

  return (
    <div>
      <Form.List name="business_hours" className="flex">
        {(fields, { add, remove }) => (
          <>
            <div className="!w-full flex justify-end">
              {/* <Form.Item className="!w-fit px-3">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  //   icon={<PlusOutlined />}
                >
                  Add new Business Hours
                </Button>
              </Form.Item> */}
            </div>
            <div className="flex flex-wrap gap-2">
              {fields.map(({ key, name, ...restField }, index) => (
                <>
                  <div className="flex items-center w-[500px] justify-between gap-x-4 shadow-lg px-5 py-2 rounded-lg  flex-wrap bg-white">
                    <div className="flex items-center">
                      <Form.Item
                        {...restField}
                        name={[name, "day"]}
                        label=" "
                        className="border-less"
                      >
                        <Input disabled className="!border-none !w-[80px] " />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label=" "
                        name={[name, "status"]}
                      >
                        <Switch
                          onChange={(e) => {
                            handleBusinessHoursChange(e, index);
                          }}
                          size="small"
                        />
                      </Form.Item>
                    </div>

                    {!_.get(
                      formData,
                      `business_hours[${index}].status`,
                      true
                    ) ? (
                      <p className="pt-2 !font-Poppins">Closed</p>
                    ) : (
                      <>
                        <Form.Item
                          {...restField}
                          name={[name, "from"]}
                          label="From Time"
                          rules={[
                            {
                              required: true,
                              message: "Enter From Time",
                            },
                          ]}
                        >
                          <Input
                            type="time"
                            onChange={(e) => {
                              console.log(e.target.value);
                            }}
                            value={"10:00 AM"}
                            className="!w-[150px] !h-[50px] "
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="To Time"
                          name={[name, "to"]}
                          rules={[
                            {
                              required: true,
                              message: "Select To Time",
                            },
                          ]}
                        >
                          <Input type="time" className="!w-[150px] !h-[50px]" />
                        </Form.Item>
                      </>
                    )}
                  </div>
                </>
              ))}
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default BusinessHoursForm;
