import axios from "axios";
// Server
import config from "../../Server/Server";

export const allProjects = async (body) => {
  return await axios.post(`${config.baseAPI}/all_projects.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const addProject = async (body) => {
  return await axios.post(`${config.baseAPI}/project.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateProject = async (body) => {
  return await axios.post(`${config.baseAPI}/update_project.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const fetchProject = async (body) => {
  return await axios.post(`${config.baseAPI}/fetch_project.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const deleteProject = async (body) => {
  return await axios.post(`${config.baseAPI}/delete_project.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
