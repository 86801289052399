// Icons
import { Link, TopologyStar3 } from "tabler-icons-react";

// Images
import StateQrCode from "../../../Assets/StaticImages/StateQrCode.png";
import DynamicQrCode from "../../../Assets/StaticImages/DynamicQrCode.png";

export const Static_DynamicQrCodeData = [
  {
    image: StateQrCode,
    icon: <TopologyStar3 />,
    title: `Static QR codes`,
    desc: `Static QR codes are fixed, meaning that all the data is directly embedded into the QR code. Once generated and printed for real-world usage, it can't be changed.`,
    list: [
      `Fully customizable`,
      `No scan limit`,
      `No data saved on our servers`,
    ],
  },
  {
    image: DynamicQrCode,
    icon: <Link />,
    title: `Dynamic QR codes`,
    desc: `Dynamic QR codes for links, allow you to change the destination of the QR code on-the-fly, whenever you want. It combines multiple technologies to make this happen in the background with the help of our Links tool.`,
    list: [
      `Advanced scan statistics`,
      `Change URL destination dynamically`,
      `Scheduling, expiration limits, A/B Rotation`,
      `Password protection`,
      `Country, device & language re-targeting`,
    ],
  },
];
