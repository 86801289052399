import { Form, Input, Select } from "antd";
import _ from "lodash";

import React from "react";

const BackgroundOptions = (properties) => {
  const { setQrStyle, qrstyle } = properties;
  return (
    <div className="flex items-center gap-x-4">
      <Form.Item
        initialValue={"single"}
        name="bg_color_type"
        label="Color Type"
      >
        <Select
          className="!border !w-[200px]"
          onChange={(e) => {
            setQrStyle((prevState) => ({
              ...prevState,
              backgroundOptions: {
                ...prevState?.backgroundOptions,
                color_type: e,
                color: "#000",
                gradient:
                  e === "single"
                    ? ""
                    : {
                        type: "linear",
                        rotation: 0,
                        colorStops: [
                          {
                            offset: 0,
                            color: "#6e2150",
                          },
                          {
                            offset: 1,
                            color: "#1acb9f",
                          },
                        ],
                      },
              },
            }));
          }}
        >
          <Select.Option value="single">Single</Select.Option>
          <Select.Option value="gradient">Gradient</Select.Option>
        </Select>
      </Form.Item>
      {_.get(qrstyle, "backgroundOptions.color_type", "") === "single" ? (
        <Form.Item
          label="Color"
          name="bg-color_single"
          initialValue={_.get(qrstyle, "backgroundOptions.color", "")}
        >
          <Input
            type="color"
            onChange={(e) => {
              setQrStyle((prevState) => ({
                ...prevState,
                backgroundOptions: {
                  ...prevState?.backgroundOptions,
                  color: e.target.value,
                  gradient: "",
                },
              }));
            }}
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item label="From" name={"bg_color_from"}>
            <Input
              type="color"
              onChange={(e) => {
                setQrStyle((prevState) => ({
                  ...prevState,
                  backgroundOptions: {
                    ...prevState.backgroundOptions,
                    gradient: {
                      ...prevState.backgroundOptions.gradient,
                      colorStops: [
                        {
                          offset: 0,
                          color: e.target.value,
                        },
                        {
                          offset: 1,
                          color:
                            prevState.backgroundOptions.gradient.colorStops[1].color,
                        },
                      ],
                    },
                  },
                }));
              }}
              className="!w-[100px]"
            />
          </Form.Item>
          <Form.Item label="To" name={"bg_color_to"}>
            <Input
              type="color"
              onChange={(e) => {
                setQrStyle((prevState) => ({
                  ...prevState,
                  backgroundOptions: {
                    ...prevState.backgroundOptions,
                    gradient: {
                      ...prevState.backgroundOptions.gradient,
                      colorStops: [
                        {
                          offset: 0,
                          color:
                            prevState.backgroundOptions.gradient.colorStops[0].color,
                        },
                        {
                          offset: 1,
                          color: e.target.value,
                        },
                      ],
                    },
                  },
                }));
              }}
              className="!w-[100px]"
            />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default BackgroundOptions;
