import React from "react";

// CSS
import "./Static_DynamicQrCode.css";

// Mantine Core
import { Flex, List, Text, ThemeIcon, Title } from "@mantine/core";
import { Check } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";

const Static_DynamicQrCode = ({ data }) => {
  const media760Query = useMediaQuery("(max-width:760px)");

  return (
    <div>
      <div className="state-dynamic-container">
        {data.map((value, index) => {
          return (
            <Flex
              mt={media760Query ? "2rem" : "4rem"}
              mb={media760Query ? "2rem" : "4rem"}
              key={index}
              direction={
                media760Query ? "column" : index === 1 ? "row-reverse" : ""
              }
              gap={media760Query ? "1rem" : "3rem"}
              style={{ margin: `${index === 1 ? "6rem 0" : ""}` }}
            >
              <div className="state-dynamic-image-container">
                <img src={value.image} alt="QrCodeTemplate" />
              </div>
              <div className="state-dynamic-content">
                <div className="state-dynamic-content-icon">{value.icon}</div>
                <div className="state-dynamic-content-title">
                  <Title order={1}>{value.title}</Title>
                </div>
                <div className="state-dynamic-content-desc">
                  <Text>{value.desc}</Text>
                </div>
                <div className="state-dynamic-content-list">
                  <List
                    spacing="md"
                    center
                    icon={
                      <ThemeIcon color="teal" size={20} radius="xl">
                        <Check size={15} />
                      </ThemeIcon>
                    }
                  >
                    {value.list.map((valueList, indexList) => {
                      return <List.Item key={indexList}>{valueList}</List.Item>;
                    })}
                  </List>
                </div>
              </div>
            </Flex>
          );
        })}
      </div>
    </div>
  );
};

export default Static_DynamicQrCode;
