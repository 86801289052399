import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../helper/QRtypes";

const EmailForms = () => {
  return (
    <>
      <Form.Item
        className="w-[400px]"
        label="Email Address"
        rules={[basicRules("Enter Email Address"),{type:"email",message:"Enter a valid email address"}]}
        name={"email_email_address"}
      >
        <Input placeholder="Enter Email Address" />
      </Form.Item>
      <Form.Item
        className="w-[400px]"
        label="Pre-Filled Subject"
        rules={[basicRules("Enter Subject")]}
        name={"email_subject_address"}
      >
        <Input placeholder="Enter Email Subject" />
      </Form.Item>
      <Form.Item
        className="w-[400px] text-area"
        label="Pre-Filled Message"
        rules={[basicRules("Enter Message")]}
        name={"email_message"}
      >
        <Input.TextArea
          rows={10}
          placeholder="Enter Message Here"
          className="!h-[100px]"
        />
      </Form.Item>
    </>
  );
};

export default EmailForms;
