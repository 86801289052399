import React, { useEffect, useState } from "react";
import { Avatar, Badge, Card, Divider, QRCode, Tag } from "antd";
import {
  DatabaseOutlined,
  DeleteFilled,
  DownloadOutlined,
  EditFilled,
  FieldTimeOutlined,
  LinkOutlined,
  PlayCircleOutlined,
  SaveFilled,
  SaveOutlined,
} from "@ant-design/icons";
import DashboardImg from "../../Assets/icons/dashboard.png";
import { useSelector } from "react-redux";
import config from "../../Config/Server/Server";
import {
  getOneAppoinment,
  getSavedQrs,
} from "../../Config/Quries/Vacrd/VcardQuery";
import _ from "lodash";
import {
  errorNotification,
  successNotification,
} from "../../Components/Notification/notify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BasicLoading from "../../helper/BasicLoading";
import { allDynamicLinks } from "../../Config/Quries/DynamicLinks/DynamicLinksQuries";
import { icons } from "antd/es/image/PreviewGroup";

const Dashboard = () => {
  const userData = useSelector((state) => state.userData.value);
  const [myQrs, setMyQrs] = useState([]);
  const [linksCount, setLinkCounts] = useState([]);
  const [appoinmentCount, setAppoinmentCount] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      let formData = {
        user_id: _.get(userData, "id", ""),
      };
      const data = await Promise.all([
        getSavedQrs(formData),
        allDynamicLinks(formData),
        getOneAppoinment({ id: _.get(userData, "id", "") }),
      ]);

      setMyQrs(_.get(data, "[0].data", []));
      setLinkCounts(_.get(data, "[1].data", []));
      setAppoinmentCount(_.get(data, "[2].data.oppoinment", []));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getPlanDetails = (value) => {
    if ("2" === userData.prime_user) {
      return "Annual";
    } else if ("1" === userData.prime_user) {
      return "Free Tier";
    } else {
      return "LifeTime";
    }
  };

  const counts = [
    {
      id: 1,
      name: "Saved QR",
      img: "https://api.dicebear.com/7.x/miniavs/svg?seed=1",
      count: _.size(myQrs),
      bgcolor: "#fb923c",
      icon: <SaveOutlined />,
    },
    {
      id: 2,
      name: "Total Links",
      img: "https://api.dicebear.com/7.x/miniavs/svg?seed=1",
      count: _.size(linksCount),
      bgcolor: "#84cc16",
      icon: <LinkOutlined />,
    },
    {
      id: 3,
      name: "Appoinments",
      img: "https://api.dicebear.com/7.x/miniavs/svg?seed=1",
      count: _.size(appoinmentCount),
      bgcolor: "#f43f5e",
      icon: <FieldTimeOutlined />,
    },
    {
      id: 4,
      name: "Active Plan",
      img: "https://api.dicebear.com/7.x/miniavs/svg?seed=1",
      count: getPlanDetails(),
      bgcolor: "#0ea5e9",
      icon: <PlayCircleOutlined />,
    },
  ];

  return (
    <div className="p-5 !font-Poppins">
      <div className="flex items-center shadow gap-x-2 bg-white w-full h-[50px] rounded">
        <img src={DashboardImg} alt="" className="!w-[50px]" />
        <h1 className="text-2xl font-bold up">Dashboard</h1>
      </div>
      <div className="pt-2 flex flex-wrap gap-2 justify-between">
        {counts.map((res, index) => {
          return (
            <div
              style={{ background: res.bgcolor }}
              className="w-[300px] h-[100px] rounded-lg shadow-inner p-4 flex flex-col gap-y-2"
            >
              <div className="flex items-center gap-x-2 text-white">
                {res.icon}
                <div className="text-xl">{res.name}</div>
              </div>
              <div className="text-2xl text-white">{res.count}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dashboard;
