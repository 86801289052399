import { Button, Card, Input, PasswordInput, ThemeIcon } from "@mantine/core";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { CircleCheck, Key, X } from "tabler-icons-react";
import "./UpdatePassword.css";
import DashboardImg from "../../Assets/icons/dashboard.png";

// Server
import config from "../../Config/Server/Server";
import { showNotification } from "@mantine/notifications";

const UpdatePassword = () => {
  const [changePassword, setChangePassword] = useState({
    id: localStorage.getItem("user_id"),
    oldpassword: "",
    password: "",
  });
  const handileUpdatePassword = async () => {
    return await axios
      .post(`${config.baseAPI}/update_password.php`, changePassword, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (
          response.data.message === "Old Password is Wrong" ||
          response.data.message ===
            "Old Password and New Password cannot be the same"
        ) {
          showNotification({
            icon: (
              <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                <X color="red" />
              </ThemeIcon>
            ),
            message: response.data.message,
          });
        } else {
          showNotification({
            icon: (
              <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                <CircleCheck color="green" />
              </ThemeIcon>
            ),
            message: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="p-5 !font-Poppins">
      <div className="flex items-center justify-between shadow gap-x-2 bg-white w-full h-[50px] rounded">
        <div className="center_div gap-x-2">
          <img src={DashboardImg} alt="" className="!w-[50px]" />
          <h1 className="text-2xl font-bold up">Update Password</h1>
        </div>
      </div>
      {/* rest of.t. */}
      <div className="pt-2">
        <Card className="update-password-div-card">
          <div className="update-password-div-card-form-input">
            <Input.Wrapper label="Old Password">
              <PasswordInput
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    oldpassword: e.target.value,
                  });
                }}
                value={changePassword.oldpassword}
              />
            </Input.Wrapper>
          </div>
          <div className="update-password-div-card-form-input">
            <Input.Wrapper label="New Password">
              <PasswordInput
                onChange={(e) => {
                  setChangePassword({
                    ...changePassword,
                    password: e.target.value,
                  });
                }}
                value={changePassword.password}
              />
            </Input.Wrapper>
          </div>
          <div className="update-password-div-card-form-input">
            <Button onClick={handileUpdatePassword} className="!text-black">
              Update
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default UpdatePassword;
