//import react packages
import React, { useEffect, useState } from "react";
import { CgPushChevronLeft } from "react-icons/cg";
import { CgPushChevronRight } from "react-icons/cg";

//import react-router-dom packages
import { Link, useLocation, useNavigate } from "react-router-dom";

//import mantine packages

import { Avatar, Dropdown, Tooltip } from "antd";

//import react-pro-slider packages
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

//import font-awesome packages
import "font-awesome/css/font-awesome.min.css";
import "react-datepicker/dist/react-datepicker.css";

//import AdminRouters css
import "./AdminRouters.css";

//import dashboard from pages->admin->dashboard
import Dashboard from "../Pages/Dashboard/Dashboard";
import QrGenerator from "../Pages/QrGenerator/QrGenerator";
import SavedQr from "../Pages/SavedQr/SavedQr";
import DynamicLinks from "../Pages/DynamicLinks/DynamicLinks";
import Projects from "../Pages/Projects/Projects";

//import images
import Logo from "../Assets/BrandLogo.png";

// PrimeUser
import { useDispatch, useSelector } from "react-redux";

// Tabler ICons
import { Share } from "tabler-icons-react";
import QrCodeReader from "../Pages/QrCodeReader/QrCodeReader";
import UpdatePassword from "../Pages/UpdatePassword/UpdatePassword";
import Transactions from "../Pages/Transactions/Transactions";
import { FaRegImage } from "react-icons/fa6";

// Mantine Media Hook

import Gallery from "../Pages/Gallery/Gallery";
import Templates from "../Components/Messages/Templates";
import _ from "lodash";
// React Iocns
import { FiMessageSquare } from "react-icons/fi";
import Appoinments from "../Components/Messages/Appoinments";
import { useQuery } from "react-query";
import { getUserDataById } from "../Config/Quries/User/userQuries";
import { setUserData } from "../StateHandler/Slice/userData/userDataSlice";

const AdminRouters = () => {
  const [open, setOpen] = useState(true);
  // Media query

  // UserData
  const userData = useSelector((state) => state.userData.value);
  const [userDetails, setUserDetails] = useState("");
  // Redux State
  const primeUser = useSelector((state) => state.primeUser.value);

  // Scroll to TOP
  window.scrollTo(0, 0);
  // Navigate
  const navigate = useNavigate();

  const dispatch = useDispatch();

  window.scrollTo(0, 0);

  const [collapsed, setCollapsed] = useState(false);

  window.scrollTo(0, 0);
  const location = useLocation();
  const [loactionPath, setLocationPath] = useState();
  const bodyContent = {
    "/dashboard": <Dashboard />,
    "/dashboard/generate_qr": <QrGenerator userData={userData} />,
    "/dashboard/saved_qr": <SavedQr />,
    "/dashboard/dynamic_links": <DynamicLinks />,
    "/dashboard/projects": <Projects />,
    "/dashboard/qrcode_reader": <QrCodeReader />,
    "/dashboard/change_password": <UpdatePassword />,
    "/dashboard/transactions": <Transactions />,
    "/dashboard/gallery": <Gallery />,
    "/dashboard/templates": <Templates />,
    "/dashboard/appoinment": <Appoinments />,
  };

  useEffect(() => {
    if (localStorage.getItem("user_name")) {
      setUserDetails(localStorage.getItem("user_name"));
    }
  }, []);

  useEffect(() => {
    const paramsId = location.pathname.split("/");
    if (paramsId.length > 2) {
      if (paramsId[1] === "productsdetails_individual") {
        setLocationPath("/productsdetails_individual/product_id");
      } else if (paramsId[1] === "editproduct") {
        setLocationPath("/editproduct/product_id");
      } else if (paramsId[2] === "generate_qr") {
        setLocationPath("/dashboard/generate_qr");
      } else if (paramsId[2] === "saved_qr") {
        setLocationPath("/dashboard/saved_qr");
      } else if (paramsId[2] === "dynamic_links") {
        setLocationPath("/dashboard/dynamic_links");
      } else if (paramsId[2] === "projects") {
        setLocationPath("/dashboard/projects");
      } else if (paramsId[2] === "qrcode_reader") {
        setLocationPath("/dashboard/qrcode_reader");
      } else if (paramsId[2] === "change_password") {
        setLocationPath("/dashboard/change_password");
      } else if (paramsId[2] === "transactions") {
        setLocationPath("/dashboard/transactions");
      } else if (paramsId[2] === "templates") {
        setLocationPath("/dashboard/templates");
      } else if (paramsId[2] === "appoinment") {
        setLocationPath("/dashboard/appoinment");
      }
    } else {
      setLocationPath(`/${paramsId[1]}`);
    }
  }, [location]);
  const renderBodyData = bodyContent[loactionPath] || null;

  // User Not Premium Redirect to Home
  useEffect(() => {
    if (!localStorage.getItem("user_name")) {
      navigate("/");
    }
  }, []);

  const navItem = [
    {
      id: 1,
      name: "Dashboard",
      path: "/dashboard",
      icon: <i className="fa fa-home" />,
    },
    {
      id: 2,
      name: "Generate Qr Code",
      path: "/dashboard/generate_qr",
      icon: <i className="fa fa-plus" />,
    },
    {
      id: 3,
      name: "Saved Qr Code",
      path: "/dashboard/saved_qr",
      icon: <i className="fa fa-qrcode" />,
    },
    {
      id: 4,
      name: "Links",
      path: "/dashboard/dynamic_links",
      icon: <i className="fa fa-link" />,
    },
    // {
    //   id: 5,
    //   name: "QR Code Reader",
    //   path: "/dashboard/qrcode_reader",
    //   icon: <i className="fa fa-qrcode" />,
    // },
    // {
    //   id: 6,
    //   name: "Transactions",
    //   path: "/dashboard/transactions",
    //   icon: <i className="fa fa-history" />,
    // },
    {
      id: 7,
      name: "Templates",
      path: "/dashboard/templates",
      icon: <i className="fa fa-image" />,
    },
    {
      id: 7,
      name: "Appoinments",
      path: "/dashboard/appoinment",
      icon: <i className="fa fa-calendar" />,
    },
  ];

  const dropdownitems = [
    {
      key: "4",
      danger: true,
      label: "a danger item",
    },
  ];

  useQuery(["userPremium", localStorage.getItem("user_id")], getUserDataById, {
    enabled: !!localStorage.getItem("user_id"),
    onSuccess: (res) => {
      // if (res.data[0]?.prime_user == 1) {
      //   dispatch(setPrimeUser(true));
      //   setPremiumMember(true);
      // } else if (res.data[0].prime_user == 2) {
      //   dispatch(setPrimeUser(true));
      //   setPremiumMember(true);
      // } else if (res.data[0].prime_user == 3) {
      //   dispatch(setPrimeUser(true));
      //   setPremiumMember(true);
      // }
      dispatch(setUserData(res.data[0]));
    },
  });

  return (
    <div className="w-screen h-screen  bg-[#f7f7f7] flex">
      {/* sidenavbar */}
      <div
        className={`${
          open ? "w-[15vw]" : "w-[5vw]"
        } bg-white relative transition-all duration-700 !h-screen `}
      >
        <div className="flex flex-col items-center py-5">
          {/* logo */}
          <img src={Logo} alt="" className={`w-[25%]`} />
          {/* navbar */}
          <div className="pt-10 flex flex-col gap-y-4">
            {navItem.map((res, index) => {
              return (
                <Link
                  to={res.path}
                  className=" flex items-center gap-x-4 !font-Poppins hover:text-primaryColor cursor-pointer px-3 py-2"
                >
                  <Tooltip title={res.name} placement="right">
                    {res.icon}
                  </Tooltip>
                  <p
                    className={`${
                      open ? "block  !line-clamp-1 !text-ellipsis" : "hidden"
                    } text-sm`}
                  >
                    {res.name}
                  </p>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={`${
          open ? "w-[85vw]" : "w-[95vw]"
        } h-screen overflow-y-scroll flex flex-col`}
      >
        <div
          className={`flex  justify-between z-50 px-5 items-center bg-white h-[50px] fixed ${
            open ? "w-[85vw]" : "w-[95vw]"
          } `}
        >
          <div
            className="cursor-pointer  flex justify-center   !z-50"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? (
              <CgPushChevronLeft className="!text-primaryColor !text-xl" />
            ) : (
              <CgPushChevronRight className="!text-primaryColor !text-xl" />
            )}
          </div>
          <div className="flex items-center gap-x-3">
            <Link to="/dashboard/change_password">
              <Avatar>
                <div className="uppercase">
                  {userData?.firstname?.split("")[0]}
                </div>
              </Avatar>
            </Link>
            <div
              onClick={() => window.location.reload(localStorage.clear())}
              className="px-3 center_div text-sm shadow-inner text-white bg-primaryColor py-2 rounded-lg cursor-pointer"
            >
              Logout
            </div>
          </div>
        </div>
        {/* body */}
        <div className="pt-[50px] ">{renderBodyData}</div>
      </div>
    </div>
  );
};

export default AdminRouters;
