import React, { useEffect, useState } from "react";

// Mantine Core
import { Button, Checkbox, Input, Modal, Select, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  AddDynamicLinks,
  TotalDynamicLinks,
} from "../../../Controller/DynamicLinks/DynamicLinksController";

const URLQr = ({
  qrDetails,
  setQrDetails,
  dynamic_url,
  dynamicURLDet,
  setDynamicURLDet,
}) => {
  const [dynamicopened, { open: dynamicopen, close: dynamicclose }] =
    useDisclosure(false);
  const [dynamicLinkData, setDynamicLinkData] = useState([]);
  // Refetch
  const [refetchData, setRefetchData] = useState(true);
  useEffect(() => {
    TotalDynamicLinks(localStorage.getItem("user_id"), setDynamicLinkData);
  }, [refetchData]);
  const options = dynamicLinkData.map((link) => ({
    value: link.link_name,
    label: link.link_name,
  }));
  const handleAddLinks = () => {
    AddDynamicLinks(
      localStorage.getItem("user_id"),
      setRefetchData,
      dynamicURLDet,
      setDynamicURLDet,
      dynamicclose
    );
  };

  return (
    <div>
      {qrDetails.url.dynamicurl === false ? (
        <div className="qrgenerator-div-content-form-individual">
          <Input.Wrapper label={<Text pb={"sm"}>URL</Text>}>
            <Input
              radius={"md"}
              onChange={(e) => {
                {
                  setQrDetails({
                    ...qrDetails,
                    url: {
                      ...qrDetails.url,
                      url: e.target.value,
                    },
                  });
                }
              }}
              value={qrDetails.url.url}
            />
          </Input.Wrapper>
        </div>
      ) : (
        <div className="qrgenerator-div-content-form-individual">
          <Input.Wrapper label={<Text pb={"sm"}>URL</Text>}>
            <Button
              onClick={dynamicopen}
              className="model-btn"
              style={{ padding: "4px 8px", height: "25px", marginLeft: "1rem" }}
            >
              + Create
            </Button>
            <Select
              radius={"md"}
              placeholder="Select URL"
              data={options}
              searchable
              value={qrDetails.url.url}
              onChange={(e) => {
                setQrDetails({
                  ...qrDetails,
                  url: {
                    ...qrDetails.url,
                    url: e,
                  },
                });
              }}
            />
          </Input.Wrapper>
        </div>
      )}

      <div style={{ padding: "1rem 0" }}>
        <Input.Wrapper>
          <Checkbox
            checked={qrDetails.url.dynamicurl}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  url: {
                    ...qrDetails.url,
                    dynamicurl: !qrDetails.url.dynamicurl,
                  },
                });
              }
            }}
            label="Dynamic QR code"
            description="Enabling this will create a QR code that points to an internal short link, which will redirect to any specified link, dynamically."
          />
        </Input.Wrapper>
      </div>
      <Modal
        size="xl"
        zIndex={12345}
        centered
        opened={dynamicopened}
        onClose={dynamicclose}
        title="Dynamic URL"
      >
        <div className="qrgenerator-div-content-form-individual">
          <Input.Wrapper label="Destination URL">
            <Input
              onChange={(e) => {
                {
                  setDynamicURLDet({
                    ...dynamicURLDet,
                    destination_url: e.target.value,
                  });
                }
              }}
              value={dynamicURLDet.destination_url}
            />
          </Input.Wrapper>
        </div>
        <div className="qrgenerator-div-content-form-individual">
          <Input.Wrapper label="URL Alias">
            <Text className="input-group-prepend">
              https://www.qrcodeweboney.in
            </Text>
            <Input
              onChange={(e) => {
                {
                  setDynamicURLDet({
                    ...dynamicURLDet,
                    url_alias: e.target.value,
                  });
                }
              }}
              value={dynamicURLDet.url_alias}
            />
          </Input.Wrapper>
        </div>
        <div className="qrgenerator-div-content-form-individual-button">
          <Button
            onClick={handleAddLinks}
            style={{ backgroundColor: "#1357be" }}
            mt={"sm"}
            mb={"sm"}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default URLQr;
