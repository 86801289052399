import React from "react";
import { Input, Text, Textarea } from "@mantine/core";

const WhatsappQr = ({ qrDetails, setQrDetails }) => {
  return (
    <div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>Phone Number</Text>}>
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  whatsapp: {
                    ...qrDetails.whatsapp,
                    phone_number: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.whatsapp.phone_number}
          />
        </Input.Wrapper>
      </div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper
          pb={"md"}
          label={<Text pb={"sm"}>Prefilled Message</Text>}
        >
          <Textarea
            autosize
            radius={"md"}
            minRows={4}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  whatsapp: {
                    ...qrDetails.whatsapp,
                    prefilled_message: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.whatsapp.prefilled_message}
          />
        </Input.Wrapper>
      </div>
    </div>
  );
};

export default WhatsappQr;
