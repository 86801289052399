import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../../helper/QRtypes";

const AddressInfo = () => {
  return (
    <div className="flex flex-wrap gap-4 items-start">
      <Form.Item
        className="w-[200px]"
        label="Street Address"
        rules={[basicRules("Enter Street Address")]}
        name={"business_street_address"}
      >
        <Input placeholder="Street Address" />
      </Form.Item>
      <Form.Item
        className="w-[200px]"
        label="City"
        rules={[basicRules("Enter Your City")]}
        name={"business_city_name"}
      >
        <Input placeholder="City" />
      </Form.Item>
      <Form.Item
        className="w-[200px]"
        label="Country"
        rules={[basicRules("Enter Your Country")]}
        name={"business_country_name"}
      >
        <Input placeholder="Country" />
      </Form.Item>
      <Form.Item
        className="w-[200px]"
        label="Zip Code"
        rules={[basicRules("Enter Your Zip Code")]}
        name={"business_zip_code"}
      >
        <Input placeholder="Zip Code" />
      </Form.Item>
    </div>
  );
};

export default AddressInfo;
