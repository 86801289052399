import { Form, Input, Select } from "antd";
import _ from "lodash";

import React from "react";

const DotOption = (properties) => {
  const { setQrStyle, qrstyle } = properties;
  return (
    <div className="flex items-center gap-x-4">
      <Form.Item initialValue={"Square"} name={"dot_style"} label="Dots Style">
        <Select
          className="!border !w-[200px]"
          onChange={(e) => {
            setQrStyle((prevState) => ({
              ...prevState,
              dotsOptions: {
                ...prevState?.dotsOptions,
                type: e,
              },
            }));
          }}
        >
          <Select.Option value="square">Square</Select.Option>
          <Select.Option value="dots">Dots</Select.Option>
          <Select.Option value="rounded">Rounded</Select.Option>
          <Select.Option value="extra-rounded">Extra Rounded</Select.Option>
          <Select.Option value="classy">Classy</Select.Option>
          <Select.Option value="classy-rounded">Classy Rounded</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        initialValue={"single"}
        name="dot_color_type"
        label="Color Type"
      >
        <Select
          className="!border !w-[200px]"
          onChange={(e) => {
            setQrStyle((prevState) => ({
              ...prevState,
              dotsOptions: {
                ...prevState?.dotsOptions,
                color_type: e,
                color: "#000",
                gradient:
                  e === "single"
                    ? ""
                    : {
                        type: "linear",
                        rotation: 0,
                        colorStops: [
                          {
                            offset: 0,
                            color: "#000",
                          },
                          {
                            offset: 1,
                            color: "#000",
                          },
                        ],
                      },
              },
            }));
          }}
        >
          <Select.Option value="single">Single</Select.Option>
          <Select.Option value="gradient">Gradient</Select.Option>
        </Select>
      </Form.Item>
      {_.get(qrstyle, "dotsOptions.color_type", "") === "single" ? (
        <Form.Item
          label="Color"
          name="dot_color"
          initialValue={_.get(qrstyle, "dotsOptions.color", "")}
        >
          <Input
            type="color"
            onChange={(e) => {
              setQrStyle((prevState) => ({
                ...prevState,
                dotsOptions: {
                  ...prevState?.dotsOptions,
                  color: e.target.value,
                  gradient: "",
                },
              }));
            }}
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item label="From" name={"dot_from_color"}>
            <Input
              type="color"
              onChange={(e) => {
                setQrStyle((prevState) => ({
                  ...prevState,
                  dotsOptions: {
                    ...prevState.dotsOptions,
                    gradient: {
                      ...prevState.dotsOptions.gradient,
                      colorStops: [
                        {
                          offset: 0,
                          color: e.target.value,
                        },
                        {
                          offset: 1,
                          color:
                            prevState.dotsOptions.gradient.colorStops[1].color,
                        },
                      ],
                    },
                  },
                }));
              }}
              className="!w-[100px]"
            />
          </Form.Item>
          <Form.Item label="To" name={"dot_to_color"}>
            <Input
              type="color"
              onChange={(e) => {
                setQrStyle((prevState) => ({
                  ...prevState,
                  dotsOptions: {
                    ...prevState.dotsOptions,
                    gradient: {
                      ...prevState.dotsOptions.gradient,
                      colorStops: [
                        {
                          offset: 0,
                          color:
                            prevState.dotsOptions.gradient.colorStops[0].color,
                        },
                        {
                          offset: 1,
                          color: e.target.value,
                        },
                      ],
                    },
                  },
                }));
              }}
              className="!w-[100px]"
            />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default DotOption;
