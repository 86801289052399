import React from "react";
import axios from "axios";
import { Download, Qrcode } from "tabler-icons-react";
import { Card } from "@mantine/core";
import { useState, useEffect } from "react";
import config from "../../Config/Server/Server";

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);
  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    hanldeFetchGallery(user_id);
  }, [user_id]);

  const hanldeFetchGallery = async (id) => {
    try {
      const res = await axios.get(
        `${config.baseAPI}/fetch_uploadimges.php?id=${id}`
      );
      setGalleryData(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownload = (imageFileName) => {
    const downloadUrl = `${config.baseAPI}/image_gallery/${imageFileName}`;

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = imageFileName;
    link.target = "_blank";
    link.rel = "noopener noreferrer";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="transaction-div">
        <h4 className="dashboard-div-heading1">
          <Qrcode size={16} strokeWidth={1} />
          Transactions
        </h4>
        <div className="dashboard-div-latest-qr-table-content">
          <Card>
            <table className="latest-qr-table">
              <thead>
                <tr>
                  <th>Name / Content</th>
                  <th>Image Name</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(galleryData) ? (
                  galleryData.map((value, index) => (
                    <tr key={index}>
                      <td>{value.content}</td>
                      <td>
                        <a
                          href={`${config.baseAPI}/image_gallery/${value.image}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {value.image}
                        </a>
                      </td>
                      <td>
                        <Download
                          onClick={() => handleDownload(value.image)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} style={{ textAlign: "center" }}>
                      No Data Found to Display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
