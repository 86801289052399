import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// CSS
import "./Pricing.css";

// Components
import Navbar from "../../Layouts/Navbar/Navbar";

// Mantine Core
import {
  Card,
  Center,
  Grid,
  Text,
  Title,
  List,
  Flex,
  Button,
  Badge,
  ThemeIcon,
} from "@mantine/core";

// Icons
import { Check, CircleCheck, X } from "tabler-icons-react";

// Components
import FrequentlyAsked from "../../Components/FrequentlyAsked/FrequentlyAsked";

// Layouts
import Footer from "../../Layouts/Footer/Footer";
import axios from "axios";
import config from "../../Config/Server/Server";
import { showNotification } from "@mantine/notifications";

// Mantine Media Query
import { useMediaQuery } from "@mantine/hooks";

const Pricing = () => {
  // Scroll to TOP
  window.scrollTo(0, 0);

  // Price List Data
  const pricingList = [
    `QR code types`,
    `saved QR codes`,
    `dynamic links`,
    `projects`,
    `days statistics retention`,
    `Included analytics`,
    `Password protection`,
    `Sensitive content`,
    `Cloaking short URLs`,
    `Auto open app on mobile `,
    `Custom back-half URL`,
    `QR Reader`,
    `API access `,
    `No Ads`,
  ];

  const icons = {
    free: [0, 11],
    annual: [0, 1, 2, 3, 4, 5, 7, 8, 9, 13, 14, 15, 16],
    lifetime: [0, 1, 2, 3, 4, 5, 7, 8, 9, 13, 14, 15, 16],
  };
  const [razorpayDet, setRazorpayDet] = useState({
    razorpayresponse: "",
    subscriptiondet: "",
  });
  const userdata = useSelector((state) => state.userData.value);

  useEffect(() => {
    if (razorpayDet.razorpayresponse !== "") {
      axios
        .post(`${config.baseAPI}/success.php`, razorpayDet, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((success) => {
          showNotification({
            icon: (
              <ThemeIcon variant="light" radius="xl" size="xl" color="green">
                <CircleCheck color="green" />
              </ThemeIcon>
            ),
            message: "Plan successfully Subscripted",
          });
          setRazorpayDet((prevState) => ({
            ...prevState,
            razorpayresponse: "",
            subscriptiondet: "",
          }));
        })
        .catch((error) => {
          showNotification({
            icon: (
              <ThemeIcon variant="light" radius="xl" size="xl" color="red">
                <X color="red" />
              </ThemeIcon>
            ),
            message: "Error in Subcribe Plan",
          });
        });
    }
  }, [razorpayDet.razorpayresponse]);

  window.handlePaymentSuccess = function (response) {
    setRazorpayDet((prevState) => ({
      ...prevState,
      razorpayresponse: response,
    }));
  };

  const handlePlan = async (amount, type) => {
    var body = {
      user_id: userdata.id,
      user_name: userdata.firstname,
      user_mail: userdata.email,
      amount: amount,
      type: type,
    };

    try {
      const response = await axios.post(
        `${config.baseAPI}/checkout.php`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.razorpayConfig) {
        const razorpayConfig = response.data.razorpayConfig;
        setRazorpayDet((prevState) => ({
          ...prevState,
          subscriptiondet: response.data.subscription,
        }));

        if (typeof razorpayConfig.handler === "string") {
          razorpayConfig.handler = window[razorpayConfig.handler];
        }
        sessionStorage.setItem(
          "redirect_url",
          response.data.razorpayConfig.redirect
        );
        
        const rzp = new window.Razorpay(razorpayConfig);

        rzp.on("payment.success", function (response) {
         

          if (response && response.status === "success") {
            alert("Payment Success");
          } else {
            alert("Error in Payment");
          }
        });

        rzp.on("payment.error", function (error) {
          console.error("Payment Error:", error);
          alert("Payment Error");
        });

        rzp.on("payment.cancel", function (response) {
          console.error("Payment Cancelled:", response);
          alert("Payment Cancelled");
        });

        rzp.open();
      } else if (response.data && response.data.error) {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "You Already Purchased a Plan",
        });
      } else {
        console.error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // media Query
  const media1000px = useMediaQuery("(max-width:1000px)");
  const media600px = useMediaQuery("(max-width:600px)");
  const media540px = useMediaQuery("(max-width:540px)");
  return (
    <div>
      <div
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: "white",
          zIndex: "1234",
        }}
      >
        <Navbar />
      </div>
      <div className="pricing-container">
        <div className="pricing-container-title">
          <Center>
            <Title style={{ textAlign: media540px && "center" }}>
              <span className="welcome-banner-container-title-qr">
                Pricing&nbsp;
              </span>
              is Simple and Transparent
            </Title>
          </Center>
          <Center>
            <Text size="lg">
              Choose a suitable plan for you and your budget.
            </Text>
          </Center>
        </div>
        {/* Grid Container */}
        <div className="pricing-container-grid">
          <Grid
            justify="center"
            className="pricing-container-grid-mantine"
            style={{ overflow: "visible" }}
            columns={media600px ? 1 : media1000px ? 2 : 3}
          >
            <Grid.Col span={1}>
              <Card
                className="pricing-container-grid-card"
                shadow="sm"
                padding="lg"
                radius="xl"
                withBorder
              >
                <div className="pricing-container-grid-card-top-title">
                  <Center>
                    <Title style={{ fontWeight: "500" }} order={2}>
                      Free
                    </Title>
                  </Center>
                </div>
                <div className="pricing-container-grid-pricing">
                  <div style={{ padding: "0 0 1rem 0" }}>
                    <Center>
                      <Badge color="blue">Only 3 QR types</Badge>
                    </Center>
                    <Center>
                      <Title>Free</Title>
                    </Center>
                  </div>
                  <div className="pricing-container-grid-pricing-list">
                    <ul>
                      <li>
                        {pricingList.map((value, index) => {
                          return (
                            <Flex
                              key={index}
                              justify={"space-between"}
                              align={"center"}
                            >
                              <Flex align={"center"}>
                                <p>
                                  <span
                                    style={{ paddingRight: "0.1rem" }}
                                    className="welcome-banner-container-title-qr"
                                  >
                                    {index === 0
                                      ? "3"
                                      : index === 1
                                      ? "0"
                                      : index === 2
                                      ? "0"
                                      : index === 3
                                      ? "0"
                                      : index === 4
                                      ? "0"
                                      : null}
                                  </span>
                                  {value}
                                </p>
                              </Flex>
                              {index === 0 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 11 ? (
                                <Check size={"1.1rem"} />
                              ) : (
                                <X size={"1rem"} />
                              )}
                            </Flex>
                          );
                        })}
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-container-grid-button">
                    <Button
                      radius={"md"}
                      className={Number(userdata.prime_user) === 1 ? "green-btn" : ""}
                    >
                       {Number(userdata.prime_user) === 1
                        ? "Active"
                        : "choose"} plan
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid.Col>
            <Grid.Col className={media600px && "pricing-last-grid"} span={1}>
              <Card
                className="pricing-container-grid-card"
                shadow="sm"
                padding="lg"
                radius="xl"
                withBorder
              >
                <div className="pricing-container-grid-card-top-title">
                  <Center>
                    <Title style={{ fontWeight: "500" }} order={2}>
                      Annual
                    </Title>
                  </Center>
                </div>
                <div className="pricing-container-grid-pricing">
                  <div style={{ padding: "0 0 1rem 0" }}>
                    <Center>
                      <Text td="line-through">₹ 3999</Text>
                      &nbsp;
                      <Badge color="blue">50% off</Badge>
                    </Center>
                    <Center>
                      <Title>₹ 1999</Title>
                    </Center>
                  </div>
                  <div className="pricing-container-grid-pricing-list">
                    <ul>
                      <li>
                        {pricingList.map((value, index) => {
                          return (
                            <Flex
                              key={index}
                              justify={"space-between"}
                              align={"center"}
                            >
                              <Flex align={"center"}>
                                <p>
                                  <span
                                    style={{ paddingRight: "0.1rem" }}
                                    className="welcome-banner-container-title-qr"
                                  >
                                    {index === 0
                                      ? "12"
                                      : index === 1
                                      ? "100"
                                      : index === 2
                                      ? "50"
                                      : index === 3
                                      ? "100"
                                      : index === 4
                                      ? "120"
                                      : null}
                                  </span>
                                  {value}
                                </p>
                              </Flex>
                              {index === 0 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 1 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 2 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 3 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 5 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 6 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 4 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 11 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 12 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 13 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 14 ? (
                                <Check size={"1.1rem"} />
                              ) : index === 15 ? (
                                <Check size={"1.1rem"} />
                              ) : (
                                <X size={"1rem"} />
                              )}
                            </Flex>
                          );
                        })}
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-container-grid-button">
                    <Button
                      className={Number(userdata.prime_user) === 2 ? "green-btn" : ""}
                      onClick={(e) => {
                        handlePlan(999, "A");
                      }}
                      radius={"md"}
                    >
                       {Number(userdata.prime_user) === 2
                        ? "Active"
                        : "choose"} plan
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid.Col>
            <Grid.Col className="pricing-last-grid" span={1}>
              <Card
                className="pricing-container-grid-card"
                shadow="sm"
                padding="lg"
                radius="xl"
                withBorder
              >
                <div className="pricing-container-grid-card-top-title">
                  <Center>
                    <Title style={{ fontWeight: "500" }} order={2}>
                      Lifetime
                    </Title>
                  </Center>
                </div>
                <div className="pricing-container-grid-pricing">
                  <div style={{ padding: "0 0 1rem 0" }}>
                    <Center>
                      <Text td="line-through">₹ 15999</Text> &nbsp;
                      <Badge color="blue">50% off</Badge>
                    </Center>
                    <Center>
                      &nbsp;
                      <Title>₹ 7999</Title>
                    </Center>
                  </div>
                  <div className="pricing-container-grid-pricing-list">
                    <ul>
                      <li>
                        {pricingList.map((value, index) => {
                          return (
                            <Flex
                              key={index}
                              justify={"space-between"}
                              align={"center"}
                            >
                              <Flex align={"center"}>
                                <p>
                                  <span
                                    style={{ paddingRight: "0.1rem" }}
                                    className="welcome-banner-container-title-qr"
                                  >
                                    {index === 0
                                      ? "12"
                                      : index === 1
                                      ? "Unlimited"
                                      : index === 2
                                      ? "Unlimited"
                                      : index === 3
                                      ? "Unlimited"
                                      : index === 4
                                      ? "Unlimited"
                                      : ""}
                                  </span>
                                  {value}
                                </p>
                              </Flex>
                              {index !== 7 ? (
                                <Check size={"1.1rem"} />
                              ) : (
                                <X size={"1rem"} />
                              )}
                            </Flex>
                          );
                        })}
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-container-grid-button">
                    <Button
                      className={Number(userdata.prime_user) === 3 ? "green-btn" : ""}
                      onClick={(e) => {
                        handlePlan(5999, "L");
                      }}
                      radius={"md"}
                    >
                      {Number(userdata.prime_user) === 3
                        ? "Active"
                        : "choose"} plan
                    </Button>
                  </div>
                </div>
                
              </Card>
            </Grid.Col>
          </Grid>
        </div>
        {/* Grid Container End */}

        <div>
          <FrequentlyAsked />
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default Pricing;
