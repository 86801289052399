const local = {
  baseAPI: "http://localhost/qr_php_server",
};

const staging = {
  baseAPI: "https://www.qrcodeweboney.in/wqc_api/",
};

const config = {
  local,
  staging,
};

export default config["staging"];
