import { Card, FileInput, Flex, Grid, Text, ThemeIcon } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useRef, useState } from "react";
import QrReader from "react-qr-reader";
import { ClipboardCopy, Photo, X } from "tabler-icons-react";
import "./QrCodeReader.css";
const QrCodeReader = () => {
  // USeReff
  const qrReff = useRef();

  // Scroll to TOP
  window.scrollTo(0, 0);

  // State
  const [image, setImage] = useState();
  const [result, setResult] = useState("");

  // Handle Image Upload to QR Code Reader
  const uploadImage = (image) => {
    qrReff.current.openImageDialog(image);
    setImage(image);
  };

  const fileScanned = (e) => {
    if (e) {
      setResult(e);
    }
  };

  const handleError = (err) => {
    if (err) {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Reading File",
      });
    }
  };

  return (
    <div>
      <div className="qr-code-reader-page">
        <div className="qr-code-reader-container-admin">
          <Grid columns={2}>
            <Grid.Col span={1}>
              <Card
                mih={"17vh"}
                shadow="sm"
                padding="lg"
                radius="md"
                withBorder
              >
                <Card.Section p={"lg"}>Image</Card.Section>
                <FileInput
                  accept="image/*"
                  onClick={uploadImage}
                  className="qr-code-reader-container-admin-file-input"
                  size="xl"
                  leftSection={<Photo />}
                  placeholder={<Text>Click to Upload Qr code Image</Text>}
                  leftSectionPointerEvents="none"
                />
              </Card>
            </Grid.Col>
            <Grid.Col span={1}>
              <Card
                mih={"14vh"}
                shadow="sm"
                padding="lg"
                radius="md"
                withBorder
              >
                <Card.Section p={"lg"}>
                  <QrReader
                    ref={qrReff}
                    style={{ display: "none" }}
                    delay={300}
                    onScan={fileScanned}
                    legacyMode={true}
                    onError={handleError}
                  />
                  <Flex align={"center"} justify={"space-between"}>
                    Data
                    <ClipboardCopy size={"1.3rem"} />
                  </Flex>
                </Card.Section>
                <Text
                  style={{
                    color: "#B5BCC3",
                    border: "1px solid #B5BCC3",
                    padding: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  {result ? result : " upload file to get QR Data"}
                </Text>
              </Card>
            </Grid.Col>
          </Grid>
          <Grid columns={1} mt={"xl"}>
            <Grid.Col span={1}>
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                Change me from the admin panel's language manager. You can
                include additional useful information here.
              </Card>
            </Grid.Col>
          </Grid>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
        }}
      ></div>
    </div>
  );
};

export default QrCodeReader;
