import React, { useEffect, useRef, useState } from "react";

// Mantine Core
import {
  ActionIcon,
  Grid,
  Input,
  Select,
  Text,
  Textarea,
  ThemeIcon,
} from "@mantine/core";

// React Date Picker
import ReactDatePicker from "react-datepicker";
import { DateInput, TimeInput } from "@mantine/dates";

// CSS
import "./EventQr.css";

// Tabler iCons
import { ClockHour5 } from "tabler-icons-react";
import moment from "moment";

const EventQr = ({
  qrDetails,
  setQrDetails,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleStartDateChange,
  handleEndDateChange,
  timezones,
}) => {
  // Date State
  const [mantineDate, setMantineDate] = useState({
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  });

  // UseReff
  const startTimeRef = useRef();
  const endTimeRef = useRef();

  // Start On Picker
  const startsOnPickerControl = (
    <ThemeIcon
      style={{ cursor: "pointer" }}
      color="white"
      onClick={() => startTimeRef.current?.showPicker()}
    >
      <ClockHour5 color="black" strokeWidth={1} />
    </ThemeIcon>
  );

  // End On Picker
  const EndsOnPickerControl = (
    <ThemeIcon
      style={{ cursor: "pointer" }}
      color="white"
      onClick={() => endTimeRef.current?.showPicker()}
    >
      <ClockHour5 color="black" strokeWidth={1} />
    </ThemeIcon>
  );

  useEffect(() => {
    if (mantineDate.startDate) {
      const startsDate = `${
        mantineDate.startDate + " " + mantineDate.startTime
      }`;
      setStartDate(startsDate);
      handleStartDateChange(mantineDate.startDate,mantineDate.startTime);
    }
    if (mantineDate.endDate) {
      const endsDate = `${mantineDate.endDate + " " + mantineDate.endTime}`;
      setEndDate(endsDate);
      handleEndDateChange(mantineDate.endDate, mantineDate.endTime);
    }
  }, [mantineDate]);

  return (
    <div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>Event Name</Text>}>
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  event: {
                    ...qrDetails.event,
                    event_name: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.event.event_name}
          />
        </Input.Wrapper>
      </div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>GEO Location</Text>}>
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  event: {
                    ...qrDetails.event,
                    geo_location: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.event.geo_location}
          />
        </Input.Wrapper>
      </div>
      {/* <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>Event URL</Text>}>
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  event: {
                    ...qrDetails.event,
                    event_url: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.event.event_url}
          />
        </Input.Wrapper>
      </div> */}
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>Notes</Text>}>
          <Textarea
            radius={"md"}
            autosize
            minRows={4}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  event: {
                    ...qrDetails.event,
                    notes: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.event.notes}
          />
        </Input.Wrapper>
      </div>
      <Grid columns={4}>
        <Grid.Col span={1}>
          <Input.Wrapper label={<Text pb={"xs"}>Starts Date</Text>}>
            <DateInput
              size="sm"
              minDate={new Date()}
              onChange={(e) => {
                setMantineDate({
                  ...mantineDate,
                  startDate: e,
                  endDate: "",
                });
              }}
              value={mantineDate.startDate}
              placeholder="Date input"
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={1}>
          <Input.Wrapper label={<Text pb={"xs"}>Starts Time</Text>}>
            <TimeInput
              rightSection={startsOnPickerControl}
              ref={startTimeRef}
              onChange={(e) => {
                setMantineDate({
                  ...mantineDate,
                  startTime: e.target.value,
                });
              }}
              value={mantineDate.startTime}
              placeholder="Start Time"
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={1}>
          <Input.Wrapper label={<Text pb={"xs"}>Ends Date</Text>}>
            <DateInput
              size="sm"
              minDate={mantineDate.startDate}
              onChange={(e) => {
                setMantineDate({
                  ...mantineDate,
                  endDate: e,
                });
              }}
              value={mantineDate.endDate}
              placeholder="Date input"
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={1}>
          <Input.Wrapper label={<Text pb={"xs"}>Ends Time</Text>}>
            <TimeInput
              rightSection={EndsOnPickerControl}
              ref={endTimeRef}
              onChange={(e) => {
                setMantineDate({
                  ...mantineDate,
                  endTime: e.target.value,
                });
              }}
              value={mantineDate.endTime}
              placeholder="Start Time"
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default EventQr;
