import React from "react";

// CSS
import "./QrTypes.css";

// Mantine Core
import { Card, Center, Grid, Text, Title, Button } from "@mantine/core";
import {
  BrandWhatsapp,
  CalendarEvent,
  Cards,
  Link,
  Mail,
  MapPin,
  MessageCircle,
  PhoneCall,
  TextSize,
  Transform,
  VideoPlus,
  Wifi,
} from "tabler-icons-react";

// Media Quuery Mantine
import { useMediaQuery } from "@mantine/hooks";

const QrTypes = () => {
  const typesData = [
    {
      title: `Text`,
      icon: <TextSize size={"3rem"} />,
      desc: `Simple and clear text embedded into a QR code`,
    },
    {
      title: `URL`,
      icon: <Link size={"3rem"} />,
      desc: `Send someone to a link`,
    },
    {
      title: `Phone`,
      icon: <PhoneCall size={"3rem"} />,
      desc: `Get calls from people by scanning the QR code`,
    },
    {
      title: `SMS`,
      icon: <MessageCircle size={"3rem"} />,
      desc: `Get custom SMS messages from people by scanning the QR code`,
    },
    {
      title: `Email`,
      icon: <Mail size={"3rem"} />,
      desc: `Get custom Emails from people by scanning the QR code`,
    },
    {
      title: `Whatsapp`,
      icon: <BrandWhatsapp size={"3rem"} />,
      desc: `Get custom Whatsapps from people by scanning the QR code`,
    },
    {
      title: `Factime`,
      icon: <VideoPlus size={"3rem"} />,
      desc: `Get Facetime calls from people by scanning the QR code`,
    },
    {
      title: `Location`,
      icon: <MapPin size={"3rem"} />,
      desc: `Embed location coordinates for people to get directions more easily`,
    },
    {
      title: `Wifi`,
      icon: <Wifi size={"3rem"} />,
      desc: `Create Wifi QR codes and let people connect to your Wifi more easily`,
    },
    {
      title: `Event`,
      icon: <CalendarEvent size={"3rem"} />,
      desc: `Generate a custom life calendar event and embed it into a QR code`,
    },
    {
      title: `Vcard`,
      icon: <Cards size={"3rem"} />,
      desc: `Embed all your contact data into a digital business card`,
    },
    {
      title: `UPI transaction`,
      icon: <Transform size={"3rem"} />,
      desc: `Create PayPal direct payment links`,
    },
  ];

  const media1060px = useMediaQuery("(max-width:1060px)");
  const media600px = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <div className="qrtypes-container">
        <div className="qrtypes-container-title">
          <Center>
            <Title order={media600px ? 2 : 1}>
              <span className="welcome-banner-container-title-qr"> QR </span>
              code types
            </Title>
          </Center>
          <Text style={{ textAlign: media600px ? "center" : "" }}>
            A large array of QR code templates to choose from and get started.
          </Text>
        </div>
        <div className="qrtypes-container-grid">
          <Grid columns={media600px ? 1 : media1060px ? 2 : 3}>
            {typesData.map((value, index) => {
              return (
                <Grid.Col span={1} key={index}>
                  <Card shadow="md" padding="lg" radius="md" withBorder>
                    <Card.Section
                      pt={"xl"}
                      pl={"xl"}
                      pr={"xl"}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Text style={{ color: "#6ea6fa" }}>{value.icon}</Text>
                    </Card.Section>
                    <div style={{ margin: "1rem 0 0rem 0" }}>
                      <Center>
                        <Title order={2}>{value.title}</Title>
                      </Center>
                      <Center>
                        <Text style={{ textAlign: "center" }}>
                          {value.desc}
                        </Text>
                      </Center>
                    </div>
                    <Center m={"xl"}>
                      <Button className="qrtypes-container-grid-btn" w={"100%"}>
                        Generate {value.title} QR Code
                      </Button>
                    </Center>
                  </Card>
                </Grid.Col>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default QrTypes;
