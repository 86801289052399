import axios from "axios";
// Server
import config from "../../Server/Server";

export const allDynamicLinks = async (body) => {
  return await axios.post(`${config.baseAPI}/all_dynamic_links.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const addDynLinks = async (body) => {
  return await axios.post(`${config.baseAPI}/dynamic_links.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const updateDynLinks = async (body) => {
  return await axios.post(`${config.baseAPI}/update_dynamic_links.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const fetchDynLinks = async (id) => {
  return await axios.post(`${config.baseAPI}/fetch_dynamic_links.php?id=${id}`);
};
export const deleteDynLinks = async (body) => {
  return await axios.post(`${config.baseAPI}/delete_dynamic_links.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const addLink = async (body) => {
  return await axios.post(`${config.baseAPI}/add_link.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateLinksfuk = async (body) => {
  return await axios.post(`${config.baseAPI}/update_link.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
