import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Transactions.css";
// Axios
import axios from "axios";

// Config
import config from "../../Config/Server/Server";
import { Download, Qrcode } from "tabler-icons-react";
import { Card } from "@mantine/core";
import moment from "moment";
const Transactions = () => {
  const [transactionData, setTransactionData] = useState();
  const userdata = useSelector((state) => state.userData.value);
  useEffect(() => {
    hanldeFetchSubscription(userdata.id);
  }, [userdata]);

  const hanldeFetchSubscription = async (id) => {
    try {
      const res = await axios
        .get(`${config.baseAPI}/fetch_all_transaction.php?id=${id}`)
        .then((res) => {
          setTransactionData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.error(err);
    }
  };
  const handileDownload = async (id) => {
    try {
      const response = await axios.get(
        `${config.baseAPI}/generate_invoice.php?id=${id}`,
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="transaction-div">
        <h4 className="dashboard-div-heading1">
          <Qrcode size={16} strokeWidth={1} />
          Transactions
        </h4>
        <div className="dashboard-div-latest-qr-table-content">
          <Card>
            <table className="latest-qr-table">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(transactionData) ? (
                  transactionData?.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {value.type === "A"
                            ? "Annual"
                            : value.type === "L"
                            ? "LifeTime"
                            : "Free"}
                        </td>
                        <td>{moment(value.from_date).format("DD-MM-YYYY")}</td>
                        <td>{value.to_date === "∞" ? "∞" :moment(value.to_date).format("DD-MM-YYYY")}</td>
                        <td>{value.status}</td>
                        <td>{value.gst_amount}</td>
                        <td>
                          <Download
                            onClick={(e) => {
                              handileDownload(value.id);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} style={{ textAlign: "center" }}>
                      No Data Found to Display
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
