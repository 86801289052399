import { Button, Drawer, Form, Input, Space } from "antd";
import Upload from "antd/es/upload/Upload";
import React from "react";
import { useState } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { message, Divider, Select } from "antd";
import {
  handleBeforeUpload,
  handleImageUpload,
} from "../../../../helper/QRtypes";
import {
  errorNotification,
  successNotification,
} from "../../../../Components/Notification/notify";
import _ from "lodash";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";

const SocialMediaForm = () => {
  return (
    <div>
      <Form.List name="social_media" className="flex">
        {(fields, { add, remove }) => (
          <>
            <div className="!w-full flex justify-end">
              <Form.Item className="!w-fit px-3">
                <Button onClick={() => add()} block icon={<PlusOutlined />} className="!bg-primaryColor !text-white">
                  <span className="!text-white"> Add new</span>
                </Button>
              </Form.Item>
            </div>
            <div className="flex flex-wrap gap-2">
              {fields.map(({ key, name, ...restField }, index) => (
                <>
                  <div className="flex items-center gap-x-4 shadow-lg px-5 py-2 rounded-lg  justify-start flex-wrap bg-white">
                    <Form.Item
                      {...restField}
                      name={[name, "social"]}
                      label="Social Media"
                      rules={[
                        {
                          required: true,
                          message: "select a social media",
                        },
                      ]}
                    >
                      <Select className="!w-[120px] !border !border-gray-400 !rounded-lg">
                        <Select.Option key={"instagram"}>instagram</Select.Option>
                        <Select.Option key={"twitter"}>twitter</Select.Option>
                        <Select.Option key={"facebook"}>facebook</Select.Option>
                        <Select.Option key={"youtube"}>youtube</Select.Option>
                        <Select.Option key={"linkedin"}>linkedin</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      label="URL"
                      name={[name, "to"]}
                      rules={[
                        {
                          required: true,
                          message: "Enter Social Media URL",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Social Media URL" className="!w-[400px] !h-[50px]" />
                    </Form.Item>
                    <div className="!h-[50px]  center_div">
                      <MdOutlineDeleteOutline onClick={() => remove(name)} className="text-red-500 cursor-pointer " />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default SocialMediaForm;
