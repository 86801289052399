import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// CSS
import "./TypeBtns.css";

// Mantine Core
import { Flex, Grid, GridCol } from "@mantine/core";

// Icons
import {
  TextSize,
  Link as LinkIcon,
  PhoneCall,
  Messages,
  Mail,
  BrandWhatsapp,
  MapPin,
  Wifi,
  CalendarEvent,
  Cards,
  Transform,
  VideoPlus,
  Video,
} from "tabler-icons-react";

// Redux State
import { useSelector, useDispatch } from "react-redux";
import { setSelectedType } from "../../StateHandler/Slice/SelectedType/SelectedTypeSlice";

// Mantine Media
import { useMediaQuery } from "@mantine/hooks";

import { useNavigate } from "react-router-dom";
import { notification } from "antd";

const TypeBtns = ({ premiumMember }) => {
  // State
  const [qrTab, setQrTab] = useState("Text");

  // navigation
  const navigate = useNavigate();

  // Redux State
  const selectedType = useSelector((state) => state.selectedType.value);
  const dispatch = useDispatch();

  // UseEffect
  useEffect(() => {
    dispatch(setSelectedType(qrTab));
  }, [qrTab]);

  // Media Query
  const media786px = useMediaQuery("(max-width:786px)");

  const handleLoginTriger = () => {
    navigate("/login");
    notification.warning({
      message: "To utilize this feature Login is Required",
    });
  };

  return (
    <div>
      <div className="generate-qr-div-content">
        <div className="generate-qr-div-content-qr-type-btn1">
          <button
            onClick={() => {
              setQrTab("Text");
            }}
            className={qrTab === "Text" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <TextSize size={"1.2rem"} />
              Text
            </Flex>
          </button>
          <button
            onClick={() => {
              setQrTab("Phone");
            }}
            className={qrTab === "Phone" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <PhoneCall size={"1.2rem"} />
              Phone
            </Flex>
          </button>
          <button
            onClick={() => {
              setQrTab("Sms");
            }}
            className={qrTab === "Sms" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <Messages size={"1.2rem"} />
              SMS
            </Flex>
          </button>
          <button
            onClick={handleLoginTriger}
            className={qrTab === "Url" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <LinkIcon size={"1.2rem"} />
              URL
            </Flex>
          </button>
          <button
            onClick={handleLoginTriger}
            className={qrTab === "Email" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <Mail size={"1.2rem"} />
              Email
            </Flex>
          </button>
          <button
            onClick={handleLoginTriger}
            className={qrTab === "Whatsapp" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <BrandWhatsapp size={"1.2rem"} />
              Whatsapp
            </Flex>
          </button>
          <button
            onClick={handleLoginTriger}
            className={qrTab === "Facetime" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <Video size={"1.2rem"} />
              Facetime
            </Flex>
          </button>
        </div>

        {/* Button 2 */}
        <div className="generate-qr-div-content-qr-type-btn2">
          <button
            onClick={handleLoginTriger}
            className={qrTab === "Location" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <MapPin size={"1.2rem"} />
              Location
            </Flex>
          </button>
          <button
            onClick={handleLoginTriger}
            className={qrTab === "Wifi" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <Wifi size={"1.2rem"} />
              Wifi
            </Flex>
          </button>
          <button
            onClick={handleLoginTriger}
            className={qrTab === "Event" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <CalendarEvent size={"1.2rem"} />
              Event
            </Flex>
          </button>
          <button
            onClick={handleLoginTriger}
            className={qrTab === "Vcard" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <Cards size={"1.2rem"} />
              Vcard
            </Flex>
          </button>
          <button
            onClick={handleLoginTriger}
            className={qrTab === "Payment" ? "active" : ""}
          >
            <Flex align={"center"} justify={"center"} gap={"0.2rem"}>
              <Transform size={"1.2rem"} />
              Payment
            </Flex>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TypeBtns;
