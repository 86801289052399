import {
  addDynLinks,
  allDynamicLinks,
  deleteDynLinks,
  fetchDynLinks,
  updateDynLinks,
} from "../../Config/Quries/DynamicLinks/DynamicLinksQuries";

import { ThemeIcon } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { CircleCheck, X } from "tabler-icons-react";
export const TotalDynamicLinks = async (user_id, setDynamicLinkData) => {
  var body = {
    user_id: user_id,
  };
  allDynamicLinks(body).then((result) => {
    if (result.status === 200) {
      setDynamicLinkData(result.data);
    }
  });
};
export const AddDynamicLinks = async (
  user_id,
  setRefetchData,
  dynamicURLDet,
  setDynamicURLDet,
  dynamicclose
) => {
  const { destination_url, url_alias } = dynamicURLDet;

  const body = {
    user_id: user_id,
    destination_url: destination_url,
    url_alias: url_alias,
  };
  addDynLinks(body)
    .then((result) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="green">
            <CircleCheck color="green" />
          </ThemeIcon>
        ),
        message: "Link Created Successfully",
      });
      setRefetchData(false);
      setDynamicURLDet({
        ...dynamicURLDet,
        destination_url: "",
        url_alias: "",
      });
      dynamicclose();
    })
    .catch((err) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Creating Links",
      });
    });
};
export const UpdateDynamicLinks = async (
  setRefetchData,
  editData,
  setEditData,
  dynamiccloseedit
) => {
  var body = {
    id: editData.id,
    destination_url: editData.url,
    url_alias: editData.alias,
  };
  updateDynLinks(body)
    .then((result) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="green">
            <CircleCheck color="green" />
          </ThemeIcon>
        ),
        message: "Link Updated Successfully",
      });
      setRefetchData(false);
      setEditData({
        ...editData,
        id: "",
        url: "",
        alias: "",
      });
      dynamiccloseedit();
    })
    .catch((err) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Updating Links",
      });
    });
};
// export const FetchDynamicLinks = async (id, setEditData, editData) => {
//   fetchDynLinks(id).then((result) => {
//     setEditData({
//       ...editData,
//       id: result.data[0]["id"],
//       url: result.data[0]["destination_url"],
//       alias: result.data[0]["url_alias"],
//     });
//   });
// };
export const DeleteDynamicLinks = async (id, setRefetchData) => {
  var body = {
    id: id,
  };
  deleteDynLinks(body)
    .then((result) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="green">
            <CircleCheck color="green" />
          </ThemeIcon>
        ),
        message: "Link Deleted Successfully",
      });
      setRefetchData(false);
    })
    .catch((err) => {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Error Deleting Link",
      });
    });
};
