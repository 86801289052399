// Images
import QRTemplates from "../../../Assets/CardsTypes/QrTemplates.png";
import Privacy from "../../../Assets/CardImages/Privacy.png";
import Customizable from "../../../Assets/CardsTypes/EditQr.png";
import Tracking from "../../../Assets/CardImages/Pixels.png";
import Projects from "../../../Assets/CardsTypes/Projects.png";
import CustomDomains from "../../../Assets/CardImages/CustomDomains.png";

export const cardState = [
  {
    title: `QR templates`,
    desc: `You get 12 built-in QR code templates to get you started right away.`,
    image: QRTemplates,
  },
  // {
  //   title: `Privacy`,
  //   desc: `We do not store any data of your created QR codes, except the ones that you save in your account willingly.`,
  //   image: Privacy,
  // },
  {
    title: `Projects`,
    desc: `Easiest way to categorize your managed resources.`,
    image: Projects,
  },
  {
    title: `Highly customizable`,
    desc: `Create the QR code you need, brand it with your own colors & logo.`,
    image: Customizable,
  },
  // {
  //   title: `Tracking pixels`,
  //   desc: `Facebook, Google Analytics, Google Tag Manager, LinkedIn, Pinterest, X, Quora, TikTok, Snapchat tracking pixels available.`,
  //   image: Tracking,
  // },

  // {
  //   title: `Custom domains`,
  //   desc: `Connect your own domain or use our predefined ones.`,
  //   image: CustomDomains,
  // },
];
