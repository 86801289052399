import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// CSS
import "./Navbar.css";

// Images
import BrandLogo from "../../Assets/BrandLogo/NewLogo.jpg";

// Icons
import {
  LayoutDashboard,
  Login,
  Logout,
  UserCircle,
  UserPlus,
} from "tabler-icons-react";

// Mantine Core
import {
  Burger,
  Drawer,
  Flex,
  Grid,
  Image,
  Menu,
  ScrollArea,
  Text,
} from "@mantine/core";

// React Query
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

// Quries
import { getUserDataById } from "../../Config/Quries/User/userQuries";
import { setPrimeUser } from "../../StateHandler/Slice/UserLogin/UserLoginSlice";
import { setUserData } from "../../StateHandler/Slice/userData/userDataSlice";

// Axios
import axios from "axios";

// Server
import config from "../../Config/Server/Server";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
const Navbar = () => {
  // Dispatch Redux
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState("");
  const userData = useSelector((state) => state.userData.value);

  const navigate = useNavigate();

  // Array List
  const ulList = ["Generate QR Code", "QR Code Reader", "Pricing"];

  // User Details

  // Permium Member
  const [premiumMember, setPremiumMember] = useState(false);

  // useQuerys
  useQuery(["userPremium", localStorage.getItem("user_id")], getUserDataById, {
    enabled: !!localStorage.getItem("user_id"),
    onSuccess: (res) => {
      // if (res.data[0]?.prime_user == 1) {
      //   dispatch(setPrimeUser(true));
      //   setPremiumMember(true);
      // } else if (res.data[0].prime_user == 2) {
      //   dispatch(setPrimeUser(true));
      //   setPremiumMember(true);
      // } else if (res.data[0].prime_user == 3) {
      //   dispatch(setPrimeUser(true));
      //   setPremiumMember(true);
      // }
      dispatch(setUserData(res.data[0]));
      if (userDetails) {
        if (_.get(res, "data[0].role", "") != "admin") {
          navigate("/dashboard");
        }
      }
    },
  });

  // UseEffect
  useEffect(() => {
    if (localStorage.getItem("user_name")) {
      setUserDetails(localStorage.getItem("user_name"));
    }
  }, []);
  // Deleting QR DB of Demo ID

  // schedule expired time
  useEffect(() => {}, []);

  useEffect(() => {
    const userID = userData.prime_user;
    if (userID == 3) {
      const yourFunction = async () => {
        return await axios
          .get(`${config.baseAPI}/delete_userdata.php?id=13`)
          .then((res) => {})
          .catch((err) => {});
      };
      const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;
      const intervalId = setInterval(yourFunction, twelveHoursInMilliseconds);
      return () => clearInterval(intervalId);
    }
  }, []);

  const [opened, { toggle }] = useDisclosure();
  const [navopened, { open, close }] = useDisclosure(false);

  const media1000Query = useMediaQuery("(max-width:1000px)");

  return (
    <div className="nav-page">
      {media1000Query ? (
        <>
          <div className="mobile-nav-bar-container">
            <div className="mobile-nav-bar-container-content">
              <div className="mobile-nav-bar-container-content-left">
                <div className="nav-bar-logo">
                  <Link to={"/"}>
                    <Image src={BrandLogo} alt="Brand Logo" />
                  </Link>
                </div>
              </div>
              <div className="mobile-nav-bar-container-content-right">
                {!navopened && (
                  <Burger
                    opened={navopened}
                    onClick={() => {
                      toggle();
                      open();
                    }}
                    aria-label="Toggle navigation"
                  />
                )}
              </div>
            </div>
          </div>
          <Drawer.Root zIndex={12345} opened={navopened} onClose={close} scrollAreaComponent={ScrollArea.Autosize}>
            <Drawer.Overlay />
            <Drawer.Content>
              <Drawer.Header className="nav-drawer-header">
                <Drawer.Title>
                  <div className="nav-bar-logo-drawer">
                    <Link to={"/"}>
                      <Image src={BrandLogo} alt="Brand Logo" />
                    </Link>
                  </div>
                </Drawer.Title>
                <Drawer.CloseButton />
              </Drawer.Header>
              <Drawer.Body>
                <div className="drawer-navbar-link">
                  <ul>
                    {ulList.map((value, index) => {
                      return (
                        <li key={index}>
                          <Text component={Link} to={index === 0 ? "/generate-qr-code" : index === 1 ? "/read-qr-code" : index === 2 ? "/pricedetails" : ""}>
                            {value}
                          </Text>
                        </li>
                      );
                    })}
                  </ul>
                  <ul>
                    {!userDetails && (
                      <li>
                        <Text style={{ fontWeight: "600" }} component={Link} to={"/generate-qr-code"}>
                          Demo
                        </Text>
                      </li>
                    )}
                    {userDetails ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        {premiumMember ? (
                          <>
                            <li>
                              <Flex
                                align={"center"}
                                gap={"0.2rem"}
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                }}
                              >
                                <Menu style={{ zIndex: "123456" }}>
                                  <Menu.Target>
                                    <Flex
                                      align={"center"}
                                      gap={"0.2rem"}
                                      style={{
                                        color: "black",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <UserCircle size={"1.3rem"} className="nav-bar-link-icon" />
                                      {userDetails}
                                    </Flex>
                                  </Menu.Target>
                                  <Menu.Dropdown style={{ zIndex: "123456" }}>
                                    <Menu.Item onClick={() => window.location.reload(localStorage.clear())} leftSection={<Logout size={"1.3rem"} className="nav-bar-link-icon" />}>
                                      Logout
                                    </Menu.Item>
                                  </Menu.Dropdown>
                                </Menu>
                              </Flex>
                            </li>
                            <li>
                              <Flex
                                component={Link}
                                to={"/dashboard"}
                                align={"center"}
                                gap={"0.2rem"}
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                }}
                              >
                                <LayoutDashboard size={"1.3rem"} className="nav-bar-link-icon" />
                                Dashboard
                              </Flex>
                            </li>
                          </>
                        ) : (
                          <>
                            <Flex align={"center"} gap={"0.2rem"} style={{ color: "black", textDecoration: "none" }}>
                              <UserCircle size={"1.3rem"} className="nav-bar-link-icon" />
                              {userDetails}
                            </Flex>
                            <li onClick={() => window.location.reload(localStorage.clear())}>
                              <Flex
                                align={"center"}
                                gap={"0.2rem"}
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                }}
                              >
                                <Logout size={"1.3rem"} className="nav-bar-link-icon" />
                                Logout
                              </Flex>
                            </li>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        <li className="nav-bar-link-last-2-child">
                          <Flex component={Link} to={"/login"} align={"center"} gap={"0.2rem"} style={{ color: "#1357be", textDecoration: "none" }}>
                            <Login size={"1.3rem"} className="nav-bar-link-icon" />
                            Sign in
                          </Flex>
                        </li>
                        <li className="nav-bar-link-last-2-child">
                          <Flex component={Link} to={"/register"} align={"center"} gap={"0.2rem"} style={{ color: "#1357be", textDecoration: "none" }}>
                            <UserPlus size={"1.2rem"} className="nav-bar-link-icon" />
                            Sign up
                          </Flex>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </Drawer.Body>
            </Drawer.Content>
          </Drawer.Root>
        </>
      ) : (
        <>
          <div className="nav-bar-container">
            <Grid gutter={"lg"} style={{ gap: "1rem" }} justify="space-between" align="center" columns={5}>
              <Grid.Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                span={1}
              >
                <div className="nav-bar-logo">
                  <Link to={"/"}>
                    <Image src={BrandLogo} alt="Brand Logo" />
                  </Link>
                </div>
              </Grid.Col>
              <Grid.Col
                span={2}
                className="nav-bar-links"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ul>
                  {ulList.map((value, index) => {
                    return (
                      <li key={index}>
                        <Text component={Link} to={index === 0 ? "/generate-qr-code" : index === 1 ? "/read-qr-code" : index === 2 ? "/pricedetails" : ""}>
                          {value}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </Grid.Col>
              <Grid.Col style={{ display: "flex", justifyContent: "space-around" }} span={2} className="nav-bar-links-2">
                <ul>
                  {!userDetails && (
                    <li>
                      <Text style={{ fontWeight: "600" }} component={Link} to={"/generate-qr-code"}>
                        Demo
                      </Text>
                    </li>
                  )}
                  {userDetails ? (
                    <div
                      style={{
                        paddingLeft: "1rem",
                        display: "flex",
                        gap: "1rem",
                      }}
                    >
                      {premiumMember ? (
                        <>
                          <li>
                            <Flex align={"center"} gap={"0.2rem"} style={{ color: "black", textDecoration: "none" }}>
                              <Menu>
                                <Menu.Target>
                                  <Flex
                                    align={"center"}
                                    gap={"0.2rem"}
                                    style={{
                                      color: "black",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <UserCircle size={"1.3rem"} className="nav-bar-link-icon" />
                                    {userDetails}
                                  </Flex>
                                </Menu.Target>
                                <Menu.Dropdown>
                                  <Menu.Item onClick={() => window.location.reload(localStorage.clear())} leftSection={<Logout size={"1.3rem"} className="nav-bar-link-icon" />}>
                                    Logout
                                  </Menu.Item>
                                </Menu.Dropdown>
                              </Menu>
                            </Flex>
                          </li>
                          <li>
                            <Flex component={Link} to={"/dashboard"} align={"center"} gap={"0.2rem"} style={{ color: "black", textDecoration: "none" }}>
                              <LayoutDashboard size={"1.3rem"} className="nav-bar-link-icon" />
                              Dashboard
                            </Flex>
                          </li>
                        </>
                      ) : (
                        <>
                          <Flex align={"center"} gap={"0.2rem"} style={{ color: "black", textDecoration: "none" }}>
                            <UserCircle size={"1.3rem"} className="nav-bar-link-icon" />
                            {userDetails}
                          </Flex>
                          <li onClick={() => window.location.reload(localStorage.clear())}>
                            <Flex align={"center"} gap={"0.2rem"} style={{ color: "black", textDecoration: "none" }}>
                              <Logout size={"1.3rem"} className="nav-bar-link-icon" />
                              Logout
                            </Flex>
                          </li>
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      <li className="nav-bar-link-last-2-child">
                        <Flex component={Link} to={"/login"} align={"center"} gap={"0.2rem"} style={{ color: "#1357be", textDecoration: "none" }}>
                          <Login size={"1.3rem"} className="nav-bar-link-icon" />
                          Sign in
                        </Flex>
                      </li>
                      <li className="nav-bar-link-last-2-child">
                        <Flex component={Link} to={"/register"} align={"center"} gap={"0.2rem"} style={{ color: "#1357be", textDecoration: "none" }}>
                          <UserPlus size={"1.2rem"} className="nav-bar-link-icon" />
                          Sign up
                        </Flex>
                      </li>
                    </>
                  )}
                </ul>
              </Grid.Col>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

export default Navbar;
