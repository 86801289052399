import React from "react";
import { Form, Image, Upload } from "antd";
import config from "../../../../Config/Server/Server";
import { DeleteFilled, DeleteOutlined } from "@ant-design/icons";
import { basicRules } from "../../../../helper/QRtypes";
import _ from "lodash";

const ImagesInfo = (properties) => {
  const {
    businessImages,
    setBusinessImages,
    handleUpload,
    props,
    currentId,
    setCurrentId,
    formData,
    handleRemoveImage,
  } = properties;

  let base_URL = config.baseAPI;

  return (
    <div className="flex flex-wrap gap-8">
      <Form.Item
        label={
          <span className="flex items-center gap-x-1">
            <span className="text-red-400">*</span> Banner Image
          </span>
        }
        name={"business_banner_image"}
      >
        {businessImages.bannerImage ? (
          <div className=" !rounded-ms relative w-[100px] group h-[100px] !border-none center_div  cursor-pointer ">
            <Image
              src={`${base_URL}/${businessImages.bannerImage}`}
              alt=""
              className="!w-[100px] !h-[100px] !rounded-lg"
            />
            <div
              onClick={() => {
                handleRemoveImage("business_banner_image");
              }}
              className="text-white center_div invisible group-hover:visible !font-Poppins cursor-pointer absolute left-0 w-[100px] rounded-b-lg h-[20px] bg-red-500 -bottom-1"
            >
              Remove
            </div>
          </div>
        ) : (
          <Upload
            listType="picture-card"
            maxCount={1}
            showUploadList={false}
            onChange={_.debounce((e) => {
              handleUpload(e, "business_banner_image");
            }, 500)}
          >
            +
          </Upload>
        )}
      </Form.Item>
      <Form.Item
        label={
          <span className="flex items-center gap-x-1">
            <span className="text-red-400">*</span>Profile Image
          </span>
        }
        name={"business_profile_image"}
      >
        {businessImages.profileImage ? (
          <div className="!rounded-ms relative w-[100px] group h-[100px] !border-none center_div  cursor-pointer ">
            <Image
              src={`${base_URL}/${businessImages.profileImage}`}
              alt=""
              className="!w-[100px] !h-[100px] !rounded-lg"
            />

            <div
              onClick={() => {
                handleRemoveImage("business_profile_image");
              }}
              className="text-white center_div invisible group-hover:visible !font-Poppins cursor-pointer absolute left-0 w-[100px] rounded-b-lg h-[20px] bg-red-500 -bottom-1"
            >
              Remove
            </div>
          </div>
        ) : (
          <Upload
            listType="picture-card"
            onChange={_.debounce((e) => {
              handleUpload(e, "business_profile_image");
            }, 500)}
            maxCount={1}
            showUploadList={false}
          >
            +
          </Upload>
        )}
      </Form.Item>
    </div>
  );
};

export default ImagesInfo;
