import {
  Accordion,
  Button,
  FileInput,
  Flex,
  Grid,
  Input,
  Select,
  Text,
  Textarea,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { CalendarTime, CirclePlus, Phone } from "tabler-icons-react";
import { Modal, TimePicker, Switch, notification } from "antd";
import moment from "moment";

const Timing = (properties) => {
  const { qrDetails, setQrDetails } = properties;

  let initialDate = [
    {
      id: 1,
      day: "Monday",
      start: "09:30 am",
      end: "06:00 pm",
      status: true,
    },
    {
      id: 2,
      day: "Tuesday",
      start: "09:30 am",
      end: "06:00 pm",
      status: true,
    },
    {
      id: 3,
      day: "wednesday",
      start: "09:30 am",
      end: "06:00 pm",
      status: true,
    },
    {
      id: 4,
      day: "Thursday",
      start: "09:30 am",
      end: "06:00 pm",
      status: true,
    },
    {
      id: 5,
      day: "Friday",
      start: "09:30 am",
      end: "06:00 pm",
      status: true,
    },
    {
      id: 6,
      day: "Saturday",
      start: "09:30 am",
      end: "06:00 pm",
      status: false,
    },
    {
      id: 7,
      day: "Sunday",
      start: "09:30 am",
      end: "06:00 pm",
      status: false,
    },
  ];

  const [open, setOpen] = useState(false);
  const [days, setDays] = useState(initialDate);
  const [dummy, setDummy] = useState(false);

  useEffect(() => {
    setQrDetails({
      ...qrDetails,
      vcard: {
        ...qrDetails.vcard,
        businessHoursDetails: JSON.stringify(initialDate),
      },
    });
  }, []);

  const handleChange = (value, field, id) => {
    try {
      let bucketData = days;
      bucketData.map((res) => {
        if (field === "status") {
          return res.id === id ? (res.status = value) : res.status;
        }
        if (field === "start") {
          return res.id === id
            ? (res.start = moment(value, "HH:mm").format("h:mm A"))
            : res.start;
        }
        if (field === "end") {
          return res.id === id
            ? (res.end = moment(value, "HH:mm").format("h:mm A"))
            : res.end;
        }
      });

      setDays(bucketData);
      setDummy(!dummy);
      setQrDetails({
        ...qrDetails,
        vcard: {
          ...qrDetails.vcard,
          businessHoursDetails: JSON.stringify(bucketData),
        },
      });
    } catch (err) {
  
    }
  };

  const handleApplyClick = () => {
    setOpen(false);
    notification.success({
      message: "Business Hours Has been Successfully Applyed",
    });
  };

  return (
    <>
      <Accordion.Item value="vcard_business_hours">
        <Accordion.Control icon={<CalendarTime />}>
          Business Hours
        </Accordion.Control>
        <Accordion.Panel>
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="flex items-center gap-x-2 justify-center cursor-pointer border !text-sm  px-2 py-2 border-dashed rounded text-black"
          >
            <CirclePlus size={16} />
            <span className="!text-sm">schedule Business Hours</span>
          </div>
        </Accordion.Panel>
      </Accordion.Item>
      <Modal
        open={open}
        closable={false}
        footer={false}
        onCancel={() => {
          setOpen(false);
        }}
        title="Schedule Business Hours"
        className="!w-fit !px-10"
      >
        <div className="flex flex-col gap-y-6 pt-4 ">
          {days.map((res, index) => {
            return (
              <div className="flex  gap-y-6 gap-x-4 items-center">
                <div className="w-[100px]  capitalize  font-semibold">
                  {res.day}
                </div>
                <div className="w-[50px]">
                  <Switch
                    size="small"
                    defaultValue={res.status}
                    className="!border"
                    onChange={() => {
                      handleChange(!res.status, "status", res.id);
                    }}
                  />
                </div>
                {res.status ? (
                  <div className="w-[200px] flex gap-x-2">
                    <TimePicker
                    
                      onOk={(e) => {
                        handleChange(e, "start", res.id);
                      }}
                      format={"h:mm a"}
                      className="w-[100px]"
                      defaultValue={moment(`${res.start}`, "h:mmA")}
                    />
                    <TimePicker
                     
                      onOk={(e) => {
                        handleChange(e, "end", res.id);
                      }}
                      format={"h:mm a"}
                      className="w-[100px]"
                      defaultValue={moment(`${res.end}`, "h:mmA")}
                    />
                  </div>
                ) : (
                  <div className="text-center w-[200px] capitalize text-red-500 font-semibold tracking-wide">
                    Closed
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <Button
          onClick={handleApplyClick}
          block
          className="!bg-primaryColor !w-full mt-5 !text-white"
        >
          Apply
        </Button>
      </Modal>
    </>
  );
};

export default Timing;
