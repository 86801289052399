import React, { useEffect, useState } from "react";
import "./admin.css";
import TopNavbar from "./navbar/TopNavbar";
import SideNavbar from "./navbar/SideNavbar";
import { getAllUserCount } from "../Config/Quries/superAdmin/adminQuery";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineDashboard } from "react-icons/md";


// lodash
import _ from "lodash";

// icons
import { FiUsers } from "react-icons/fi";
import { CiLink } from "react-icons/ci";
import { MdOutlineQrCode2 } from "react-icons/md";
import { Link } from "react-router-dom";

const SuperAdmin = () => {
  const [count, setCount] = useState([]);
  const fetchData = async () => {
    const result = await getAllUserCount();
    setCount(result.data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  let data = [
    {
      id: 1,
      name: "Users",
      icons: <FiUsers />,
      count: _.get(count, "user_count", 0),
      to:"/user",
    },
    {
      id: 2,
      name: "QR Codes",
      count: _.get(count, "qr_count", 0),
      to:"/qrcodes",
      icons: <MdOutlineQrCode2 />,
    },
    {
      id: 3,
      name: "Links",
      count: _.get(count, "links_count", 0),
      to:"/links",
      icons: <CiLink />,
    },
    {
      id: 4,
      name: "Payments",
      count: _.get(count, "payment_count", 0),
      to:"/payment",
      icons: <CiLink />,
    },
    {
      id: 5,
      name: "Earnings",
      count: _.get(count, "amount", 0),
      to:"#",
      icons: <CiLink />,
      amount:true
    },
  ];

  return (
    <div className="flex w-screen min-h-screen !bg-white">
      <SideNavbar />
      <div className="flex flex-col">
        <TopNavbar />
        <div className="w-[85vw] px-10 py-10 pt-[100px] overflow-y-scroll">
          <h1 className="text-xl  font-bold !text-black flex items-center gap-x-2">
            <MdOutlineDashboard className="!text-black" /> Dashboard
          </h1>
          <div className="flex gap-x-4 pt-4">
            {data.map((res, index) => {
              return (
                <div className="w-[300px] shadow-xl h-[120px] justify-around px-10 bg-white rounded flex flex-col py-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-x-2">
                      {res.icons}
                      {res.name}
                    </div>
                    <Link to={res.to} className="cursor-pointer">
                      <IoIosArrowForward />
                    </Link>
                  </div>
                  <div className="text-2xl">{res.amount?`₹ ${res.count||0}`:res.count}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdmin;
