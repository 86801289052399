import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../helper/QRtypes";

const PaymentForm = () => {
  return (
    <>
      <Form.Item
        className="w-[400px]"
        label="UPI ID"
        rules={[basicRules("Enter UPI ID")]}
        name={"payment_upi_id"}
      >
        <Input rows={10} placeholder="Enter UPI ID" />
      </Form.Item>
      <Form.Item
        className="w-[400px]"
        label="Amount"
        
        rules={[basicRules("Enter Amount")]}
        name={"payment_amount"}
      >
        <Input type="number"  placeholder="Enter Amount" />
      </Form.Item>
      <Form.Item
        className="w-[400px]"
        label="Transacton Note"
        rules={[basicRules("Enter Note")]}
        name={"payment_note"}
      >
        <Input rows={10} placeholder="Enter Note" />
      </Form.Item>
    </>
  );
};

export default PaymentForm;
