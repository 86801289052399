import { Input, Text } from "@mantine/core";
import React from "react";

const FacetimeQr = ({ qrDetails, setQrDetails }) => {
  return (
    <div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper
          label={<Text pb={"sm"}>Phone number or Email Address</Text>}
        >
          <Input
            onChange={(e) => {
              setQrDetails({
                ...qrDetails,
                facetime: {
                  ...qrDetails.facetime,
                  facetime_phone_email: e.target.value,
                },
              });
            }}
            value={qrDetails.facetime.facetime_phone_email}
          />
        </Input.Wrapper>
      </div>
    </div>
  );
};

export default FacetimeQr;
