import React from "react";
import _ from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneUserMessages } from "../../Config/Quries/Vacrd/VcardQuery";
import { Table, Badge, Spin, notification } from "antd";
import DashboardImg from "../../Assets/icons/dashboard.png";

// icon
import { FaRegImage } from "react-icons/fa6";

import Vcard1 from "../../Assets/vcard/1.png";
import Vcard2 from "../../Assets/vcard/2.png";
import Vcard3 from "../../Assets/vcard/3.png";
import { updateVcardStatus } from "../../Config/Quries/User/userQuries";
import { setUserData } from "../../StateHandler/Slice/userData/userDataSlice";

const Messages = () => {
  const userData = useSelector((data) => data);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const vcards = [
    {
      id: 1,
      img: Vcard1,
    },
    {
      id: 2,
      img: Vcard2,
    },
    {
      id: 3,
      img: Vcard3,
    },
  ];

  const handleChange = async (value) => {
    if (
      Number(value.id) ===
      Number(_.get(userData, "userData.value.vcard_id", ""))
    ) {
      return true;
    }
    try {
      setLoading(true);
   
      const formData = {
        id: _.get(userData, "userData.value.id", ""),
        vcard_number: value.id,
      };

      const result = await updateVcardStatus(formData);
      dispatch(setUserData(result.data[0]));
      notification.success({ message: "Vcard updated successfully" });
      setLoading(false);
    } catch (err) {

      setLoading(false);
    }
  };

  return (
    <div className="p-5 !font-Poppins">
      <div className="flex items-center justify-between shadow gap-x-2 z-0 bg-white w-full h-[50px] rounded">
        <div className="center_div gap-x-2">
          <img src={DashboardImg} alt="" className="!w-[50px]" />
          <h1 className="text-2xl font-bold up">Templates</h1>
        </div>
      </div>
      {/* rest ogf... */}
      <div className="flex gap-x-2 pt-2">
        {vcards.map((res, index) => {
          return (
            <div
              key={index}
              className="!w-[320px] relative !h-[400px] group bg-white rounded-lg p-1"
            >
              <img
                src={res.img}
                alt=""
                className="w-full h-full object-cover rounded-lg group-hover:brightness-50 brightness-90"
              />
              <div
                onClick={() => {
                  handleChange(res);
                }}
                className={`size-[30px] ${
                  res.id ===
                  Number(_.get(userData, "userData.value.vcard_id", ""))
                    ? "!bg-primaryColor !text-white cursor-not-allowed"
                    : "bg-white "
                } font-Poppins hover:bg-primaryColor hover:text-white !w-[100px] cursor-pointer  absolute rounded-br-full  top-0 center_div`}
              >
                {res.id ===
                Number(_.get(userData, "userData.value.vcard_id", ""))
                  ? "Active"
                  : "Apply"}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Messages;
