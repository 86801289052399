import {
  Badge,
  Button,
  Card,
  Center,
  ColorInput,
  Flex,
  Input,
  Menu,
  Modal,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  CalendarEvent,
  CirclePlus,
  Copy,
  DotsVertical,
  Download,
  History,
  Pencil,
  Qrcode,
  Trash,
} from "tabler-icons-react";
import {
  AddProjects,
  DeleteProjects,
  TotalProjects,
  UpdateProjects,
} from "../../Controller/Project/ProjectController";
import "./Projects.css";

// React Query
import moment from "moment";

const Projects = () => {
  const [projopened, { open, close }] = useDisclosure(false);
  const [editprojopened, { open: editopen, close: editclose }] =
    useDisclosure(false);
  var [projDet, setProjDet] = useState({
    project_name: "",
    project_color: "",
  });
  var [editData, setEditData] = useState({
    id: "",
    project_name: "",
    project_color: "",
  });

  const [projectData, setProjectData] = useState("");
  const [reFetchprojectData, setRefetchProjectData] = useState(true);

  // UseEffects
  useEffect(() => {
    TotalProjects(localStorage.getItem("user_id"), setProjectData);
  }, [reFetchprojectData]);

  // hanlde Fetch Controlle
  const hanldeClickCreateProject = () => {
    const user_id = localStorage.getItem("user_id");
    AddProjects(user_id, projDet, close, setProjDet, setRefetchProjectData);
  };
  const hanldeClickUpdateProject = () => {
    const user_id = localStorage.getItem("user_id");
    UpdateProjects(editData, editclose, setEditData, setRefetchProjectData);
  };

  // Delete Project
  const handleDelete = (projectID) => {
    DeleteProjects(projectID, setRefetchProjectData);
  };

  // useEffect
  useEffect(() => {
    if (!reFetchprojectData) {
      setRefetchProjectData(true);
    }
  }, [reFetchprojectData]);
  return (
    <div>
      <div className="project-div">
        <div className="dashboard-div-latest-qr-table">
          <div className="dashboard-div-latest-qr-table-heading">
            <h4 className="dashboard-div-heading1">
              <Qrcode size={16} strokeWidth={1} />
              All Projects
            </h4>
            <div className="dashboard-border"></div>
            <Button onClick={open} className="dashboard_generate_qr_link">
              <CirclePlus size={20} />
              Create Project
            </Button>
          </div>
          <div className="dashboard-div-latest-qr-table-content">
            <Card>
              <table className="latest-qr-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Color</th>
                    <th>Created</th>
                    <th>Modified</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(projectData) ? (
                    projectData?.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>{value.project}</td>
                          <td>
                            <Badge p={"md"} color={value.color_code}>
                              {value.color_code}
                            </Badge>
                          </td>
                          <td>
                            {moment(value.created_at).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            {" "}
                            {moment(value.updated_at).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <div className="latest-qr-table-history">
                              <Menu>
                                <Menu.Target>
                                  <Text>
                                    <DotsVertical />
                                  </Text>
                                </Menu.Target>
                                <Menu.Dropdown>
                                  <Menu.Item
                                    p={"md"}
                                    onClick={() => {
                                      editopen();
                                      setEditData({
                                        ...editData,
                                        id: value.id,
                                        project_name: value.project,
                                        project_color: value.color_code,
                                      });
                                    }}
                                  >
                                    <Flex align={"center"} gap={"0.3rem"}>
                                      <Pencil />
                                      Edit
                                    </Flex>
                                  </Menu.Item>
                                  <Menu.Item
                                    p={"md"}
                                    onClick={() => handleDelete(value.id)}
                                  >
                                    <Flex align={"center"} gap={"0.3rem"}>
                                      <Trash />
                                      Delete
                                    </Flex>
                                  </Menu.Item>
                                </Menu.Dropdown>
                              </Menu>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        No Data Found to Display
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        centered
        opened={projopened}
        onClose={close}
        title="Create Project"
      >
        <div>
          <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>Project Name</Text>}>
            <Input
              onChange={(e) => {
                {
                  setProjDet({ ...projDet, project_name: e.target.value });
                }
              }}
              value={projDet.project_name}
            />
          </Input.Wrapper>
        </div>
        <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>Color</Text>}>
          <ColorInput
            style={{ overflow: "visible" }}
            onChange={(e) => {
              {
                setProjDet({ ...projDet, project_color: e });
              }
            }}
            withEyeDropper={false}
            value={projDet.project_color}
          />
        </Input.Wrapper>
        <Center>
          <Button
            onClick={hanldeClickCreateProject}
            style={{ backgroundColor: "#1357be" }}
            mt={"sm"}
            mb={"sm"}
          >
            Save
          </Button>
        </Center>
      </Modal>
      <Modal
        zIndex={12345}
        centered
        opened={editprojopened}
        onClose={editclose}
        title="Edit Project"
      >
        <div className="qrgenerator-div-content-form-individual">
          <Input.Wrapper label="Project Name">
            <Input
              onChange={(e) => {
                {
                  setEditData({
                    ...editData,
                    project_name: e.target.value,
                  });
                }
              }}
              value={editData.project_name}
            />
          </Input.Wrapper>
        </div>
        <div className="qrgenerator-div-content-form-individual">
          <Input.Wrapper label="Color">
            <ColorInput
              onChange={(e) => {
                {
                  setEditData({ ...editData, project_color: e });
                }
              }}
              value={editData.project_color}
            />
          </Input.Wrapper>
        </div>
        <div className="qrgenerator-div-content-form-individual-button">
          <Center>
            <Button
              onClick={hanldeClickUpdateProject}
              style={{ backgroundColor: "#1357be" }}
              mt={"sm"}
              mb={"sm"}
            >
              Save
            </Button>
          </Center>
        </div>
      </Modal>
    </div>
  );
};

export default Projects;
