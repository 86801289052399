import React from "react";
import _ from "lodash";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getOneAppoinment,
  getOneUserMessages,
} from "../../Config/Quries/Vacrd/VcardQuery";
import DashboardImg from "../../Assets/icons/dashboard.png";
import { Table } from "antd";
// icon
import { LuCalendar } from "react-icons/lu";
import moment from "moment";

const Appoinments = () => {
  const userData = useSelector((data) => data);

  const [messageData, setMessageData] = useState([]);

  const fetchData = async () => {
    try {
      const formdata = {
        id: _.get(userData, "userData.value.id", ""),
      };
      const result = await getOneAppoinment(formdata);

      setMessageData(_.get(result, "data.oppoinment", ""));
    } catch (err) {
   
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "S.No",
      dataIndex: "name",
      render: (value, allValue, index) => {
        return <div className="font-bold">{index + 1}</div>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Booking Date",
      dataIndex: "created_at",
    },
    {
      title: "Mobile Number",
      dataIndex: "contact_number",
    },
    {
      title: "Appoinment Date",
      dataIndex: "appoinment_date",
      render: (data) => {
        return <div>{moment(data).format("LL")}</div>;
      },
    },
    {
      title: "Appoinment Time",
      dataIndex: "appoinment_time",
      render: (data) => {
        return <div>{moment(data).format("h:mm A")}</div>;
      },
    },
  ];

  return (
    <div className="p-5 !font-Poppins">
      <div className="flex items-center justify-between shadow gap-x-2 bg-white w-full h-[50px] rounded">
        <div className="center_div gap-x-2">
          <img src={DashboardImg} alt="" className="!w-[50px]" />
          <h1 className="text-2xl font-bold up">Appoinments</h1>
        </div>
      </div>
      {/* rest ogf... */}
      <div className="pt-2">
        <Table
          dataSource={messageData}
          columns={columns}
          pagination={{ pageSize: 5 }}
        />
      </div>
    </div>
  );
};

export default Appoinments;
