import React from "react";

// CSS
import "./TermsOfServices.css";

import ServicesComponents from "../../Components/ServicesComponents/ServicesComponents";

const TermsOfServices = () => {
  // Scroll to TOP
  window.scrollTo(0, 0);
  // Arry of Lists
  const listArray = [
    {
      title: `Welcome to Weboney QR Code Generator!`,
      list: [
        `Please read these Terms of Service ("Terms") carefully before using Weboney QR Code Generator (the "Service") operated by Weboney`,
        `Your acceptance of and adherence to these terms will be necessary for you to have access to and use the service. Any visitors, users, and other parties who want to access or use the Service must abide by these Terms`,
        `
        By accessing or using the service, you agree to be bound by these terms. If you disagree with any of the terms, you may not use the Service.`,
      ],
    },
    {
      title: `QR Code Generation`,
      list: [
        `Our service allows you to generate Quick Response (QR) codes for various purposes, including but not limited to website links, contact information, and text. You understand and agree that the QR codes generated using our service are solely for your personal or business use, and you are responsible for the content encoded in the QR codes.`,
      ],
    },
    {
      title: `Usage Guidelines
        `,
      list: [
        `When using our QR code generator, you agree not to generate QR codes for illegal, inappropriate, or malicious activities. This includes, but is not limited to, the creation of QR codes that lead to phishing websites, malware, or content that infringes on the rights of others.`,
      ],
    },
    {
      title: `
        Accuracy of Information
        `,
      list: [
        `While we strive to provide accurate and up-to-date information, we do not guarantee the accuracy, completeness, or reliability of any information, content, or QR codes generated through our service. This service is provided at your own risk.`,
      ],
    },
    {
      title: `Account Registration`,
      list: [
        `Certain functionalities of the service may need the creation of an account. You undertake to submit accurate, current, and complete information during the registration process, as well as to maintain such information as accurate, current, and complete.`,
        `You are solely responsible for keeping your account and password secure. You agree to assume responsibility for any and all activities that take place under your account or password.`,
      ],
    },
    {
      title: `
        Termination`,
      list: [
        `We reserve the right to terminate or suspend your access to the service at our sole discretion, without prior notice or liability, for any reason whatsoever, including, without limitation, a breach of these Terms.`,
      ],
    },
    {
      title: `Governing Law`,
      list: [
        `
            These Terms are governed and construed in accordance with the laws of [your country or state], without regard to conflict of law issues.`,
      ],
    },
    {
      title: `Changes`,
      list: [
        `
            We retain the right, at our sole discretion, to amend or replace these terms at any time. If a modification is significant, we will strive to offer at least 30 days' notice before any new terms take effect. What constitutes a major change shall be evaluated solely at our discretion.`,
        `By continuing to access or use our service after such adjustments become effective, you agree to be bound by the amended terms. If you do not agree with the revised conditions, please cease using the service.`,
      ],
    },
    {
      title: `Contact Us`,
      list: [
        `Please contact us at . if you have any issues regarding these Terms.`,
      ],
    },
  ];

  return (
    <div>
      <ServicesComponents listArray={listArray} title={"Terms of Services"} />
    </div>
  );
};

export default TermsOfServices;
