import React from "react";
import ServicesComponents from "../../Components/ServicesComponents/ServicesComponents";

const PrivacyPolicy = () => {
  // Scroll to TOP
  window.scrollTo(0, 0);
  // Arrays List
  const listArray = [
    {
      title: `Information We Collect:`,
      list: [
        `Generated QR Codes: We do not store the content of the QR codes generated on our servers. The generated QR codes are only available to you during your session, and we do not retain any record of them.`,
        `Usage Information: We may collect non-personal information about your use of our website, such as the type of browser you use, the pages you visit, and your IP address. The user experience is enhanced, and analytics are performed using this data.`,
      ],
    },
    {
      title: `Cookies:`,
      list: [
        `To improve your experience on our website, we could use cookies. Cookies are small files stored on your device that help us improve the functionality of our site. You can choose to disable cookies in your browser settings, but this may affect your ability to use certain features of our website.`,
      ],
    },
    {
      title: `Data Security:

              `,
      list: [
        `We take the necessary safety measures to guarantee your data's protection Please note, however, that we are unable to guarantee complete security and that there is no method of transmission via the internet or electronic storage that is completely safe.`,
      ],
    },
    {
      title: `
      Third-Party Links:
              `,
      list: [
        `Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites. We recommend that you read the privacy rules on these websites before submitting any personal information.`,
      ],
    },
    {
      title: `Children's Privacy:`,
      list: [
        `Individuals under the age of 13 are not eligible for our services. We do not intentionally gather personal data from minors.  If you are a parent or guardian and become aware that your child has provided us with personal information, please contact us, and we will take steps to delete such information.`,
      ],
    },
    {
      title: `
      Changes to this Privacy Policy:`,
      list: [
        `We have the right to amend or modify our privacy statement at any time. Any modifications will be effective the moment they are posted on our website. It is your duty to frequently examine our Privacy Policy in order to be informed about the ways in which we are safeguarding your personal data.`,
      ],
    },
    {
      title: `Contact Information:`,
      list: [
        `
        If you have any questions or issues about our privacy policies, please contact us at weboney.com`,
      ],
    },
    {
      title: `Changes`,
      list: [
        `
                  We retain the right, at our sole discretion, to amend or replace these terms at any time. If a modification is significant, we will strive to offer at least 30 days' notice before any new terms take effect. What constitutes a major change shall be evaluated solely at our discretion.`,
        `By continuing to access or use our service after such adjustments become effective, you agree to be bound by the amended terms. If you do not agree with the revised conditions, please cease using the service.`,
      ],
    },
    {
      title: `Contact Us`,
      list: [
        `Please contact us at . if you have any issues regarding these Terms.`,
      ],
    },
  ];

  // Description
  const desc = `
This Privacy Policy outlines how we collect, use, and protect your personal information when you use our QR Code Generator website. You accept the conditions stated in this policy by using our services.`;

  return (
    <div>
      <ServicesComponents
        title={"Privacy Policy"}
        listArray={listArray}
        desc={desc}
      />
    </div>
  );
};

export default PrivacyPolicy;
