import { Input, Select, Text } from "@mantine/core";
import React from "react";

const WifiQr = ({ qrDetails, setQrDetails, wifi_encryp }) => {
  return (
    <div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper
          pb={"sm"}
          label={<Text pb={"sm"}>Wifi Name (SSID)</Text>}
        >
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  wifi: {
                    ...qrDetails.wifi,
                    wifi_name: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.wifi.wifi_name}
          />
        </Input.Wrapper>
      </div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>Encryption</Text>}>
          <Select
            radius={"md"}
            placeholder="Select Encryption"
            data={wifi_encryp}
            searchable
            value={qrDetails.wifi.wifi_encryption}
            onChange={(e) => {
              setQrDetails({
                ...qrDetails,
                wifi: {
                  ...qrDetails.wifi,
                  wifi_encryption: e,
                },
              });
            }}
          />
        </Input.Wrapper>
      </div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"md"} label={<Text pb={"sm"}>Password</Text>}>
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  wifi: {
                    ...qrDetails.wifi,
                    wifi_password: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.wifi.wifi_password}
          />
        </Input.Wrapper>
      </div>
    </div>
  );
};

export default WifiQr;
