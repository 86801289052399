import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../helper/QRtypes";

const LinkForm = () => {
  return (
    <Form.Item
      className="w-[400px]"
      label="Enter Link"
      rules={[basicRules("Enter Your Link")]}
      name={"link_value"}
    >
      <Input rows={10} placeholder="Enter Your Link" />
    </Form.Item>
  );
};

export default LinkForm;
