import React from "react";
import { RxAvatar } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserData } from "../../StateHandler/Slice/userData/userDataSlice";

const TopNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="w-[86vw] !bg-[#fff] h-[70px] shadow flex items-center  !text-black px-10 gap-x-10  justify-end fixed !z-40 ">
      <div className="flex gap-x-2 items-center cursor-pointer">
        <RxAvatar className="!text-2xl" />
        <h1>Super Admin</h1>
      </div>
      <div
        className="cursor-pointer border-2 hover:border-[#1357be] text-white hover:text-black hover:bg-white bg-[#1357be]  transition-all duration-700 ease-linear px-3 py-1 rounded-md "
        onClick={() => {
          localStorage.clear();
          dispatch(setUserData([]));
          navigate("/");
        }}
      >
        Logout
      </div>
    </div>
  );
};

export default TopNavbar;
