import React from "react";
import { Input, Text, Textarea } from "@mantine/core";

const SmsQr = ({ qrDetails, setQrDetails }) => {
  return (
    <div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"md"} label={<Text pb={"sm"}>Phone Number</Text>}>
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  sms: {
                    ...qrDetails.sms,
                    phone_number: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.sms.phone_number}
          />
        </Input.Wrapper>
      </div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper
          pb={"md"}
          label={<Text pb={"sm"}>Prefilled Message</Text>}
        >
          <Textarea
            radius={"md"}
            autosize
            minRows={4}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  sms: {
                    ...qrDetails.sms,

                    prefilled_message: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.sms.prefilled_message}
          />
        </Input.Wrapper>
      </div>
    </div>
  );
};

export default SmsQr;
