import {
  Accordion,
  Button,
  FileInput,
  Flex,
  Grid,
  Input,
  Select,
  Text,
  Textarea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { MdDeleteOutline } from "react-icons/md";
import { CiImageOn } from "react-icons/ci";

import {
  AlignBoxLeftMiddle,
  BrandFacebook,
  BrandFirebase,
  BrandInstagram,
  BrandLinkedin,
  BrandTwitter,
  BrandYoutube,
  CalendarTime,
  CirclePlus,
  Phone,
  Photo,
  Social,
  User,
  X,
} from "tabler-icons-react";
import { MdDelete } from "react-icons/md";
import Fileinput from "../../Cropper/Fileinput";
import ImageCropper from "../../../Components/Cropper/ImageCropper";
import { Modal } from "antd";

// CSS
import "./VcardQr.css";
import Services from "./Services";
import Testimonials from "./Testimonials";
import Timing from "./Timing";

const VcardQr = ({
  qrDetails,
  setQrDetails,
  birthdayDate,
  handleBirthdayDateChange,
  handleVcardPhoneCreate,
  handleVcardPhoneDelete,
  handleVcardSocialCreate,
  handleVcardSocialDelete,
  load,
  loadImages,
}) => {
  const [socialInput, setSocialInput] = useState("facebook");
  const [dynamicopened, setDynamicClose] = useState(false);
  const [image, setImage] = useState("");
  const [currentUpload, setCurrentUpload] = useState("profile");
  const [currentprofile, setCurrentprofile] = useState("");
  const [currentbanner, setCurrentbanner] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  const [dummy, setDummy] = useState("");

  // services

  let initialValue = [
    {
      id: 1,
      name: "",
      description: "",
      img: "",
    },
  ];

  let testimonialInitialValue = [
    {
      id: 1,
      client: "",
      review: "",
      img: "",
    },
  ];

  let services = useRef(initialValue);
  let testimonial = useRef(testimonialInitialValue);

  const [cardStyle, setCardStyle] = useState({
    status: false,
    content: 1,
  });

  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
  };

  const handleUploadImages = (data) => {
    setCurrentUpload(data);
    setDynamicClose(true);
  };

  // Callback function when cropping is done
  const onCropDone = (imgCroppedArea) => {
    try {
      // Create a canvas element to crop the image
      const canvasEle = document.createElement("canvas");
      canvasEle.width = imgCroppedArea.width;
      canvasEle.height = imgCroppedArea.height;

      const context = canvasEle.getContext("2d");

      // Load the selected image
      let imageObj1 = new Image();
      imageObj1.src = image;
      imageObj1.onload = function () {
        // Draw the cropped portion of the image onto the canvas
        context.drawImage(
          imageObj1,
          imgCroppedArea.x,
          imgCroppedArea.y,
          imgCroppedArea.width,
          imgCroppedArea.height,
          0,
          0,
          imgCroppedArea.width,
          imgCroppedArea.height
        );

        // Convert the canvas content to a data URL (JPEG format)
        const dataURL = canvasEle.toDataURL("image/jpeg");
        if (currentUpload === "profile") {
          setCurrentprofile(dataURL);
        } else {
          setCurrentbanner(dataURL);
        }
        setImgAfterCrop(dataURL);

        setCurrentPage("img-cropped");
      };
    } catch (err) {
    
    }
  };

  // Callback function when cropping is canceled
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setDynamicClose(false);
    setCurrentUpload("");
    setImgAfterCrop("");
  };

  const handleRemove = (value) => {
    if (value === "profile") {
      setCurrentprofile("");
      setQrDetails({
        ...qrDetails,
        vcard: {
          ...qrDetails.vcard,
          image: "",
        },
      });
    } else {
      setCurrentbanner("");
      setQrDetails({
        ...qrDetails,
        vcard: {
          ...qrDetails.vcard,
          vcard_banner_image: "",
        },
      });
    }
  };

  const handleRemoveService = (id) => {
    let edit = services.current;
    const result = edit.filter((res) => {
      return res.id != id;
    });
    services.current = result;
    setDummy(!dummy);
  };

  const handleRemoveTestimonials = (id) => {
    let edit = testimonial.current;
    const result = edit.filter((res) => {
      return res.id != id;
    });
    testimonial.current = result;
    setDummy(!dummy);
  };

  const handleAddServices = () => {
    let add = [
      ...services.current,
      { id: services.current.length + 1, name: "", img: "" },
    ];
    services.current = add;
    setDummy(!dummy);
  };

  const handleAddTestimonials = () => {
    let add = [
      ...testimonial.current,
      { id: testimonial.current.length + 1, name: "", img: "" },
    ];
    testimonial.current = add;
    setDummy(!dummy);
  };

  const handleServiceChange = (value, id, fields) => {
    let update = services.current;
    update.map((res) => {
      if (fields === "name") {
        return res.id === id ? (res.name = value) : res.name;
      } else if (fields === "description") {
        return res.id === id ? (res.description = value) : res.description;
      } else if (fields === "img") {
        return res.id === id ? (res.img = value) : res.img;
      } else if (fields === "remove") {
        return res.id === id ? (res.img = "") : res.img;
      }
    });
    services.current = update;

    setDummy(!dummy);
    setQrDetails({
      ...qrDetails,
      vcard: {
        ...qrDetails.vcard,
        servicesDetails: JSON.stringify(update),
      },
    });
  };

  const handleTestimonialChange = (value, id, fields) => {
    let update = testimonial.current;

    update.map((res) => {
      if (fields === "client") {
        return res.id === id ? (res.client = value) : res.client;
      } else if (fields === "review") {
        return res.id === id ? (res.review = value) : res.review;
      } else if (fields === "img") {
        return res.id === id ? (res.img = value) : res.img;
      } else if (fields === "remove") {
        return res.id === id ? (res.img = "") : res.img;
      }
    });

    testimonial.current = update;
    setDummy(!dummy);
    setQrDetails({
      ...qrDetails,
      vcard: {
        ...qrDetails.vcard,
        testimonialDetails: JSON.stringify(update),
      },
    });
  };

  const handleRemoveImage = (id) => {};

  return (
    <div>
      {/* Services */}
      <Accordion
        pb={"md"}
        variant="separated"
        radius="md"
        defaultValue="vcard_basic"
      >
        <Accordion.Item value="vcard_basic">
          <Accordion.Control icon={<BrandFirebase />}>
            Basic Info
          </Accordion.Control>
          <Accordion.Panel>
          <Grid columns={2}>
              <Grid.Col span={1}>
                <Input.Wrapper
                  w={"100%"}
                  pb={"sm"}
                  label={<Text pb={"sm"}>First Name</Text>}
                >
                  <Input
                    radius={"md"}
                    onChange={(e) => {
                      {
                        setQrDetails({
                          ...qrDetails,
                          vcard: {
                            ...qrDetails.vcard,
                            first_name: e.target.value,
                          },
                        });
                      }
                    }}
                    value={qrDetails.vcard.first_name}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={1}>
                <Input.Wrapper
                  w={"100%"}
                  pb={"sm"}
                  label={<Text pb={"sm"}>Last Name</Text>}
                >
                  <Input
                    radius={"md"}
                    onChange={(e) => {
                      {
                        setQrDetails({
                          ...qrDetails,
                          vcard: {
                            ...qrDetails.vcard,
                            last_name: e.target.value,
                          },
                        });
                      }
                    }}
                    value={qrDetails.vcard.last_name}
                  />
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
            <Grid columns={2}>
              <Grid.Col span={1}>
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>Phone Number</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              phone_number: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.phone_number}
                    />
                  </Input.Wrapper>
                </div>
              </Grid.Col>
              <Grid.Col span={1}>
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>Email Address</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              email_address: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.email_address}
                    />
                  </Input.Wrapper>
                </div>
              </Grid.Col>
            </Grid>
            <Grid columns={2}>
              <Grid.Col span={1}>
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>Company</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              company: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.company}
                    />
                  </Input.Wrapper>
                </div>
              </Grid.Col>
              <Grid.Col span={1}>
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>Job Title</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              job_title: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.job_title}
                    />
                  </Input.Wrapper>
                </div>
              </Grid.Col>
            </Grid>
           
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="vcard_address">
          <Accordion.Control icon={<AlignBoxLeftMiddle />}>
            Address
          </Accordion.Control>
          <Accordion.Panel>
            <Grid columns={2}>
              <Grid.Col span={1}>
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>Street Address</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              street_address: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.street_address}
                    />
                  </Input.Wrapper>
                </div>
              </Grid.Col>
              <Grid.Col span={1}>
                {" "}
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>City</Text>}>
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              city: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.city}
                    />
                  </Input.Wrapper>
                </div>
              </Grid.Col>
            </Grid>
            <Grid columns={2}>
              <Grid.Col span={1}>
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>Country</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              country: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.country}
                    />
                  </Input.Wrapper>
                </div>
              </Grid.Col>

              <Grid.Col span={1}>
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>Zip Code</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              zip: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.zip}
                    />
                  </Input.Wrapper>
                </div>
              </Grid.Col>
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="vcard_images">
          <Accordion.Control icon={<Photo />}>Images</Accordion.Control>
          <Accordion.Panel>
            <Grid columns={2}>
              <Grid.Col span={1}>
                <Input.Wrapper
                  pb={"sm"}
                  label={
                    <Flex style={{ alignItems: "center", gap: "0.5rem" }}>
                      <Text pb={"sm"}>Profile Image</Text>{" "}
                      {currentprofile != "" && (
                        <span>
                          <MdDelete
                            style={{ color: "#f2334d", cursor: "pointer" }}
                            onClick={() => {
                              handleRemove("profile");
                            }}
                          />
                        </span>
                      )}{" "}
                    </Flex>
                  }
                >
                  {currentprofile === "" ? (
                    <Button
                      onClick={() => {
                        handleUploadImages("profile");
                      }}
                      className="model-btn"
                      style={{
                        padding: "4px 8px",
                        height: "30px",
                        display: "flex",
                      }}
                    >
                      + Click to Upload
                    </Button>
                  ) : (
                    <div className="model-image-container">
                      <img className="model-btn" src={currentprofile} />
                    </div>
                  )}
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={1}>
                <Input.Wrapper
                  pb={"sm"}
                  label={
                    <Flex style={{ alignItems: "center", gap: "0.5rem" }}>
                      <Text pb={"sm"}>banner Image</Text>{" "}
                      {currentbanner != "" && (
                        <span>
                          <MdDelete
                            style={{ color: "#f2334d", cursor: "pointer" }}
                            onClick={() => {
                              handleRemove("banner");
                            }}
                          />
                        </span>
                      )}{" "}
                    </Flex>
                  }
                >
                  {currentbanner === "" ? (
                    <Button
                      onClick={() => {
                        handleUploadImages("banner");
                      }}
                      className="model-btn !border !border-dashed"
                      style={{
                        padding: "4px 8px",
                        height: "30px",
                        display: "flex",
                      }}
                    >
                      + Click to Upload
                    </Button>
                  ) : (
                    <div className="model-image-container">
                      <img className="model-btn" src={currentbanner} />
                    </div>
                  )}
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="vcard_about_me">
          <Accordion.Control icon={<User />}>About Me</Accordion.Control>
          <Accordion.Panel>
            <Grid columns={1}>
              <Grid.Col span={1}>
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper pb={"md"}>
                    <Textarea
                      radius={"md"}
                      autosize
                      minRows={4}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              description: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.description}
                    />
                  </Input.Wrapper>
                </div>
              </Grid.Col>
            </Grid>
          </Accordion.Panel>
        </Accordion.Item>

        <Services
          handleAddServices={handleAddServices}
          services={services}
          handleRemoveService={handleRemoveService}
          handleServiceChange={handleServiceChange}
          handleRemoveImage={handleRemoveImage}
          load={load}
          loadImages={loadImages}
        />
        <Testimonials
          handleAddTestimonials={handleAddTestimonials}
          testimonial={testimonial}
          handleRemoveTestimonials={handleRemoveTestimonials}
          handleTestimonialChange={handleTestimonialChange}
          handleRemoveImage={handleRemoveImage}
          load={load}
          loadImages={loadImages}
        />
        <Accordion.Item value="vcard_social_links">
          <Accordion.Control icon={<Social />}>Social Links</Accordion.Control>
          <Accordion.Panel>
            <div className="social-links-div">
              <Button
                className={
                  socialInput === "facebook"
                    ? `social-link-active-btn social-link-btn`
                    : `social-link-btn`
                }
                onClick={() => {
                  setSocialInput("facebook");
                }}
              >
                <BrandFacebook strokeWidth={1.1} size={16} />
                Facebook
              </Button>
              <Button
                className={
                  socialInput === "instagram"
                    ? `social-link-active-btn social-link-btn`
                    : `social-link-btn`
                }
                onClick={() => {
                  setSocialInput("instagram");
                }}
              >
                <BrandInstagram strokeWidth={1.1} size={16} />
                Instagram
              </Button>
              <Button
                className={
                  socialInput === "twitter"
                    ? `social-link-active-btn social-link-btn`
                    : `social-link-btn`
                }
                onClick={() => {
                  setSocialInput("twitter");
                }}
              >
                <BrandTwitter strokeWidth={1.1} size={16} />
                Twitter
              </Button>
              <Button
                className={
                  socialInput === "youtube"
                    ? `social-link-active-btn social-link-btn`
                    : `social-link-btn`
                }
                onClick={() => {
                  setSocialInput("youtube");
                }}
              >
                <BrandYoutube strokeWidth={1.1} size={16} />
                YouTube
              </Button>
              <Button
                className={
                  socialInput === "linkedin"
                    ? `social-link-active-btn social-link-btn`
                    : `social-link-btn`
                }
                onClick={() => {
                  setSocialInput("linkedin");
                }}
              >
                <BrandLinkedin strokeWidth={1.1} size={16} />
                LinkedIn
              </Button>
            </div>
            {socialInput === "facebook" ? (
              <div className="vcard-facebook-content">
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>Facebook</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              facebook: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.facebook}
                    />
                  </Input.Wrapper>
                </div>
              </div>
            ) : socialInput === "instagram" ? (
              <div className="vcard-instagram-content">
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>Instagram</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              instagram: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.instagram}
                    />
                  </Input.Wrapper>
                </div>
              </div>
            ) : socialInput === "twitter" ? (
              <div className="vcard-twitter-content">
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>Twitter</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              twitter: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.twitter}
                    />
                  </Input.Wrapper>
                </div>
              </div>
            ) : socialInput === "youtube" ? (
              <div className="vcard-youtube-content">
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>YouTube</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              youtube: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.youtube}
                    />
                  </Input.Wrapper>
                </div>
              </div>
            ) : socialInput === "linkedin" ? (
              <div className="vcard-linkedin-content">
                <div className="qrgenerator-div-content-form-individual">
                  <Input.Wrapper
                    pb={"sm"}
                    label={<Text pb={"sm"}>LinkedIn</Text>}
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        {
                          setQrDetails({
                            ...qrDetails,
                            vcard: {
                              ...qrDetails.vcard,
                              linkedin: e.target.value,
                            },
                          });
                        }
                      }}
                      value={qrDetails.vcard.linkedin}
                    />
                  </Input.Wrapper>
                </div>
              </div>
            ) : (
              ""
            )}
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="vcard_business_hour">
          <Timing qrDetails={qrDetails} setQrDetails={setQrDetails} />
        </Accordion.Item>
      </Accordion>

      <Modal
        size="xl"
        zIndex={12345}
        centered
        open={dynamicopened}
        onCancel={onCropCancel}
        destroyOnClose
        footer={false}
        title="Select Image"
      >
        <div className="crop-modal-content">
          {currentPage === "choose-img" ? (
            <div className="w-full h-full flex items-center justify-center ">
              <Fileinput onImageSelected={onImageSelected} />
            </div>
          ) : currentPage === "crop-img" ? (
            <ImageCropper
              image={image}
              onCropDone={onCropDone}
              onCropCancel={onCropCancel}
            />
          ) : (
            <div>
              <div>
                <img
                  src={imgAfterCrop}
                  className="cropped-img"
                  width={500}
                  height={500}
                />
              </div>

              <div className="w-full h-full flex items-center justify-center gap-x-10 pt-10">
                <button
                  onClick={() => {
                    setCurrentPage("choose-img");
                    setImage("");
                  }}
                  className="btn btn border border-primaryColor text-black py-1 px-3 rounded"
                >
                  New Image
                </button>

                <button
                  onClick={() => {
                    currentUpload === "profile"
                      ? setQrDetails({
                          ...qrDetails,
                          vcard: {
                            ...qrDetails.vcard,
                            image: imgAfterCrop,
                          },
                        })
                      : setQrDetails({
                          ...qrDetails,
                          vcard: {
                            ...qrDetails.vcard,
                            vcard_banner_image: imgAfterCrop,
                          },
                        });
                    onCropCancel();
                  }}
                  className="btn  bg-primaryColor text-white py-1 px-3 rounded"
                >
                  Set Image
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        open={cardStyle.status}
        footer={false}
        closable={false}
        destroyOnClose
        onCancel={() => {
          setCardStyle((data) => ({ ...data, ["status"]: true }));
        }}
        className="!min-h-[400px] !w-fit"
        centered
      >
        <div className=" flex justify-around items-center gap-x-10">
          <div className="flex flex-col items-center gap-y-2">
            <img src="/logo/1.png" alt="" className="!w-[200px] !h-[400px]" />
            <button
              className={`!border px-4 rounded-2xl ${
                cardStyle.content === 1 ? "bg-primaryColor text-white" : ""
              } py-1 !border-primaryColor`}
              onClick={() => {
                setCardStyle((data) => ({
                  ...data,
                  ["status"]: false,
                  ["content"]: 1,
                }));
                setQrDetails({
                  ...qrDetails,
                  vcard: {
                    ...qrDetails.vcard,
                    variant: 1,
                  },
                });
              }}
            >
              {cardStyle.content === 1 ? "Selected" : "Select"}
            </button>
          </div>
          <div className="flex flex-col items-center gap-y-2">
            <img src="/logo/2.png" alt="" className="!w-[200px] !h-[400px]" />
            <button
              onClick={() => {
                setCardStyle((data) => ({
                  ...data,
                  ["status"]: false,
                  ["content"]: 2,
                }));
                setQrDetails({
                  ...qrDetails,
                  vcard: {
                    ...qrDetails.vcard,
                    variant: 2,
                  },
                });
              }}
              className={`!border px-4 rounded-2xl ${
                cardStyle.content === 2 ? "bg-primaryColor text-white" : ""
              } py-1 !border-primaryColor`}
            >
              {cardStyle.content === 2 ? "Selected" : "Select"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VcardQr;
