import React from "react";
import Logo from "../../Assets/BrandLogo.png";
import { IoHome } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
// icons
import { CiLink } from "react-icons/ci";
import { MdOutlineQrCode2 } from "react-icons/md";
import { MdOutlineCurrencyRupee } from "react-icons/md";


const SideNavbar = () => {
  const location = useLocation();

  const menu = [
    {
      id: 1,
      name: "Dashboard",
      icon: <IoHome />,
      link: "/",
    },
    {
      id: 2,
      name: "Users",
      icon: <FaUsers />,
      link: "/user",
    },
    {
      id: 3,
      name: "QR Codes",
      icon: <MdOutlineQrCode2 />,
      link: "/qrcodes",
    },
    {
      id: 4,
      name: "Links",
      icon: <CiLink />,
      link: "/links",
    },
    {
      id: 4,
      name: "Payment",
      icon: <MdOutlineCurrencyRupee />,
      link: "/payment",
    },
  ];

  return (
    <div className="w-[15vw] flex flex-col gap-y-14 shadow items-center py-10 text-black  !bg-[#fff] !bg-gradient-to-b">
      <img src={Logo} alt="" className="size-[50px] " />
      <div className="flex flex-col gap-y-2 self-start w-full">
        {menu.map((res, index) => {
          return (
            <Link
              to={res.link}
              className={`flex ${
                _.get(location, "pathname", "") === res.link
                  ? "!text-white bg-color  shadow-inner"
                  : "text-black"
              } items-center gap-x-6 cursor-pointer px-6 py-3 rounded-lg mx-1 transition-all hover:bg-color duration-700 ease-linear hover:text-white`}
            >
              {res.icon} {res.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SideNavbar;
