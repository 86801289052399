import React from "react";
import { Form, Input } from "antd";
import { basicRules } from "../../../helper/QRtypes";



const TextForm = () => {
  return (
    <Form.Item
      className="w-[400px] text-area"
      label="Enter Your Text"
      rules={[basicRules("Enter Your Text")]}
      name={"text_value"}
    >
      <Input.TextArea
        rows={10}
        placeholder="Enter Text Here"
        className="!h-[100px]"
      />
    </Form.Item>
  );
};

export default TextForm;
