import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// CSS
import "./Signup.css";

// Images
import Logo from "../../../Assets/BrandLogo.png";

// Mantaine Core
import { Center, Flex, Input, PasswordInput, Text, Title } from "@mantine/core";

// Controller
import {
  LoginUserControl,
  signUpFunction,
  verifyotpAuth,
} from "../../../Controller/LoginUser/LoginUser";

// React Redux
import { useDispatch, useSelector } from "react-redux";

const Signup = () => {
  // Scroll to TOP
  window.scrollTo(0, 0);
  // REact Redux
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.value);

  // navigate
  const navigate = useNavigate();

  // OTPStatus
  const [otpStatus, setOtpStatus] = useState(0);
  const [userId, setUserId] = useState("");
  // States
  const [loginDetails, setLoginDetails] = useState({
    firstName: "",
    email: "",
    password: "",
    confirmpassword: "",
    otp: "",
  });

  // Data Validation
  const [loginDetailsValidation, setLoginDetailsValidation] = useState({
    firstName: 0,
    email: 0,
    password: 0,
    confirmpassword: 0,
    otp: 0,
  });

  // Handle Function
  const handleRegister = () => {
    LoginUserControl(
      loginDetails,
      setLoginDetails,
      loginDetailsValidation,
      setLoginDetailsValidation,
      setOtpStatus,
      setUserId
    );
  };

  // Verify OTP
  const handleVerifyOTPClick = () => {
    verifyotpAuth(
      loginDetails,
      setLoginDetails,
      loginDetailsValidation,
      setLoginDetailsValidation,
      setOtpStatus,
      userId,
      setUserId
    );
  };
  const handleSignup = () => {
    signUpFunction(
      loginDetails,
      setLoginDetails,
      loginDetailsValidation,
      setLoginDetailsValidation,
      setOtpStatus,
      userId,
      setUserId,
      dispatch,
      navigate
    );
  };

  // UseEffects
  useEffect(() => {
    if (loginDetails.firstName) {
      setLoginDetailsValidation({
        ...loginDetailsValidation,
        firstName: 0,
      });
    }
    if (loginDetails.email) {
      setLoginDetailsValidation({
        ...loginDetailsValidation,
        email: 0,
      });
    }
    if (loginDetails.password) {
      setLoginDetailsValidation({
        ...loginDetailsValidation,
        password: 0,
      });
    }
  }, [loginDetails]);

  useEffect(() => {
    if (localStorage.getItem("user_email") && userData) {
      navigate("/");
    }
  }, [userData]);

  return (
    <div className="register-container">
      <div className="login-div">
        <div className="login-div-card">
          <div className="login-div-card-logo-div">
            <Link to={"/"}>
              <img src={Logo} alt="website logo" />
            </Link>
          </div>
          <Center>
            <Title order={2} style={{ color: " #1357be" }}>
              Register
            </Title>
          </Center>
          {otpStatus === 0 ? (
            <div className="login-div-card-form">
              <div className="login-div-card-form-individual">
                <Flex gap={"1rem"}>
                  <Input.Wrapper
                    error={`${
                      loginDetailsValidation.firstName === 1
                        ? "Please Enter Name"
                        : ""
                    }`}
                    w={"100%"}
                    label="Name"
                  >
                    <Input
                      placeholder="Name"
                      onChange={(e) => {
                        setLoginDetails({
                          ...loginDetails,
                          firstName: e.target.value,
                        });
                      }}
                      value={loginDetails.firstName}
                    />
                  </Input.Wrapper>
                </Flex>
              </div>
              <div className="login-div-card-form-individual">
                <Flex gap={"1rem"}>
                  <Input.Wrapper
                    error={`${
                      loginDetailsValidation.email === 1
                        ? "Please Enter Email"
                        : loginDetailsValidation.email === 2
                        ? "Please Enter Valid Email"
                        : loginDetailsValidation.email === 3
                        ? "Email already Exists"
                        : ""
                    }`}
                    w={"100%"}
                    label="Email ID"
                  >
                    <Input
                      placeholder="Email Id"
                      onChange={(e) => {
                        setLoginDetails({
                          ...loginDetails,
                          email: e.target.value,
                        });
                      }}
                      value={loginDetails.email}
                    />
                  </Input.Wrapper>
                </Flex>
              </div>
              <div className="login-div-card-form-individual-btn">
                <button onClick={handleRegister}>Send OTP</button>
              </div>
              <div className="login-div-card-form-individual-btn">
                <Center>
                  <Flex gap={"0.5rem"}>
                    Already have an account ?
                    <Text
                      component={Link}
                      to={"/login"}
                      style={{ color: "blue" }}
                    >
                      Login
                    </Text>
                  </Flex>
                </Center>
              </div>
            </div>
          ) : otpStatus === 1 ? (
            <div className="login-div-card-form">
              <div className="login-div-card-form-individual">
                <Flex gap={"1rem"}>
                  <Input.Wrapper
                    error={`${
                      loginDetailsValidation.firstName === 1
                        ? "Please Enter Name"
                        : ""
                    }`}
                    w={"100%"}
                    label="Email"
                  >
                    <Input
                      placeholder="Email"
                      disabled
                      onChange={(e) => {
                        setLoginDetails({
                          ...loginDetails,
                          firstName: e.target.value,
                        });
                      }}
                      value={loginDetails.email}
                    />
                  </Input.Wrapper>
                </Flex>
              </div>
              <div className="login-div-card-form-individual">
                <Flex gap={"1rem"}>
                  <Input.Wrapper
                    error={`${
                      loginDetailsValidation.otp === 1
                        ? "Please Enter OTP"
                        : loginDetailsValidation.otp === 2
                        ? "Invalid OTP"
                        : ""
                    }`}
                    w={"100%"}
                    label="OTP"
                  >
                    <Input
                      placeholder="Enter OTP"
                      onChange={(e) => {
                        setLoginDetails({
                          ...loginDetails,
                          otp: e.target.value,
                        });
                      }}
                      value={loginDetails.otp}
                    />
                  </Input.Wrapper>
                </Flex>
              </div>
              <div className="login-div-card-form-individual-btn">
                <button onClick={handleVerifyOTPClick}>Verify OTP</button>
              </div>
              <div className="login-div-card-form-individual-btn">
                <Center>
                  <Flex gap={"0.5rem"}>
                    Already have an account ?
                    <Text
                      component={Link}
                      to={"/login"}
                      style={{ color: "blue" }}
                    >
                      Login
                    </Text>
                  </Flex>
                </Center>
              </div>
            </div>
          ) : otpStatus === 2 ? (
            <div className="login-div-card-form">
              <div className="login-div-card-form-individual">
                <Flex gap={"1rem"}>
                  <Input.Wrapper
                    error={`${
                      loginDetailsValidation.password === 1
                        ? "Please Enter Password"
                        : ""
                    }`}
                    w={"100%"}
                    label="Password"
                  >
                    <PasswordInput
                      placeholder="Password"
                      onChange={(e) => {
                        setLoginDetails({
                          ...loginDetails,
                          password: e.target.value,
                        });
                      }}
                      value={loginDetails.password}
                    />
                  </Input.Wrapper>
                </Flex>
              </div>
              <div className="login-div-card-form-individual">
                <Flex gap={"1rem"}>
                  <Input.Wrapper
                    error={`${
                      loginDetailsValidation.confirmpassword === 1
                        ? "Please Enter Confirm Password"
                        : loginDetailsValidation.confirmpassword === 2
                        ? "Please Enter Valid Email"
                        : loginDetailsValidation.email === 3
                        ? "Email already Exists"
                        : ""
                    }`}
                    w={"100%"}
                    label="Confirm Password"
                  >
                    <PasswordInput
                      placeholder="Confirm Password"
                      onChange={(e) => {
                        setLoginDetails({
                          ...loginDetails,
                          confirmpassword: e.target.value,
                        });
                      }}
                      value={loginDetails.confirmpassword}
                    />
                  </Input.Wrapper>
                </Flex>
              </div>
              <div className="login-div-card-form-individual-btn">
                <button onClick={handleSignup}>Signup</button>
              </div>
              <div className="login-div-card-form-individual-btn">
                <Center>
                  <Flex gap={"0.5rem"}>
                    Already have an account ?
                    <Text
                      component={Link}
                      to={"/login"}
                      style={{ color: "blue" }}
                    >
                      Login
                    </Text>
                  </Flex>
                </Center>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Signup;
