import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

// CSS
import "./QrCodeGenerator.css";

// Images
import BrandLogo from "../../Assets/BrandLogo.png";

// Mantine Media Query
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";

// Mantine Core
import {
  Accordion,
  Button,
  Card,
  Center,
  Checkbox,
  ColorInput,
  FileInput,
  Flex,
  Grid,
  Group,
  Input,
  NumberInput,
  Radio,
  Select,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";

// QR Styling
import QRCodeStyling from "qr-code-styling";
import {
  AdjustmentsHorizontal,
  Abc,
  Number,
  OneTwoThree,
  Qrcode,
  InfoCircle,
  X,
} from "tabler-icons-react";
import TextQr from "../Qr/TextQr/TextQr";
import URLQr from "../Qr/URLQr/URLQr";
import PhoneQr from "../Qr/PhoneQr/PhoneQr";
import SmsQr from "../Qr/SmsQr/SmsQr";
import EmailQr from "../Qr/EmailQr/EmailQr";
import WhatsappQr from "../Qr/WhatsappQr/WhatsappQr";
import FacetimeQr from "../Qr/FacetimeQr/FacetimeQr";
import LocationQr from "../Qr/LocationQr/LocationQr";
import WifiQr from "../Qr/WifiQr/WifiQr";
import EventQr from "../Qr/EventQr/EventQr";
import VcardQr from "../Qr/VcardQr/VcardQr";
import PaymentQr from "../Qr/PaymentQr/PaymentQr";
import { timezones } from "../timezone";

// React Redux
import { useSelector, useDispatch } from "react-redux";
import {
  QrcodeAddControl,
  QrcodeAddControl1,
} from "../../Controller/QrCode/QrCode/QrCode";
import moment from "moment";
import { errorNotification } from "../Notification/notify";
import {
  getUserDataById,
  updateUserDownloadCount,
} from "../../Config/Quries/User/userQuries";
import { setUserData } from "../../StateHandler/Slice/userData/userDataSlice";
import { notification } from "antd";

const QrCodeGenerator = ({ userData, datatype }) => {
  const [load, loadImages] = useState(false);
  // Media Query
  const media570px = useMediaQuery("(max-width:570px)");

  // VACRD State
  const [vCardId, setVcardId] = useState();

  // Event Date
  const [startEvent, setStartEvent] = useState("");
  const [endEvent, setEndEvent] = useState("");
  const [dummy, setDummy] = useState(false);

  // Navigate
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // prime User
  const primeUser = useSelector((state) => state.primeUser.value);
  const [qrDetails, setQrDetails] = useState({
    text: {
      text_content: "",
    },
    url: {
      url: "",
      dynamicurl: false,
    },
    phone: {
      phone_number: "",
    },
    sms: {
      phone_number: "",
      prefilled_message: "",
    },
    email: {
      email_address: "",
      prefilled_subject: "",
      prefilled_message: "",
    },
    whatsapp: {
      phone_number: "",
      prefilled_message: "",
    },
    facetime: {
      facetime_phone_email: "",
    },
    location: {
      location_address: "",
    },
    wifi: {
      wifi_name: "",
      wifi_encryption: "",
      wifi_password: "",
    },
    event: {
      event_name: "",
      geo_location: "",
      event_url: "",
      notes: "",
      starts_on: null,
      ends_on: null,
      timezone: "",
    },
    vcard: {
      first_name: "",
      last_name: "",
      email_address: "",
      website_url: "",
      company: "",
      job_title: "",
      birthday: null,
      street_address: "",
      city: "",
      zip: "",
      region: "",
      country: "",
      notes: "",
      phone_number: "",
      vcard_banner_image: "",
      variant: 1,
      description: "",
      servicesDetails: "",
      testimonialDetails: "",
      businessHoursDetails: "",
      vcard_phone_numbers: [],
      vcard_social: [],
      image: "",
      logoimage: "",
      facebook: "",
      instagram: "",
      youtube: "",
      twitter: "",
      linkedin: "",
    },
    payment: {
      upi_id: "",
      amount: "",
      transaction_note: "",
    },
  });

  var dynamic_url = [
    { value: "https://www.google.com", label: "https://www.google.com" },
    { value: "https://www.gmail.com", label: "https://www.gmail.com" },
  ];
  var [dynamicURLDet, setDynamicURLDet] = useState({
    destination_url: "",
    url_alias: "",
  });
  var wifi_encryp = [
    { value: "WEP", label: "WEP" },
    { value: "WPA/WPA2", label: "WPA/WPA2" },
    { value: "No encryption", label: "No encryption" },
  ];

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Hanlde Format Date
  const handleStartDateChange = (date, time) => {
    const newDate = moment(date).format("YYYY-MM-DD").replaceAll("-", "");
    const newTime = time.replaceAll(":", "");
    const eventStartDateFormated = `${newDate + "T" + newTime + "00" + "Z"}`;
    setStartDate(eventStartDateFormated);
    setStartEvent(eventStartDateFormated);
    setQrDetails({
      ...qrDetails,
      event: {
        ...qrDetails.event,
        starts_on: eventStartDateFormated,
      },
    });
  };

  const handleEndDateChange = (date, time) => {
    const newDate = moment(date).format("YYYY-MM-DD").replaceAll("-", "");
    const newTime = time.replaceAll(":", "");
    const eventStartDateFormated = `${newDate + "T" + newTime + "00" + "Z"}`;
    setEndDate(eventStartDateFormated);
    setEndEvent(eventStartDateFormated);
    setQrDetails({
      ...qrDetails,
      event: {
        ...qrDetails.event,
        ends_on: date,
      },
    });
  };
  const [birthdayDate, setBirthdayDate] = useState(null);
  const handleBirthdayDateChange = (date) => {
    setBirthdayDate(date);
    setQrDetails({
      ...qrDetails,
      vcard: {
        ...qrDetails.vcard,
        birthday: date,
      },
    });
  };
  var [vardPhoneDet, setVcardPhoneDet] = useState({
    label: "",
    phone_num: "",
  });
  var [vardSocialDet, setVcardSocialDet] = useState({
    label: "",
    url: "",
  });
  const handleVcardPhoneCreate = () => {
    setQrDetails((prevDetails) => ({
      ...prevDetails,
      vcard: {
        ...prevDetails.vcard,
        vcard_phone_numbers: [
          ...prevDetails.vcard.vcard_phone_numbers,
          { label: vardPhoneDet.label, phone_num: vardPhoneDet.phone_num },
        ],
      },
    }));

    setVcardPhoneDet({ label: "", phone_num: "" });
  };

  const handleVcardPhoneDelete = (index) => {
    setQrDetails((prevDetails) => ({
      ...prevDetails,
      vcard: {
        ...prevDetails.vcard,
        vcard_phone_numbers: prevDetails.vcard.vcard_phone_numbers.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const handleVcardSocialCreate = () => {
    setQrDetails((prevDetails) => ({
      ...prevDetails,
      vcard: {
        ...prevDetails.vcard,
        vcard_social: [
          ...prevDetails.vcard.vcard_social,
          { label: vardSocialDet.label, url: vardSocialDet.url },
        ],
      },
    }));

    setVcardSocialDet({ label: "", url: "" });
  };

  const handleVcardSocialDelete = (index) => {
    setQrDetails((prevDetails) => ({
      ...prevDetails,
      vcard: {
        ...prevDetails.vcard,
        vcard_social: prevDetails.vcard.vcard_social.filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const qrtype = {
    TEXT: <TextQr qrDetails={qrDetails} setQrDetails={setQrDetails} />,
    URL: (
      <URLQr
        qrDetails={qrDetails}
        setQrDetails={setQrDetails}
        dynamic_url={dynamic_url}
        dynamicURLDet={dynamicURLDet}
        setDynamicURLDet={setDynamicURLDet}
      />
    ),
    PHONE: <PhoneQr qrDetails={qrDetails} setQrDetails={setQrDetails} />,
    SMS: <SmsQr qrDetails={qrDetails} setQrDetails={setQrDetails} />,
    EMAIL: <EmailQr qrDetails={qrDetails} setQrDetails={setQrDetails} />,
    WHATSAPP: <WhatsappQr qrDetails={qrDetails} setQrDetails={setQrDetails} />,
    FACETIME: <FacetimeQr qrDetails={qrDetails} setQrDetails={setQrDetails} />,
    LOCATION: <LocationQr qrDetails={qrDetails} setQrDetails={setQrDetails} />,
    WIFI: (
      <WifiQr
        qrDetails={qrDetails}
        setQrDetails={setQrDetails}
        wifi_encryp={wifi_encryp}
      />
    ),
    EVENT: (
      <EventQr
        qrDetails={qrDetails}
        setQrDetails={setQrDetails}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        timezones={timezones}
      />
    ),
    VCARD: (
      <VcardQr
        qrDetails={qrDetails}
        setQrDetails={setQrDetails}
        birthdayDate={birthdayDate}
        handleBirthdayDateChange={handleBirthdayDateChange}
        handleVcardPhoneCreate={handleVcardPhoneCreate}
        handleVcardPhoneDelete={handleVcardPhoneDelete}
        handleVcardSocialCreate={handleVcardSocialCreate}
        handleVcardSocialDelete={handleVcardSocialDelete}
        load={load}
        loadImages={loadImages}
      />
    ),
    PAYMENT: <PaymentQr qrDetails={qrDetails} setQrDetails={setQrDetails} />,
  };
  // Types
  const [customQr, setCustomQr] = useState({
    data: "Welcome to Weboney QR Code Generator",
    format: {
      type: "PNG",
      width: media570px ? 300 : 500,
      height: media570px ? 300 : 500,
      margin: 0,
    },
    qrOptions: {
      errorLevel: "L",
    },
    image: {
      path: "",
      size: 0.2,
      margin: 0,
      hideDot: true,
    },
    backgroundColor: {
      type: "Single",
      color: "#ffffff",
      gradienttype: "linear",
      dotsGradient: {
        firstColor: "#ffff",
        secondColor: "#ffff",
      },
      rotation: 0,
    },
    style: {
      type: `sqaure`,
      colorType: "Single",
      color: "#050505",
      gradienttype: "linear",
      dotsGradient: {
        firstColor: "#050505",
        secondColor: "#050505",
      },
      rotation: "0",
    },
    innerEyeStyle: {
      type: `square`,
      colorType: "Single",
      color: "#050505",
      gradienttype: "linear",
      dotsGradient: {
        firstColor: "#050505",
        secondColor: "#050505",
      },
      rotation: "0",
    },
    outerEyeStyle: {
      type: `square`,
      colorType: "Single",
      color: "#050505",
      gradienttype: "linear",
      dotsGradient: {
        firstColor: "#050505",
        secondColor: "#050505",
      },
      rotation: "0",
    },
  });

  // State
  const ref = useRef(null);

  //Components QR Code Generator
  const qrCode = new QRCodeStyling({
    width: media570px ? 300 : customQr.format.width,
    height: media570px ? 300 : customQr.format.height,
    type: customQr.format.type,
    margin: customQr.format.margin,
    image: !primeUser
      ? BrandLogo
      : customQr?.image?.path instanceof File ||
        customQr?.image?.path instanceof Blob
      ? URL.createObjectURL(customQr.image.path)
      : "",
    // image: BrandLogo,
    dotsOptions: {
      color: customQr.style.colorType === "Single" ? customQr.style.color : "",
      type: `${customQr.style.type}`,
      gradient: customQr.style.colorType !== "Single" && {
        type: customQr.style.gradienttype,
        rotation: customQr.style.rotation,
        colorStops: [
          { offset: 0, color: customQr.style.dotsGradient.firstColor },
          { offset: 1, color: customQr.style.dotsGradient.secondColor },
        ],
      },
    },
    cornersSquareOptions: {
      color:
        customQr.outerEyeStyle.colorType === "Single" &&
        customQr.outerEyeStyle.color,
      gradient: customQr.outerEyeStyle.colorType !== "Single" && {
        type: customQr.outerEyeStyle.gradienttype,
        rotation: customQr.outerEyeStyle.rotation,
        colorStops: [
          { offset: 0, color: customQr.outerEyeStyle.dotsGradient.firstColor },
          { offset: 1, color: customQr.outerEyeStyle.dotsGradient.secondColor },
        ],
      },
      type: `${customQr.outerEyeStyle.type}`,
    },
    cornersDotOptions: {
      color:
        customQr.innerEyeStyle.colorType === "Single" &&
        customQr.innerEyeStyle.color,
      gradient: customQr.innerEyeStyle.colorType !== "Single" && {
        type: customQr.innerEyeStyle.gradienttype,
        rotation: customQr.innerEyeStyle.rotation,
        colorStops: [
          { offset: 0, color: customQr.innerEyeStyle.dotsGradient.firstColor },
          { offset: 1, color: customQr.innerEyeStyle.dotsGradient.secondColor },
        ],
      },
      type: `${customQr.innerEyeStyle.type}`,
    },
    backgroundOptions: {
      color:
        customQr.backgroundColor.type === "Single"
          ? customQr.backgroundColor.color
          : "",
      gradient: customQr.backgroundColor.type !== "Single" && {
        type: customQr.backgroundColor.gradienttype,
        rotation: customQr.backgroundColor.rotation,
        colorStops: [
          {
            offset: 0,
            color: customQr.backgroundColor.dotsGradient.firstColor,
          },
          {
            offset: 1,
            color: customQr.backgroundColor.dotsGradient.secondColor,
          },
        ],
      },
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: !primeUser ? 15 : customQr.image.margin,
      hideBackgroundDots: customQr.image.hideDot,
      imageSize: !primeUser ? 1.5 : customQr.image.size,
    },
    qrOptions: {
      errorCorrectionLevel: customQr.qrOptions.errorLevel,
    },
  });

  useEffect(() => {
    qrCode.append(ref.current);
  }, [customQr]);

  const fomrateStartDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");
    // setStartEvent(`${year}${month}${day}T${hours}${minutes}${seconds}Z`);
  };
  function formatDateToGoogleCalendarFormat1(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");
    // setEndEvent(`${year}${month}${day}T${hours}${minutes}${seconds}Z`);
  }

  useEffect(() => {
    fomrateStartDate(qrDetails?.event?.starts_on);
    formatDateToGoogleCalendarFormat1(qrDetails?.event?.ends_on);
  }, [qrDetails]);

  // Set Data
  useEffect(() => {
    if (datatype.toUpperCase() === "TEXT") {
      setCustomQr({
        ...customQr,
        data: qrDetails.text.text_content,
      });
    } else if (datatype.toUpperCase() === "URL") {
      setCustomQr({
        ...customQr,
        data: qrDetails.url.url,
      });
    } else if (datatype.toUpperCase() === "PHONE") {
      if (qrDetails.phone.phone_number.startsWith("+91")) {
        setCustomQr({
          ...customQr,
          data: `tel:${qrDetails.phone.phone_number}`,
        });
      } else {
        setCustomQr({
          ...customQr,
          data: `tel:+91${qrDetails.phone.phone_number}`,
        });
      }
    } else if (datatype.toUpperCase() === "SMS") {
      if (qrDetails.sms.phone_number.startsWith("+91")) {
        setCustomQr({
          ...customQr,
          data: `sms:${qrDetails.sms.phone_number}?body=${encodeURIComponent(
            qrDetails.sms.prefilled_message
          )}`,
        });
      } else {
        setCustomQr({
          ...customQr,
          data: `sms:+91${qrDetails.sms.phone_number}?body=${encodeURIComponent(
            qrDetails.sms.prefilled_message
          )}`,
        });
      }
    } else if (datatype.toUpperCase() === "EMAIL") {
      setCustomQr({
        ...customQr,
        data: `mailto:${qrDetails.email.email_address}?subject=${qrDetails.email.prefilled_subject}&body=${qrDetails.email.prefilled_message}`,
      });
    } else if (datatype.toUpperCase() === "WHATSAPP") {
      if (qrDetails.whatsapp.phone_number.startsWith("+91")) {
        setCustomQr({
          ...customQr,
          data: `https://wa.me/${qrDetails.whatsapp.phone_number}?text=${qrDetails.whatsapp.prefilled_message}`,
        });
      } else {
        setCustomQr({
          ...customQr,
          data: `https://wa.me/+91${qrDetails.whatsapp.phone_number}?text=${qrDetails.whatsapp.prefilled_message}`,
        });
      }
    } else if (datatype.toUpperCase() === "LOCATION") {
      setCustomQr({
        ...customQr,
        data: `https://www.google.com/maps/search/?api=1&query=${qrDetails.location.location_address
          .replaceAll(" ", "+")
          .replaceAll(",", "+")}`,
      });
    } else if (datatype.toUpperCase() === "PAYMENT") {
      setCustomQr({
        ...customQr,
        data: `upi://pay?pa=${qrDetails.payment.upi_id}&pn=${"Dmeo"}&tn=${
          qrDetails.payment.transaction_note
        }&am=${qrDetails.payment.amount}&cu=INR`,
      });
    } else if (datatype.toUpperCase() === "WIFI") {
      setCustomQr({
        ...customQr,
        data: `WIFI:S:${qrDetails.wifi.wifi_name.trim()};T:WPA;P:${qrDetails.wifi.wifi_password.trim()};;`,
      });
    } else if (datatype.toUpperCase() === "EVENT") {
      setCustomQr({
        ...customQr,
        data: `https://www.google.com/calendar/render?action=TEMPLATE&text=${qrDetails.event.event_name}&location=${qrDetails.event.geo_location}&details=${qrDetails.event.notes}&dates=${startEvent}/${endEvent}&ctz=UTC`,
      });
    } else if (datatype.toUpperCase() === "FACETIME") {
      setCustomQr({
        ...customQr,
        data: `facetime://${qrDetails.facetime.facetime_phone_email}`,
      });
    } else if (datatype.toUpperCase() === "VCARD") {
      setCustomQr({
        ...customQr,
        data: `https://www.qrcodeweboney.in/#/vcard/${vCardId}`,
      });
    }
  }, [qrDetails, vCardId]);

  useEffect(() => {
    setCustomQr({
      ...customQr,
      data: "Welcome to Weboney QR Code Generator",
    });
  }, [datatype]);

  useEffect(() => {
    qrCode.update({
      data: customQr.data,
    });
  }, [customQr]);

  const onDownloadClick = async () => {
    try {
      if (customQr.data != "Welcome to Weboney QR Code Generator") {
        qrCode.download({
          extension: customQr.format.type.toLocaleLowerCase(),
        });
        var body = {
          id: userData.id,
          count: userData.download_achived,
        };
        const result = await updateUserDownloadCount(body);
        dispatch(setUserData(result.data[0]));
        setDummy(!dummy);
      } else {
        errorNotification(
          "Please fill out the form before initiating the download"
        );
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (!primeUser) {
      setCustomQr({
        ...customQr,
        image: {
          ...customQr.image,
          path: "../../Assets/BrandLogo.png",
        },
      });
    }
  }, [primeUser]);

  // Style Array
  const styleArray = [
    {
      icon: `fa fa-stop`,
      title: `Square`,
    },
    {
      icon: `fa fa-circle`,
      title: `Dots`,
    },
    {
      icon: `fa fa-square`,
      title: `Rounded`,
    },
    {
      icon: `fa fa-square`,
      title: `Extra Rounded`,
    },
    {
      icon: `fa fa-stop`,
      title: `Classy`,
    },
    {
      icon: `fa fa-square`,
      title: `Classy Rounded`,
    },
  ];
  // Outer Eye Array
  const outerEyeArray = [
    {
      icon: `fa fa-stop`,
      title: `Square`,
    },
    {
      icon: `fa fa-circle`,
      title: `Dot`,
    },
    {
      icon: `fa fa-square`,
      title: `Extra Rounded`,
    },
  ];
  // inner Eye Array
  const innerEyeArray = [
    {
      icon: `fa fa-stop`,
      title: `Square`,
    },
    {
      icon: `fa fa-circle`,
      title: `Dot`,
    },
  ];
  // qr mode Array
  const qrmodeArray = [
    {
      icon: <OneTwoThree />,
      title: `Numeric`,
    },
    {
      icon: <Abc />,
      title: `Alphanumeric`,
    },
    {
      icon: <Qrcode />,
      title: `Byte`,
    },
    {
      icon: <Number />,
      title: `Kanji`,
    },
  ];
  const qrerrorcorrectArray = [
    {
      icon: `fa fa-exclamation`,
      title: `Low`,
    },
    {
      icon: `fa fa-exclamation`,
      title: `Medium`,
    },
    {
      icon: `fa fa-exclamation`,
      title: `Quality`,
    },
    {
      icon: `fa fa-exclamation`,
      title: `High`,
    },
  ];

  // Accordian Value
  const [accordianValue, setAccordianValue] = useState("1");

  // Prevent Right Click
  const preventRightClick = (e) => {
    e.preventDefault();
  };

  // Navigate To PRicing
  const handleNavigateToPricing = () => {
    errorNotification(
      "Your download limit has been reached. Please choose a plan to increase your download limit"
    );

    navigate("/pricedetails");
  };

  // Save TO DB
  const handileSubmit = async () => {
    // if (
    //   +userData.download_limit > +userData.download_achived ||
    //   +userData.download_limit === -1
    // ) {
    //   QrcodeAddControl1(qrDetails, customQr, datatype, navigate);
    //   var body = {
    //     id: userData.id,
    //     count: userData.download_achived,
    //   };
    //   const result = await updateUserDownloadCount(body);
    //   dispatch(setUserData(result.data[0]));
    //   setDummy(!dummy);
    // } else {
    //   errorNotification(
    //     "Your download limit has been reached. Please choose a plan to increase your download limit"
    //   );

    //   navigate("/pricedetails");
    // }
    notification.warning({
      message: "To utilize this feature Login is Required",
    });
    navigate("/login");
  };

  // Hanlde API VCARD
  const handleVcardApi = () => {
    QrcodeAddControl(qrDetails, customQr, datatype, navigate, setVcardId);
  };

  useEffect(() => {
    if (customQr.image.size >= 0.7) {
      setCustomQr({
        ...customQr,
        image: {
          ...customQr.image,
          size: 0.7,
        },
      });
    }
    if (customQr.image.size >= 0.5 && datatype === "VCARD") {
      setCustomQr({
        ...customQr,
        image: {
          ...customQr.image,
          size: 0.5,
        },
      });
    }
  }, [customQr.image.size]);

  return (
    <div>
      <div className="qr-code-generator-page">
        <div className="qr-code-generator-container">
          <div
            style={{ maxHeight: `${accordianValue === 1 ? "70vh" : ""}` }}
            className="qr-code-generator-container-inputs"
          >
            {qrtype[datatype ? datatype.toUpperCase() : "TEXT"]}
            {datatype.toUpperCase() === "VCARD" && (
              <Center>
                <Button
                  style={{ backgroundColor: "#2b3659" }}
                  onClick={handleVcardApi}
                  w={"170px"}
                  mb={"sm"}
                  mt={"sm"}
                >
                  Generate QR Code
                </Button>
              </Center>
            )}
            <div>
              <Accordion
                mt={"md"}
                style={{ color: "black" }}
                transitionDuration={1000}
                variant="separated"
                radius="md"
                onChange={(e) => {
                  setAccordianValue(e);
                }}
              >
                <Accordion.Item value={"Style"}>
                  <Accordion.Control
                    className="qr-code-generator-container-inputs-control"
                    style={{
                      backgroundColor: `${
                        accordianValue === "1"
                          ? " #1357be"
                          : accordianValue === "Style"
                          ? " #1357be"
                          : ""
                      }`,
                      borderRadius: "10px",
                      color: `${
                        accordianValue === "Style"
                          ? " White"
                          : accordianValue === "1"
                          ? " White"
                          : ""
                      }`,
                    }}
                  >
                    <Center>
                      <Flex align={"center"} gap={"0.3rem"}>
                        <i className="fa fa-circle-o"></i>
                        Inner Qr Style
                      </Flex>
                    </Center>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <div className="style-container">
                      <Title order={4}>
                        <Flex align={"center"} gap={"0.3rem"}>
                          <i className="fa fa-circle-o"></i>
                          Inner Qr Style Type
                        </Flex>
                      </Title>
                      <Grid pt={"sm"} pb={"sm"} columns={2}>
                        {styleArray.map((value, index) => {
                          return (
                            <Grid.Col key={index} span={1}>
                              <Card
                                onClick={() => {
                                  setCustomQr({
                                    ...customQr,
                                    style: {
                                      ...customQr.style,
                                      type: value.title
                                        .toLocaleLowerCase()
                                        .replaceAll(" ", "-"),
                                    },
                                  });
                                }}
                                shadow="sm"
                                padding="sm"
                                radius="md"
                                withBorder
                                style={{
                                  textAlign: "center",
                                  backgroundColor: `${
                                    customQr.style.type ===
                                    value.title
                                      .toLocaleLowerCase()
                                      .replaceAll(" ", "-")
                                      ? "#1357be"
                                      : ""
                                  }`,
                                  color: `${
                                    customQr.style.type ===
                                    value.title
                                      .toLocaleLowerCase()
                                      .replaceAll(" ", "-")
                                      ? "white"
                                      : "black"
                                  }`,
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "0.8rem",
                                }}
                              >
                                <i className={value.icon}></i>
                                {value.title}
                              </Card>
                            </Grid.Col>
                          );
                        })}
                      </Grid>
                    </div>
                    <div>
                      <Title order={4}>
                        <Flex align={"center"} gap={"0.3rem"}>
                          <i className="fa fa-paint-brush"></i>
                          Color
                        </Flex>
                      </Title>

                      <Radio.Group
                        pt={"lg"}
                        pl={"lg"}
                        onChange={(e) => {
                          setCustomQr({
                            ...customQr,
                            style: {
                              ...customQr.style,
                              colorType: e,
                            },
                          });
                        }}
                        label="Color Type"
                        defaultValue={customQr.style.colorType}
                      >
                        <Group mt="xs">
                          <Radio value="Single" label="Single" />
                          <Radio value="Gradient" label="Gradient" />
                        </Group>
                      </Radio.Group>
                      {customQr.style.colorType === "Single" ? (
                        <Input.Wrapper p={"lg"} label="Color">
                          <ColorInput
                            defaultValue={customQr.style.color}
                            value={customQr.style.color}
                            w={"200px"}
                            size="xs"
                            onChange={(e) => {
                              setCustomQr({
                                ...customQr,
                                style: {
                                  ...customQr.style,
                                  color: e,
                                },
                              });
                            }}
                          />
                        </Input.Wrapper>
                      ) : (
                        <>
                          <Flex align={"center"} gap={"1rem"}>
                            <Radio.Group
                              pt={"sm"}
                              pl={"lg"}
                              onChange={(e) => {
                                setCustomQr({
                                  ...customQr,
                                  style: {
                                    ...customQr.style,
                                    gradienttype: e,
                                  },
                                });
                              }}
                              label="Gradient Type"
                              defaultValue={customQr.style.gradienttype}
                            >
                              <Group mt="xs">
                                <Radio value="linear" label="Linear" />
                                <Radio value="radial" label="Radial" />
                              </Group>
                            </Radio.Group>
                            <Input.Wrapper p={"lg"} pt={"xl"} label="Color">
                              <Flex gap={"0.5rem"}>
                                <ColorInput
                                  onChange={(e) => {
                                    setCustomQr({
                                      ...customQr,
                                      style: {
                                        ...customQr.style,
                                        dotsGradient: {
                                          ...customQr.style.dotsGradient,
                                          firstColor: e,
                                        },
                                      },
                                    });
                                  }}
                                  value={customQr.style.dotsGradient.firstColor}
                                  w={"100px"}
                                  size="xs"
                                />
                                <ColorInput
                                  value={
                                    customQr.style.dotsGradient.secondColor
                                  }
                                  onChange={(e) => {
                                    setCustomQr({
                                      ...customQr,
                                      style: {
                                        ...customQr.style,
                                        dotsGradient: {
                                          ...customQr.style.dotsGradient,
                                          secondColor: e,
                                        },
                                      },
                                    });
                                  }}
                                  w={"100px"}
                                  size="xs"
                                />
                              </Flex>
                            </Input.Wrapper>
                            <NumberInput
                              onChange={(e) => {
                                setCustomQr({
                                  ...customQr,
                                  style: {
                                    ...customQr.style,
                                    rotation: e,
                                  },
                                });
                              }}
                              pt={"sm"}
                              w={"130px"}
                              size="xs"
                              label="Rotation"
                              min={0}
                              value={customQr.style.rotation}
                            />
                          </Flex>
                        </>
                      )}
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={"outer-eye"}>
                  <Accordion.Control
                    className="qr-code-generator-container-inputs-control"
                    style={{
                      backgroundColor: `${
                        accordianValue === "outer-eye" ? " #1357be" : ""
                      }`,
                      borderRadius: "10px",
                      color: `${
                        accordianValue === "outer-eye" ? " White" : ""
                      }`,
                    }}
                  >
                    <Center>
                      <Flex align={"center"} gap={"0.3rem"}>
                        <i className="fa fa-stop-circle"></i>
                        Outer Eye
                      </Flex>
                    </Center>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <div className="style-container">
                      <Title order={4}>
                        <Flex align={"center"} gap={"0.3rem"}>
                          <i className="fa fa-stop-circle"></i>
                          Outer Eye Style Type
                        </Flex>
                      </Title>
                      <Grid pt={"sm"} pb={"sm"} columns={2}>
                        {outerEyeArray.map((value, index) => {
                          return (
                            <Grid.Col key={index} span={1}>
                              <Card
                                onClick={() => {
                                  setCustomQr({
                                    ...customQr,
                                    outerEyeStyle: {
                                      ...customQr.outerEyeStyle,
                                      type: value.title
                                        .toLocaleLowerCase()
                                        .replaceAll(" ", "-"),
                                    },
                                  });
                                }}
                                shadow="sm"
                                padding="sm"
                                radius="md"
                                withBorder
                                style={{
                                  textAlign: "center",
                                  backgroundColor: `${
                                    customQr.outerEyeStyle.type ===
                                    value.title
                                      .toLocaleLowerCase()
                                      .replaceAll(" ", "-")
                                      ? "#1357be"
                                      : ""
                                  }`,
                                  color: `${
                                    customQr.outerEyeStyle.type ===
                                    value.title
                                      .toLocaleLowerCase()
                                      .replaceAll(" ", "-")
                                      ? "white"
                                      : "black"
                                  }`,
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "0.8rem",
                                }}
                              >
                                <i className={value.icon}></i>
                                {value.title}
                              </Card>
                            </Grid.Col>
                          );
                        })}
                      </Grid>
                    </div>
                    <div>
                      <Title order={4}>
                        <Flex align={"center"} gap={"0.3rem"}>
                          <i className="fa fa-paint-brush"></i>
                          Color
                        </Flex>
                      </Title>
                      <Radio.Group
                        pt={"lg"}
                        pl={"lg"}
                        onChange={(e) => {
                          setCustomQr({
                            ...customQr,
                            outerEyeStyle: {
                              ...customQr.outerEyeStyle,
                              colorType: e,
                            },
                          });
                        }}
                        label="Color Type"
                        defaultValue={customQr.style.colorType}
                      >
                        <Group mt="xs">
                          <Radio value="Single" label="Single" />
                          <Radio value="Gradient" label="Gradient" />
                        </Group>
                      </Radio.Group>
                      {customQr.outerEyeStyle.colorType === "Single" ? (
                        <Input.Wrapper p={"lg"} label="Color">
                          <ColorInput
                            defaultValue={customQr.outerEyeStyle.color}
                            value={customQr.outerEyeStyle.color}
                            w={"200px"}
                            size="xs"
                            onChange={(e) => {
                              setCustomQr({
                                ...customQr,
                                outerEyeStyle: {
                                  ...customQr.outerEyeStyle,
                                  color: e,
                                },
                              });
                            }}
                          />
                        </Input.Wrapper>
                      ) : (
                        <>
                          <Flex align={"center"}>
                            <Radio.Group
                              // pt={"lg"}
                              pl={"lg"}
                              onChange={(e) => {
                                setCustomQr({
                                  ...customQr,
                                  style: {
                                    ...customQr.style,
                                    gradienttype: e,
                                  },
                                });
                              }}
                              label="Gradient Type"
                              defaultValue={customQr.outerEyeStyle.gradienttype}
                            >
                              <Group mt="xs">
                                <Radio value="linear" label="Linear" />
                                <Radio value="radial" label="Radial" />
                              </Group>
                            </Radio.Group>
                            <Input.Wrapper p={"lg"} pt={"lg"} label="Color">
                              <Flex align={"center"} gap={"0.5rem"}>
                                <ColorInput
                                  onChange={(e) => {
                                    setCustomQr({
                                      ...customQr,
                                      outerEyeStyle: {
                                        ...customQr.outerEyeStyle,
                                        dotsGradient: {
                                          ...customQr.outerEyeStyle
                                            .dotsGradient,
                                          firstColor: e,
                                        },
                                      },
                                    });
                                  }}
                                  value={
                                    customQr.outerEyeStyle.dotsGradient
                                      .firstColor
                                  }
                                  w={"100px"}
                                  size="xs"
                                />
                                <ColorInput
                                  onChange={(e) => {
                                    setCustomQr({
                                      ...customQr,
                                      outerEyeStyle: {
                                        ...customQr.outerEyeStyle,
                                        dotsGradient: {
                                          ...customQr.outerEyeStyle
                                            .dotsGradient,
                                          secondColor: e,
                                        },
                                      },
                                    });
                                  }}
                                  value={
                                    customQr.outerEyeStyle.dotsGradient
                                      .secondColor
                                  }
                                  w={"100px"}
                                  size="xs"
                                />
                              </Flex>
                            </Input.Wrapper>
                            <NumberInput
                              onChange={(e) => {
                                setCustomQr({
                                  ...customQr,
                                  outerEyeStyle: {
                                    ...customQr.outerEyeStyle,
                                    rotation: e,
                                  },
                                });
                              }}
                              value={customQr.outerEyeStyle.rotation}
                              size="xs"
                              w={"130px"}
                              label="Rotation"
                            />
                          </Flex>
                        </>
                      )}
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={"inner-eye"}>
                  <Accordion.Control
                    className="qr-code-generator-container-inputs-control"
                    style={{
                      backgroundColor: `${
                        accordianValue === "inner-eye" ? " #1357be" : ""
                      }`,
                      borderRadius: "10px",
                      color: `${
                        accordianValue === "inner-eye" ? " White" : ""
                      }`,
                    }}
                  >
                    <Center>
                      <Flex align={"center"} gap={"0.3rem"}>
                        <i className="fa fa-circle"></i>
                        Inner Eye
                      </Flex>
                    </Center>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <div className="style-container">
                      <Title order={4}>
                        <Flex align={"center"} gap={"0.3rem"}>
                          <i className="fa fa-circle"></i>
                          Inner Eye Style Type
                        </Flex>
                      </Title>
                      <Grid pt={"sm"} pb={"sm"} columns={2}>
                        {innerEyeArray.map((value, index) => {
                          return (
                            <Grid.Col key={index} span={1}>
                              <Card
                                onClick={() => {
                                  setCustomQr({
                                    ...customQr,
                                    innerEyeStyle: {
                                      ...customQr.innerEyeStyle,
                                      type: value.title
                                        .toLocaleLowerCase()
                                        .replaceAll(" ", "-"),
                                    },
                                  });
                                }}
                                shadow="sm"
                                padding="sm"
                                radius="md"
                                withBorder
                                style={{
                                  textAlign: "center",
                                  backgroundColor: `${
                                    customQr.innerEyeStyle.type ===
                                    value.title
                                      .toLocaleLowerCase()
                                      .replaceAll(" ", "-")
                                      ? "#1357be"
                                      : ""
                                  }`,
                                  color: `${
                                    customQr.innerEyeStyle.type ===
                                    value.title
                                      .toLocaleLowerCase()
                                      .replaceAll(" ", "-")
                                      ? "white"
                                      : "black"
                                  }`,
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "0.8rem",
                                }}
                              >
                                <i className={value.icon}></i>
                                {value.title}
                              </Card>
                            </Grid.Col>
                          );
                        })}
                      </Grid>
                    </div>
                    <div>
                      <Title order={4}>
                        <Flex align={"center"} gap={"0.3rem"}>
                          <i className="fa fa-paint-brush"></i>
                          Color
                        </Flex>
                      </Title>
                      <Radio.Group
                        pt={"lg"}
                        pl={"lg"}
                        onChange={(e) => {
                          setCustomQr({
                            ...customQr,
                            innerEyeStyle: {
                              ...customQr.innerEyeStyle,
                              colorType: e,
                            },
                          });
                        }}
                        label="Color Type"
                        defaultValue={customQr.innerEyeStyle.colorType}
                      >
                        <Group mt="xs">
                          <Radio value="Single" label="Single" />
                          <Radio value="Gradient" label="Gradient" />
                        </Group>
                      </Radio.Group>
                      {customQr.innerEyeStyle.colorType === "Single" ? (
                        <Input.Wrapper p={"lg"} label="Color">
                          <ColorInput
                            defaultValue={customQr.innerEyeStyle.color}
                            value={customQr.innerEyeStyle.color}
                            w={"200px"}
                            size="xs"
                            onChange={(e) => {
                              setCustomQr({
                                ...customQr,
                                innerEyeStyle: {
                                  ...customQr.innerEyeStyle,
                                  color: e,
                                },
                              });
                            }}
                          />
                        </Input.Wrapper>
                      ) : (
                        <>
                          <Flex align={"center"}>
                            <Radio.Group
                              pl={"lg"}
                              onChange={(e) => {
                                setCustomQr({
                                  ...customQr,
                                  innerEyeStyle: {
                                    ...customQr.innerEyeStyle,
                                    gradienttype: e,
                                  },
                                });
                              }}
                              label="Gradient Type"
                              defaultValue={customQr.innerEyeStyle.gradienttype}
                            >
                              <Group mt="xs">
                                <Radio value="linear" label="Linear" />
                                <Radio value="radial" label="Radial" />
                              </Group>
                            </Radio.Group>
                            <Input.Wrapper p={"lg"} label="Color">
                              <Flex align={"center"} gap={"0.3rem"}>
                                <ColorInput
                                  onChange={(e) => {
                                    setCustomQr({
                                      ...customQr,
                                      innerEyeStyle: {
                                        ...customQr.innerEyeStyle,
                                        dotsGradient: {
                                          ...customQr.innerEyeStyle
                                            .dotsGradient,
                                          firstColor: e,
                                        },
                                      },
                                    });
                                  }}
                                  value={
                                    customQr.innerEyeStyle.dotsGradient
                                      .firstColor
                                  }
                                  w={"100px"}
                                  size="xs"
                                />
                                <ColorInput
                                  onChange={(e) => {
                                    setCustomQr({
                                      ...customQr,
                                      innerEyeStyle: {
                                        ...customQr.innerEyeStyle,
                                        dotsGradient: {
                                          ...customQr.innerEyeStyle
                                            .dotsGradient,
                                          secondColor: e,
                                        },
                                      },
                                    });
                                  }}
                                  value={
                                    customQr.innerEyeStyle.dotsGradient
                                      .secondColor
                                  }
                                  w={"100px"}
                                  size="xs"
                                />
                              </Flex>
                            </Input.Wrapper>
                            <NumberInput
                              onChange={(e) => {
                                setCustomQr({
                                  ...customQr,
                                  innerEyeStyle: {
                                    ...customQr.innerEyeStyle,
                                    rotation: e,
                                  },
                                });
                              }}
                              value={customQr.innerEyeStyle.rotation}
                              size="xs"
                              width={"130px"}
                              label="Rotation"
                            />
                          </Flex>
                        </>
                      )}
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={"background-options"}>
                  <Accordion.Control
                    className="qr-code-generator-container-inputs-control"
                    style={{
                      backgroundColor: `${
                        accordianValue === "background-options"
                          ? " #1357be"
                          : ""
                      }`,
                      borderRadius: "10px",
                      color: `${
                        accordianValue === "background-options" ? " White" : ""
                      }`,
                    }}
                  >
                    <Center>
                      <Flex align={"center"} gap={"0.3rem"}>
                        <AdjustmentsHorizontal size={"1.3rem"} />
                        Background Options
                      </Flex>
                    </Center>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <div>
                      <Title order={4}>
                        <Flex align={"center"} gap={"0.3rem"}>
                          <i className="fa fa-paint-brush"></i>
                          Color
                        </Flex>
                      </Title>
                      <Radio.Group
                        pt={"lg"}
                        pl={"lg"}
                        onChange={(e) => {
                          setCustomQr({
                            ...customQr,
                            backgroundColor: {
                              ...customQr.backgroundColor,
                              type: e,
                            },
                          });
                        }}
                        label="Color Type"
                        defaultValue={customQr.backgroundColor.type}
                      >
                        <Group mt="xs">
                          <Radio value="Single" label="Single" />
                          <Radio value="Gradient" label="Gradient" />
                        </Group>
                      </Radio.Group>
                      {customQr.backgroundColor.type === "Single" ? (
                        <Input.Wrapper p={"lg"} label="Color">
                          <ColorInput
                            defaultValue={customQr.backgroundColor.color}
                            value={customQr.backgroundColor.color}
                            w={"200px"}
                            size="xs"
                            onChange={(e) => {
                              setCustomQr({
                                ...customQr,
                                backgroundColor: {
                                  ...customQr.backgroundColor,
                                  color: e,
                                },
                              });
                            }}
                          />
                        </Input.Wrapper>
                      ) : (
                        <>
                          <Flex align={"center"}>
                            <Radio.Group
                              pl={"lg"}
                              onChange={(e) => {
                                setCustomQr({
                                  ...customQr,
                                  backgroundColor: {
                                    ...customQr.backgroundColor,
                                    gradienttype: e,
                                  },
                                });
                              }}
                              label="Gradient Type"
                              defaultValue={
                                customQr.backgroundColor.gradienttype
                              }
                            >
                              <Group mt="xs">
                                <Radio value="linear" label="Linear" />
                                <Radio value="radial" label="Radial" />
                              </Group>
                            </Radio.Group>
                            <Input.Wrapper p={"lg"} label="Color">
                              <Flex align={"center"} gap={"0.3rem"}>
                                <ColorInput
                                  onChange={(e) => {
                                    setCustomQr({
                                      ...customQr,
                                      backgroundColor: {
                                        ...customQr.backgroundColor,
                                        dotsGradient: {
                                          ...customQr.backgroundColor
                                            .dotsGradient,
                                          firstColor: e,
                                        },
                                      },
                                    });
                                  }}
                                  value={
                                    customQr.backgroundColor.dotsGradient
                                      .firstColor
                                  }
                                  w={"100px"}
                                  size="xs"
                                />
                                <ColorInput
                                  onChange={(e) => {
                                    setCustomQr({
                                      ...customQr,
                                      backgroundColor: {
                                        ...customQr.backgroundColor,
                                        dotsGradient: {
                                          ...customQr.backgroundColor
                                            .dotsGradient,
                                          secondColor: e,
                                        },
                                      },
                                    });
                                  }}
                                  value={
                                    customQr.backgroundColor.dotsGradient
                                      .secondColor
                                  }
                                  w={"100px"}
                                  size="xs"
                                />
                              </Flex>
                            </Input.Wrapper>
                            <NumberInput
                              onChange={(e) => {
                                setCustomQr({
                                  ...customQr,
                                  backgroundColor: {
                                    ...customQr.backgroundColor,
                                    rotation: e,
                                  },
                                });
                              }}
                              value={customQr.backgroundColor.rotation}
                              size="xs"
                              label="Rotation"
                              w={"130px"}
                            />
                          </Flex>
                        </>
                      )}
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={"image-options"}>
                  <Accordion.Control
                    className="qr-code-generator-container-inputs-control"
                    style={{
                      backgroundColor: `${
                        accordianValue === "image-options" ? " #1357be" : ""
                      }`,
                      borderRadius: "10px",
                      color: `${
                        accordianValue === "image-options" ? " White" : ""
                      }`,
                    }}
                  >
                    <Center>
                      <Flex align={"center"} gap={"0.3rem"}>
                        <i className="fa fa-file-image-o"></i>
                        Branding Options
                      </Flex>
                    </Center>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Input.Wrapper>
                      <Checkbox
                        value={customQr.image.hideDot}
                        onClick={(e) => {
                          setCustomQr({
                            ...customQr,
                            image: {
                              ...customQr.image,
                              hideDot: e.currentTarget.checked,
                            },
                          });
                        }}
                        defaultChecked
                        label="Hide Background Dots"
                      />
                    </Input.Wrapper>
                    <div>
                      <Flex pt={"lg"} pb={"lg"} gap={"1.2rem"}>
                        <Input.Wrapper w={"100%"}>
                          <NumberInput
                            allowDecimal={true}
                            value={customQr.image.size}
                            min={0.1}
                            max={1}
                            step={0.1}
                            onChange={(e) => {
                              setCustomQr({
                                ...customQr,
                                image: {
                                  ...customQr.image,
                                  size: e,
                                },
                              });
                            }}
                            label="Image Size"
                          />
                        </Input.Wrapper>
                        <Input.Wrapper w={"100%"}>
                          <NumberInput
                            label="Margin"
                            value={customQr.image.margin}
                            min={0}
                            max={25}
                            onChange={(e) => {
                              setCustomQr({
                                ...customQr,
                                image: {
                                  ...customQr.image,
                                  margin: e,
                                },
                              });
                            }}
                          />
                        </Input.Wrapper>
                        <Input.Wrapper w={"100%"}>
                          <FileInput
                            onChange={(e) => {
                              setCustomQr({
                                ...customQr,
                                image: {
                                  ...customQr.image,
                                  path: e,
                                },
                              });
                            }}
                            clearable
                            label="Logo"
                            placeholder="Click to Upload"
                          />
                        </Input.Wrapper>
                      </Flex>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value={"qr-options"}>
                  <Accordion.Control
                    className="qr-code-generator-container-inputs-control"
                    style={{
                      backgroundColor: `${
                        accordianValue === "qr-options" ? " #1357be" : ""
                      }`,
                      borderRadius: "10px",
                      color: `${
                        accordianValue === "qr-options" ? " White" : ""
                      }`,
                    }}
                  >
                    <Center>
                      <Flex align={"center"} gap={"0.3rem"}>
                        <i className="fa fa-qrcode"></i>
                        QR Options
                      </Flex>
                    </Center>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <div className="style-container">
                      <Title order={4}>
                        <Flex align={"center"} gap={"0.3rem"}>
                          <i className="fa fa-exclamation-triangle"></i>
                          Error Correction Level
                        </Flex>
                      </Title>
                      <Grid pt={"sm"} pb={"sm"} columns={2}>
                        {qrerrorcorrectArray.map((value, index) => {
                          return (
                            <Grid.Col key={index} span={1}>
                              <Card
                                onClick={() => {
                                  setCustomQr({
                                    ...customQr,
                                    qrOptions: {
                                      ...customQr.qrOptions,
                                      errorLevel: value.title.split("")[0],
                                    },
                                  });
                                }}
                                shadow="sm"
                                padding="sm"
                                radius="md"
                                withBorder
                                style={{
                                  textAlign: "center",
                                  backgroundColor: `${
                                    customQr.qrOptions.errorLevel ===
                                    value.title.split("")[0]
                                      ? "#1357be"
                                      : ""
                                  }`,
                                  color: `${
                                    customQr.qrOptions.errorLevel ===
                                    value.title.split("")[0]
                                      ? "white"
                                      : "black"
                                  }`,
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "0.8rem",
                                }}
                              >
                                <i className={value.icon}></i>
                                {value.title}
                              </Card>
                            </Grid.Col>
                          );
                        })}
                      </Grid>
                    </div>
                    <Flex gap={"1.2rem"}>
                      <div>
                        <Input.Wrapper>
                          <NumberInput
                            value={customQr.format.width}
                            label="Width"
                            min={200}
                            max={300}
                            onChange={(e) => {
                              setCustomQr({
                                ...customQr,
                                format: {
                                  ...customQr.format,
                                  width: e,
                                },
                              });
                            }}
                          />
                        </Input.Wrapper>
                      </div>
                      <div>
                        <Input.Wrapper>
                          <NumberInput
                            value={customQr.format.height}
                            onChange={(e) => {
                              setCustomQr({
                                ...customQr,
                                format: {
                                  ...customQr.format,
                                  height: e,
                                },
                              });
                            }}
                            min={200}
                            max={300}
                            label="Height"
                          />
                        </Input.Wrapper>
                      </div>
                      <div>
                        <Input.Wrapper>
                          <NumberInput
                            value={customQr.format.margin}
                            onChange={(e) => {
                              setCustomQr({
                                ...customQr,
                                format: {
                                  ...customQr.format,
                                  margin: e,
                                },
                              });
                            }}
                            min={0}
                            max={5}
                            label="Margin"
                          />
                        </Input.Wrapper>
                      </div>
                    </Flex>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div>
            {customQr.data ? (
              <>
                <div
                  ref={ref}
                  onContextMenu={preventRightClick}
                  className="qr-code-generator-container-qr-code"
                />
              </>
            ) : (
              <Flex align={"center"} justify={"center"}>
                <Text>No Data to generate QR Code</Text>
              </Flex>
            )}
            {!primeUser && (
              <Center mt={"xl"}>
                <Flex justify={"center"} align={"center"} direction={"column"}>
                  <Text>
                    Subscribe Plans to unlock Additional features and More Qr
                    Types
                  </Text>
                </Flex>
              </Center>
            )}
            <Center mt={"xl"}>
              <Flex gap={"1rem"}>
                <Select
                  disabled={(userData?.prime_user == 3 && true) || !primeUser}
                  // disabled={}
                  onChange={(e) => {
                    setCustomQr({
                      ...customQr,
                      format: {
                        ...customQr.format,
                        type: e,
                      },
                    });
                  }}
                  w={"100px"}
                  size="sm"
                  defaultValue={"PNG"}
                  data={["PNG", "WEBP", "JPG", "SVG"]}
                />

                <Button
                  // disabled={userData?.prime_user == 3 && true}
                  style={{ backgroundColor: "#1357be" }}
                  onClick={onDownloadClick}
                >
                  Download Qr Code
                </Button>
                {!media570px ? (
                  <Button
                    style={{
                      backgroundColor: "#1357be",
                    }}
                    onClick={handileSubmit}
                  >
                    Save Qr Code
                  </Button>
                ) : (
                  ""
                )}
              </Flex>
            </Center>
            <Center>
              {media570px ? (
                <Button
                  mt={"lg"}
                  style={{
                    backgroundColor: "#1357be",
                  }}
                  onClick={!primeUser ? handleNavigateToPricing : handileSubmit}
                >
                  Save Qr Code
                </Button>
              ) : (
                ""
              )}
            </Center>
            <Center mt={"xl"}>
              <Text w={"400px"} style={{ textAlign: "center" }}>
                <Flex align={"baseline"} gap={"0.3rem"}>
                  <InfoCircle size={"1rem"} />
                  Make sure to test out your configured QR code before using it
                  in production.
                </Flex>
              </Text>
            </Center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCodeGenerator;
