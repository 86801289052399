import { ThemeIcon } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { CircleCheck, X } from "tabler-icons-react";
import { registerUser } from "../../Config/Quries/User/userQuries";
import { setUserData } from "../../StateHandler/Slice/userData/userDataSlice";

export const LoginUserControl = async (
  loginDetails,
  setLoginDetails,
  loginDetailsValidation,
  setLoginDetailsValidation,
  setOtpStatus,
  setUserId
) => {
  const { firstName, email, password, otp } = loginDetails;
  if (firstName.trim()) {
    if (email.trim()) {
      if (email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        var body = {
          firstname: firstName,
          email: email,
        };
        registerUser(body).then((result) => {
          setOtpStatus(1);
          if (result.data.status === "success") {
            setUserId(result.data.user.id);
          }
        });
      } else {
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="red">
              <X color="red" />
            </ThemeIcon>
          ),
          message: "Please enter valid email",
        });
        setLoginDetailsValidation({
          ...loginDetailsValidation,
          email: 2,
        });
      }
    } else {
      showNotification({
        icon: (
          <ThemeIcon variant="light" radius="xl" size="xl" color="red">
            <X color="red" />
          </ThemeIcon>
        ),
        message: "Email is Complusory",
      });
      setLoginDetailsValidation({
        ...loginDetailsValidation,
        email: 1,
      });
    }
  } else {
    showNotification({
      icon: (
        <ThemeIcon variant="light" radius="xl" size="xl" color="red">
          <X color="red" />
        </ThemeIcon>
      ),
      message: "Name is Complusory",
    });
    setLoginDetailsValidation({
      ...loginDetailsValidation,
      firstName: 1,
    });
  }
};

export const verifyotpAuth = (
  loginDetails,
  setLoginDetails,
  loginDetailsValidation,
  setLoginDetailsValidation,
  setOtpStatus,
  userId,
  setUserId
) => {
  const { firstName, email, password, otp } = loginDetails;
  if (otp.trim()) {
    setOtpStatus(2);
    var body = {
      id: userId,
      otp: otp,
      email: email,
    };
    registerUser(body).then((result) => {
      if (result.data.status === "success") {
        setUserId(result.data.user.id);
      }
    });
  } else {
    showNotification({
      icon: (
        <ThemeIcon variant="light" radius="xl" size="xl" color="red">
          <X color="red" />
        </ThemeIcon>
      ),
      message: "OTP is Complusory",
    });
    setLoginDetailsValidation({
      ...loginDetailsValidation,
      otp: 1,
    });
  }
};
export const signUpFunction = (
  loginDetails,
  setLoginDetails,
  loginDetailsValidation,
  setLoginDetailsValidation,
  setOtpStatus,
  userId,
  setUserId,
  dispatch,
  navigate
) => {
  const { firstName, email, password } = loginDetails;
  if (password.trim()) {
    // setOtpStatus(2);
    var body = {
      id: userId,
      password: password,
    };
    registerUser(body).then((result) => {
      if (result.data.status === "success") {
        setUserId(result.data.user.id);
        localStorage.setItem("user_id", result.data.user.id);
        localStorage.setItem("user_name", result.data.user.firstname);
        localStorage.setItem("user_email", result.data.user.email);
        localStorage.setItem("user_vcard", result.data.user.vcard_id);

        dispatch(setUserData(result.data.user));
        showNotification({
          icon: (
            <ThemeIcon variant="light" radius="xl" size="xl" color="green">
              <CircleCheck color="green" />
            </ThemeIcon>
          ),
          message: "User Registered Successfully",
        });
        navigate("/");
      }
    });
  } else {
    showNotification({
      icon: (
        <ThemeIcon variant="light" radius="xl" size="xl" color="red">
          <X color="red" />
        </ThemeIcon>
      ),
      message: "OTP is Complusory",
    });
    setLoginDetailsValidation({
      ...loginDetailsValidation,
      otp: 1,
    });
  }
};
