import React from "react";

// CSS
import "./Cardstype.css";

// Mantine COre
import { Grid, Card, Title, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const Cardstype = ({ data }) => {

  const media760Query = useMediaQuery("(max-width:760px)");
  const media500Query = useMediaQuery("(max-width:500px)");

  return (
    <div>
      <div className="cardstype-container">
        <Grid gutter={"lg"}>
          {data.map((value, index) => {
            return (
              <Grid.Col key={index} span={media500Query ? 12 : media760Query ? 6  : 4}>
                <Card
                  mih={"40vh"}
                  mah={"70vh"}
                  shadow="sm"
                  padding="0"
                  radius="md"
                  withBorder
                >
                  <Card.Section
                    style={{
                      width: "100%",
                      height: "25vh",
                    }}
                  >
                    <img
                      src={value.image}
                      alt="Templates"
                      width={"100%"}
                      height={"100%"}
                      style={{ objectFit: "contain" }}
                    />
                  </Card.Section>
                  <Title order={3} pl={"lg"}>
                    {value.title}
                  </Title>
                  <Text pl={"md"} pt={"sm"}>
                    {value.desc}
                  </Text>
                </Card>
              </Grid.Col>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default Cardstype;
