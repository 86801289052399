import {
  Accordion,
  Button,
  FileInput,
  Flex,
  Grid,
  Input,
  Select,
  Text,
  Textarea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useState } from "react";
import { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { MdDeleteOutline } from "react-icons/md";
import { CiImageOn } from "react-icons/ci";

import {
  BrandFacebook,
  BrandInstagram,
  BrandLinkedin,
  BrandTwitter,
  BrandYoutube,
  CirclePlus,
  Phone,
  Social,
  X,
} from "tabler-icons-react";
import { MdDelete } from "react-icons/md";
import Fileinput from "../../Cropper/Fileinput";
import ImageCropper from "../../../Components/Cropper/ImageCropper";
import { Modal, Drawer, Image, Spin } from "antd";
import { UploadImage } from "../../../Config/Quries/Qrcode/QrcodeQuries";
import config from "../../../Config/Server/Server";
import {
  errorNotification,
  successNotification,
} from "../../Notification/notify";
import _ from "lodash";

const Services = (properties) => {
  const {
    handleAddServices,
    services,
    handleRemoveService,
    handleServiceChange,
    handleRemoveImage,
    load,
    loadImages,
  } = properties;
  const [open, setOpen] = useState();

  let base_URL = config.baseAPI;

  const handleImageUpload = (file, id) => {
    try {
      loadImages(true);
      if (file.size > 2000000) {
        loadImages(false);
        return errorNotification("Upload service image under 2mb");
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async (result) => {
          let formdata = {
            service_image: result.target.result,
          };
          const result1 = await UploadImage(formdata);

          handleServiceChange(result1.data.path, id, "img");
          successNotification("image uploded successfully");
        };
      }
      loadImages(false);
    } catch (err) {
      loadImages(false);
    }
  };

  return (
    <>
      <Accordion.Item value="vcard_phone_number">
        <Accordion.Control icon={<Phone />}>Services</Accordion.Control>
        <Accordion.Panel>
          <div
            onClick={() => {
         
              setOpen(true);
            }}
            className="flex items-center gap-x-2 justify-center cursor-pointer border !text-sm  px-2 py-2 border-dashed rounded text-black"
          >
            <CirclePlus size={16} />
            <span className="!text-sm"> Add Services</span>
          </div>
        </Accordion.Panel>
      </Accordion.Item>
      <Drawer
        title="Add Services"
        destroyOnClose
        // closeIcon={false}
        open={open}
        width={500}
        extra={
          <div
            onClick={handleAddServices}
            className="capitalize !text-sm flex items-center gap-x-2 bg-primaryColor px-2 cursor-pointer py-1 rounded text-white"
          >
            <CirclePlus size={16} /> add more
          </div>
        }
        onClose={() => {
          setOpen(false);
        }}
      >
        <Spin spinning={load} className="flex  gap-y-6 min-h-[100vh]">
          {_.get(services, "current", [])
            .sort((a, b) => b.id - a.id)
            .map((res, index) => {
              return (
                <div className="flex border shadow !items-center flex-col w-full justify-between gap-x-4 p-4 rounded-lg border-none">
                  <Input.Wrapper
                    pb={"sm"}
                    label={
                      <Text className="text-sm px-1 py-1">
                        Service name {index + 1}
                      </Text>
                    }
                    className="!w-full"
                  >
                    <Input
                      radius={"md"}
                      onChange={(e) => {
                        handleServiceChange(e.target.value, res.id, "name");
                      }}
                      value={res.name}
                    />
                  </Input.Wrapper>
                  <Input.Wrapper
                    pb={"sm"}
                    label={
                      <Text className="text-sm px-1 py-1">
                        description {index + 1}
                      </Text>
                    }
                    className="!w-full"
                  >
                    <Textarea
                      radius={"md"}
                      onChange={(e) => {
                        handleServiceChange(
                          e.target.value,
                          res.id,
                          "description"
                        );
                      }}
                      value={res.description}
                    />
                  </Input.Wrapper>
                  <Input.Wrapper
                    classNames="!w-full"
                    label={
                      <Text className="text-sm px-1 py-1 flex items-center gap-x-2 ">
                        Image {index + 1}{" "}
                        {res.img !== "" && (
                          <MdDeleteOutline
                            className="cursor-pointer !text-red-500"
                            onClick={() => {
                              handleServiceChange("", res.id, "remove");
                            }}
                          />
                        )}{" "}
                      </Text>
                    }
                    className="!w-full"
                  >
                    {res.img ? (
                      <div className="!w-full">
                        <Image
                          src={`${base_URL}/${res.img}`}
                          className="!w-[100px] !h-[100px] !border !shadow-inner"
                        />
                      </div>
                    ) : (
                      <FileInput
                        className="!w-[100px] !rounded !h-auto"
                        onChange={(e) => {
                          handleImageUpload(e, res.id);
                        }}
                        style={{
                          border: "1px dashed #1357be",
                          height: "200px",
                          color: "black",
                        }}
                        value={""}
                        placeholder={
                          <div className="!text-black"> + upload</div>
                        }
                      >
                        f
                      </FileInput>
                    )}
                  </Input.Wrapper>
                  <Input.Wrapper
                    classNames="!w-full"
                    label={
                      <Text className="text-sm px-1 py-1">
                        {/* Action {index + 1} */}
                      </Text>
                    }
                    className="!w-full"
                  >
                    <div
                      onClick={() => {
                        handleRemoveService(res.id);
                      }}
                      className="border !w-full py-2 group hover:border-red-500 transition-all duration-700 cursor-pointer gap-x-2 rounded-lg flex items-center justify-center"
                    >
                      <MdDeleteOutline className="cursor-pointer !text-red-500 " />
                      <span className="!text-sm"> Remove</span>
                    </div>
                  </Input.Wrapper>
                </div>
              );
            })}
        </Spin>
      </Drawer>
    </>
  );
};

export default Services;
