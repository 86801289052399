import React, { useEffect, useState } from "react";

import { useParams, useLocation } from "react-router-dom";
import "./Vcard.css";

// Query
import { useQuery } from "react-query";
import { vCardQuery } from "../../Config/Quries/Vacrd/VcardQuery";
import Vcard1 from "./Vcard1";

import { FaRegSave } from "react-icons/fa";

import useClipboard from "react-use-clipboard";
import config from "../../Config/Server/Server";
import _ from "lodash";
import { PiSuitcaseSimpleBold } from "react-icons/pi";
import {
  FaGlobeAmericas,
  FaLinkedin,
  FaRegBuilding,
  FaWhatsapp,
} from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
import { MdFacebook, MdOutlineMail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { GrLocation } from "react-icons/gr";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";
import {
  QRCode,
  Image,
  Card,
  Descriptions,
  Avatar,
  Form,
  Input,
  Button,
  notification,
  DatePicker,
  TimePicker,
  Modal,
  message,
} from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { FaRegCopy } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { HiOutlineGlobeAlt } from "react-icons/hi";

import {
  makeAppoinment,
  sendMessageFromVcardProfile,
} from "../../Config/Quries/Vacrd/VcardQuery";
import { Helmet } from "react-helmet";
import moment from "moment";
import dayjs from "dayjs";
import Vcard2 from "./Vcard2";
import Vcard3 from "./Vcard3";
import { ContactsOutlined, PhoneOutlined } from "@ant-design/icons";

const Vcard = () => {
  // Scroll to TOP
  window.scrollTo(0, 0);

  // Params
  const paramsId = useParams([]);

  // vcard data
  const [vCardData, setVcard] = useState([]);
  const [vCardDataUI, setVcardUI] = useState([]);

  useQuery(["vacrdData", paramsId.id], vCardQuery, {
    onSuccess: (res) => {
      try {
        setVcard(res.data[0]);

        setVcardUI(JSON.parse(res.data[0].formData));
      } catch (err) {}
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const handleSaveVCard = () => {
    const imageUrl = `${base_URL}/${_.get(
      vCardDataUI,
      "business_profile_image",
      ""
    )}`;
    const corsProxyUrl = "https://api.allorigins.win/raw?url=";

    setIsLoading(true);

    fetch(corsProxyUrl + imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          const imageData = reader.result.split(",")[1];
          const vcardWithImage =
            `BEGIN:VCARD\nVERSION:3.0\n` +
            `N:${_.get(vCardDataUI, "business_first_name", "")};${_.get(
              vCardDataUI,
              "business_first_name",
              ""
            )};;;\n` +
            `FN:${_.get(vCardDataUI, "business_first_name", "")} ${_.get(
              vCardDataUI,
              "business_last_name",
              ""
            )}\n` +
            `TEL;CELL:${_.get(vCardDataUI, "business_phone_number", "")}\n` +
            `EMAIL;HOME:${_.get(vCardDataUI, "business_email_address", "")}\n` +
            `ADR;HOME:${_.get(
              vCardDataUI,
              "business_street_address",
              ""
            )}, ${_.get(vCardDataUI, "business_city_name", "")}, ${_.get(
              vCardDataUI,
              "business_country_name",
              ""
            )}, ${_.get(vCardDataUI, "business_zip_code", "")}\n` +
            `PHOTO;TYPE=JPEG;ENCODING=BASE64:${imageData}\n` +
            `URL:${_.get(vCardDataUI, "business_web_url", "")}\n` +
            `END:VCARD`;

          const blobWithImage = new Blob([vcardWithImage], {
            type: "text/vcard",
          });
          const urlWithImage = URL.createObjectURL(blobWithImage);

          const newLink = document.createElement("a");
          newLink.href = urlWithImage;
          newLink.download = `${_.get(
            vCardDataUI,
            "business_first_name",
            ""
          )}_contact.vcf`;
          newLink.click();

          setIsLoading(false);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) =>
        console.error("Error fetching or encoding image:", error)
      );
  };

  // fbgk
  let base_URL = config.baseAPI;

  const location = useLocation();

  const [read, setRead] = useState(false);
  const [copy, setCopy] = useState(false);

  const [reads, setReads] = useState({
    title: "",
    content: "",
  });

  useEffect(() => {
    document.title = `${_.get(vCardDataUI, "business_first_name", "")} ${_.get(
      vCardDataUI,
      "business_last_name",
      ""
    )}`.toUpperCase();

    let imgUrl = `${base_URL}/${_.get(
      vCardDataUI,
      "business_profile_image",
      ""
    )}`;

    const faviconLink = document.querySelector("link[rel~='icon']");
    if (faviconLink) {
      faviconLink.href = imgUrl;
    }
    const ogImageTag = document.querySelector('meta[property="og:image"]');
    if (ogImageTag) {
      ogImageTag.setAttribute("content", imgUrl);
    }
  }, [_.get(vCardDataUI, "business_profile_image", ""), base_URL]);

  const handleRead = (res, field) => {
    if (field === "testimonials") {
      return setReads((pre) => ({
        ...pre,
        title: res.client,
        content: res.review,
      }));
    }
    setReads((pre) => ({
      ...pre,
      title: res.name,
      content: res.description,
    }));
  };

  const handleCancel = (res) => {
    setReads((pre) => ({
      ...pre,
      title: "",
      content: "",
    }));
  };

  const [oppoinments, setOpponments] = useState({
    appoinment_date: dayjs(
      moment(Date.now()).format("DD/MM/YYYY"),
      "DD/MM/YYYY"
    ),
    appoinment_time: moment(new Date(), "H:mm A"),
  });

  const contact = [
    {
      id: 1,
      icon: MdOutlineMail,
      title: "E-mail address",
      subTitle: _.get(vCardDataUI, "business_email_address", ""),
    },
    {
      id: 2,
      icon: PhoneOutlined,
      title: "Mobile Number",
      subTitle: _.get(vCardDataUI, "business_phone_number", ""),
    },
    {
      id: 3,
      icon: GrLocation,
      title: "Location",
      subTitle: `${_.get(vCardDataUI, "business_street_address", "")}, ${_.get(
        vCardDataUI,
        "business_city_name",
        ""
      )}, ${_.get(vCardDataUI, "business_country_name", "")}, ${_.get(
        vCardDataUI,
        "business_zip_code",
        ""
      )}`,
    },
    {
      id: 2,
      icon: HiOutlineGlobeAlt,
      title: "Website",
      subTitle: _.get(vCardDataUI, "business_web_url", "f"),
    },
  ];

  const social = [
    {
      id: 1,
      icon: <FaInstagram />,
      name: `instagram`,
    },
    {
      id: 2,
      icon: <FaXTwitter />,
      name: `twitter`,
    },
    {
      id: 3,
      icon: <MdFacebook />,
      name: `facebook`,
    },
    {
      id: 4,
      icon: <CiYoutube />,
      name: `youtube`,
    },
    {
      id: 5,
      icon: <FaLinkedin />,
      name: `linkedin`,
    },
  ];

  const getSocialIcon = (field) => {
    return social.filter((res) => {
      return res.name === field;
    });
  };

  const getAMPM = (value) => {
    try {
      if (value.split(":")[0] === "00") {
        return `${12}:${value.split(":")[1]} AM`;
      } else if (value.split(":")[0] === "12") {
        return `${value.split(":")[0]}:${value.split(":")[1]} PM`;
      } else if (value.split(":")[0] > 12) {
        if (value.split(":")[0] - 12 >= 9) {
          return `0${value.split(":")[0] - 12}:${value.split(":")[1]} PM`;
        }
        return `${value.split(":")[0] - 12}:${value.split(":")[1]} PM`;
      } else {
        return `${value} AM`;
      }
    } catch (err) {}
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("myqrcode")?.querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const handleCopy = async () => {
    setCopy(true);
    await navigator.clipboard.writeText(
      `https://www.qrcodeweboney.in/#${location.pathname}`
    );
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  const handleApoinmentFinish = async (values) => {
    try {
      const formData = {
        ...values,
        user_id: _.get(vCardData, "user_id", ""),
      };

      await makeAppoinment(formData);
      form1.resetFields();
      notification.success({ message: "Appoinments Uploded successfully" });
    } catch (err) {}
  };

  const handleOkay = (values, field) => {
    if (field === "appoinment_date") {
      setOpponments((pre) => ({ ...pre, appoinment_date: values }));
      form1.setFieldValue((pre) => ({ ...pre, appoinment_date: values }));
    } else {
      setOpponments((pre) => ({ ...pre, appoinment_time: values }));
      form1.setFieldValue((pre) => ({ ...pre, appoinment_time: values }));
    }
  };

  const viewCard = () => {
    let pass = {
      vCardData,
      handleSaveVCard,
      base_URL,
      location,
      handleOkay,
      handleApoinmentFinish,
      handleCopy,
      downloadQRCode,
      form,
      form1,
      contact,
      social,
      read,
      setRead,
      copy,
      setCopy,
      reads,
      setReads,
      handleRead,
      vCardDataUI,
      getSocialIcon,
      getAMPM,
    };

    switch (_.get(vCardData, "vcard_id", "")) {
      case "1": {
        return <Vcard1 data={pass} />;
        break;
      }
      case "2": {
        return <Vcard2 data={pass} />;
        break;
      }
      case "3": {
        return <Vcard3 data={pass} />;
        break;
      }
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`${_.get(vCardDataUI, "business_first_name", "")} ${_.get(
            vCardDataUI,
            "business_last_name",
            ""
          )}`.toUpperCase()}
        </title>
        <link
          rel="icon"
          href={`${base_URL}/${_.get(
            vCardDataUI,
            "business_profile_image",
            ""
          )}`}
        />
        <meta property="og:title" content="ABC Blabla 2020 Friday" />
        <meta property="og:url" content={_.get(vCardData, "data", "")} />
        <meta property="og:description" content="Photo Album" />
        <meta
          property="og:image"
          itemprop="image"
          content={`${base_URL}/${_.get(
            vCardDataUI,
            "business_profile_image",
            ""
          )}`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="en_GB" />
      </Helmet>
      {viewCard()}
      <Modal
        open={reads.title}
        footer={false}
        closable={false}
        title={
          <p className="!text-[16px] !text-vcard2Primarybg !tracking-wide !font-Poppins">
            {reads.title}
          </p>
        }
        onCancel={handleCancel}
      >
        <p className="font-Poppins text-[16px] leading-loose">
          {reads.content}
        </p>
      </Modal>
    </div>
  );
};

export default Vcard;
