import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";

// Mantine CSS
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";

// Mantine Provider
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";

// Pages
import App from "./App";

// Redux State Handler
import { Provider } from "react-redux";
import { store } from "./StateHandler/Store/Store";

// Mantine Date CSS
import "@mantine/dates/styles.css";

// React Query
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <MantineProvider>
          <ModalsProvider>
            <Provider store={store}>
              <Notifications
                position="top-right"
                zIndex={10}
                autoClose={2200}
                withBorder
              />
              <App />
            </Provider>
          </ModalsProvider>
        </MantineProvider>
      </QueryClientProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
