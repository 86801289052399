import React from "react";
import { FaExternalLinkAlt, FaRegBuilding, FaRegCopy, FaRegSave } from "react-icons/fa";
import { PiSuitcaseSimpleBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { Image, Card, Modal, Form, Input, DatePicker, TimePicker, Button, QRCode } from "antd";
import _ from "lodash";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { RxDotFilled } from "react-icons/rx";
import { useState } from "react";
import CustomVcard from "./CustomVcard";

const Vcard2 = (properties) => {
  const { handleSaveVCard, vCardData, base_URL, location, handleOkay, handleApoinmentFinish, handleCopy, downloadQRCode, form, form1, contact, social, read, setRead, copy, setCopy, reads, setReads, handleRead, vCardDataUI, getSocialIcon, getAMPM } = properties.data;

  return (
    <div className="w-screen min-h-screen flex flex-col gap-y-6 items-center !bg-vcard2Primarybg pb-10 !font-Poppins">
      {/* hero */}
      <div className="center_div flex-col w-full items-center gap-y-2">
        <div
          style={{
            background: `url(${base_URL}/${_.get(vCardDataUI, "business_banner_image", "")})`,
          }}
          className={`w-[100%] h-[30vh] !bg-no-repeat bg-center !bg-cover   `}
        ></div>
        <div className="w-[100px] h-[100px] self-end  rounded-full -mt-[50px] mr-5 z-50">
          <Image src={`${base_URL}/${_.get(vCardDataUI, "business_profile_image", "")}`} alt="" className="!w-[100px] !h-[100px] !rounded-full !object-cover  border-4 border-vcard2PrimaryText" />
        </div>
        <div className="self-start px-4 -mt-10 !text-vcard2PrimaryText flex flex-col gap-y-2">
          <h1 className="text-xl font-bold capitalize !font-Poppins">
            {_.get(vCardDataUI, "business_first_name", "")}&nbsp;
            {_.get(vCardDataUI, "business_last_name", "")}
          </h1>
          <h1 className="text-md flex items-center gap-x-2 capitalize text-white">
            <PiSuitcaseSimpleBold />
            {_.get(vCardDataUI, "business_job_title", "")}
          </h1>
          <h1 className="text-md  flex items-center gap-x-2 capitalize text-white">
            <FaRegBuilding />
            {_.get(vCardDataUI, "business_company_name", "")}
          </h1>
        </div>
        <span className="!leading-loose  text-white  !text-sm px-4 pt-2">
          {_.get(vCardDataUI, "business_about_me", "")?.slice(0, read ? _.get(vCardDataUI, "business_about_me", "").length : 200)}
          &nbsp;
          <span
            onClick={() => {
              setRead(!read);
            }}
            className={`!border-none  ${_.get(vCardDataUI, "business_about_me", "").length > 200 ? "visible" : "visible"} !bg-transparent text-sm !text-vcard2PrimaryText`}
          >
            Read {!read ? "more" : "less"}
          </span>
        </span>
        {/* social */}
        <div className="self-start px-4 flex py-4 gap-x-5">
          {_.get(vCardDataUI, "social_media", "").map((res, index) => {
            return (
              <Link to={res.to} target="_blank" key={index} className="size-[25px]  text-vcard2Primarybg  bg-vcard2PrimaryText rounded center_div">
                {getSocialIcon(res.social)[0].icon}
              </Link>
            );
          })}
        </div>
      </div>
      {/* Contact */}
      <div className="vcard2_heading">
        Contact US <div className="!w-[50px] !h-[2px] !bg-white "></div>
      </div>
      <div className="flex flex-col w-[90%]  items-center  mx-auto">
        {contact.map((res, index) => {
          return (
            <div key={index} className="!w-full  border-2 gap-x-2 p-2 text-white  border-vcard2Primarybg flex items-start">
              <div className="size-[30px] center_div text-vcard2Primarybg rounded-lg bg-vcard2PrimaryText">
                <res.icon className="!text-xl    rounded-full" />
              </div>
              <div className=" flex flex-col gap-y-1">
                <p className="!text-ellipsis  !line-clamp-1">{res.title}</p>
                <p className="!text-sm !leading-loose !text-ellipsis  !font-normal !font-sans text-start whitespace-pre-line">{res.subTitle}&nbsp;</p>
              </div>
            </div>
          );
        })}
      </div>
      {/* services  */}
      <div className="vcard2_heading">
        Our Services <div className="!w-[50px] !h-[2px] !bg-white "></div>
      </div>
      <div className="w-full flex items-center min-h-[20vh] ">
        <Swiper modules={[Pagination, Autoplay]} className="mySwiper  w-[100%]" autoplay={true} slidesPerView={2} spaceBetween={10} loop centeredSlides>
          {_.get(vCardDataUI, "services", []).map((res, index) => {
            return (
              <SwiperSlide key={index} className="!w-[80%]">
                <div className="!w-full h-[400px] bg-white !shadow-2xl border-2 rounded-lg border-vcard2Primarybg">
                  <img src={`${base_URL}/${res.img}`} alt="" className="!h-[200px] !w-full !object-cover rounded-md" />
                  <div className="p-2 flex flex-col gap-y-2">
                    <p className="!text-[16px] pt-2 text-black font-bold tracking-wide flex items-center ">
                      <RxDotFilled className="!text-vcard2Primarybg !text-2xl" />
                      &nbsp;
                      <p className="!text-ellipsis  !line-clamp-1">{res.name}</p>
                    </p>
                    <p className="!text-sm !leading-loose text-vcard2Primarybg pl-2  whitespace-pre-line">
                      {res.description.slice(0, 100)}&nbsp;{" "}
                      <span
                        className="!text-[10px] !text-vcard2Primarybg"
                        onClick={() => {
                          handleRead(res);
                        }}
                      >
                        Read more
                      </span>
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {/* Testimonials */}
      <div className="vcard2_heading">
        Textimonials <div className="!w-[50px] !h-[2px] !bg-white "></div>
      </div>
      <div className="w-full flex items-center min-h-[20vh] ">
        <Swiper modules={[Pagination, Autoplay]} className="mySwiper  w-[100%]" autoplay={true} slidesPerView={2} spaceBetween={10} loop centeredSlides>
          {_.get(vCardDataUI, "testimonials", []).map((res, index) => {
            return (
              <SwiperSlide key={index} className="!w-[80%]">
                <div className="!w-full h-[200px] bg-white shadow-2xl border-2 rounded-lg border-vcard2Primarybg flex items-start p-2 relative">
                  <div className=" flex flex-col gap-y-2">
                    <p className="!text-[16px] pt-2 text-vcard2Primarybg font-bold tracking-wide flex items-center ">
                      &nbsp;
                      <p className="!text-ellipsis  !line-clamp-1">{res.name}</p>
                    </p>
                    <p className="!text-sm !leading-loose text-black pl-2 text-start whitespace-pre-line">
                      {res.review.slice(0, 100)}&nbsp;
                      <span
                        className="!text-[10px] !text-vcard2Primarybg"
                        onClick={() => {
                          handleRead(res, "testimonials");
                        }}
                      >
                        Read more
                      </span>
                    </p>
                  </div>
                  <img src={`${base_URL}/${res.img}`} alt="" className="!size-[50px] !object-cover rounded-full" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {/* Business Hours */}
      <div className="vcard2_heading">
        Business Hours <div className="!w-[50px] !h-[2px] !bg-white "></div>
      </div>
      <div className="w-[90%] bg-vcard2Primarybg shadow-md border-2 border-white min-h-[100px]  flex flex-col gap-y-5 py-4 rounded-lg divide-y-1">
        {_.get(vCardDataUI, "business_hours", []).map((res, index) => {
          return (
            <div className="flex items-center justify-between px-4">
              <div className=" tracking-wide !text-center text-white uppercase">{res.day?.slice(0, 3)}</div>

              {res.status ? (
                <div className="flex gap-x-2 text-white">
                  <div>{getAMPM(res.from)}</div>
                  <p>-</p>
                  <div>{getAMPM(res.to)}</div>
                </div>
              ) : (
                <div className="!text-white">Closed</div>
              )}
            </div>
          );
        })}
      </div>
      {/* Make an Appoinment */}
      <div className="vcard2_heading">
        Make an Appoinment <div className="!w-[50px] !h-[2px] !bg-white "></div>
      </div>
      <div className="w-[90%] min-h-[100px] ">
        <Form form={form1} className="w-full mx-auto" layout="vertical" onFinish={handleApoinmentFinish}>
          <Form.Item label={<span className="!text-sm  !font-sans !text-white">Name</span>} name="name" rules={[{ required: true, message: "Enter name" }]}>
            <Input className="!bg-white !h-[35px] placeholder:!text-white" />
          </Form.Item>
          <Form.Item label={<span className="!text-sm  !font-sans !text-white">Mobile Number</span>} name="contact_number" rules={[{ required: true, message: "Enter mobile number" }]}>
            <Input className="!w-full !bg-white !h-[35px] placeholder:!text-black" />
          </Form.Item>

          <Form.Item
            label={
              <span className="!text-sm  !font-sans !text-white">
                <span className="text-red-500">*</span> Select Date
              </span>
            }
            name="appoinment_date"
          >
            <DatePicker
              onOk={(e) => {
                handleOkay(e, "appoinment_date");
              }}
              needConfirm
              className="!w-full !bg-white !h-[35px] placeholder:!text-black"
              format={"DD/MM/YYYY"}
            />
          </Form.Item>
          <Form.Item
            label={
              <span className="!text-sm  !font-sans !text-white">
                <span className="text-red-500">*</span> Select Time
              </span>
            }
            name="appoinment_time"
          >
            <TimePicker
              onOk={(e) => {
                handleOkay(e, "appoinment_time");
              }}
              className="!w-full !bg-white !h-[35px] placeholder:!text-white"
              format={"h:mm a"}
            />
          </Form.Item>
          <Form.Item className="pt-4">
            <Button block htmlType="submit" className="vcard2_button">
              <p className="!text-vcard2Primarybg">Make an Appoinment</p>
            </Button>
          </Form.Item>
        </Form>
      </div>
      {/* QR Download */}
      <div className="vcard2_heading">
        My QR Code <div className="!w-[50px] !h-[2px] !bg-white "></div>
      </div>
      <div className="flex !w-full items-center flex-col gap-y-4 ">
        <div id="myqrcode" className="!w-full center_div">
          <CustomVcard res={vCardData} color={"#0f2f3a"} bg={"#b8ff69"} />
        </div>
      </div>
      {/* create Card */}
      <div className="vcard2_heading">
        Create Your Card <div className="!w-[50px] !h-[2px] !bg-white "></div>
      </div>
      <div className="w-[90%] bg-white h-[50px] shadow-inner rounded-lg  center_div gap-x-4">
        <p className="!text-sm">https://www.qrcodeweboney.in/</p>
        <Link to="https://www.qrcodeweboney.in/" target="_blank">
          <FaExternalLinkAlt className="!text-vcard2Primarybg !" />
        </Link>
      </div>
      {/* share & download */}
      <div className="!flex items-center justify-center w-screen flex-col gap-y-4">
        <Button onClick={handleCopy} className={`!h-[50px] justify-between  !font-Poppins tracking-wide ${copy ? "!border-green-500 !text-green-500" : "!border-vcabg-vcard2PrimaryText !text-vcabg-vcard2PrimaryText"}   !bg-white center_div w-[90%] !text-[16px]`}>
          <p className="!text-vcard2Primarybg"> Share</p>
          <FaRegCopy className="!text-vcard2Primarybg" />
        </Button>
        <Button className="vcard2_button flex items-center gap-x-2 w-[90%] justify-between" onClick={handleSaveVCard}>
          {" "}
          <p className="!text-vcard2Primarybg">Save To Contact</p>
          <FaRegSave className="!text-vcard2Primarybg" />
        </Button>
      </div>
    </div>
  );
};

export default Vcard2;
