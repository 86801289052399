import { Input, Text, Textarea } from "@mantine/core";
import React from "react";

const EmailQr = ({ qrDetails, setQrDetails }) => {
  return (
    <div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper pb={"sm"} label={<Text pb={"sm"}>Email Address</Text>}>
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  email: {
                    ...qrDetails.email,
                    email_address: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.email.email_address}
          />
        </Input.Wrapper>
      </div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper
          pb={"sm"}
          label={<Text pb={"sm"}>Prefilled Subject</Text>}
        >
          <Input
            radius={"md"}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  email: {
                    ...qrDetails.email,
                    prefilled_subject: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.email.prefilled_subject}
          />
        </Input.Wrapper>
      </div>
      <div className="qrgenerator-div-content-form-individual">
        <Input.Wrapper
          pb={"md"}
          label={<Text pb={"sm"}>Prefilled Message</Text>}
        >
          <Textarea
            radius={"md"}
            autosize
            minRows={4}
            onChange={(e) => {
              {
                setQrDetails({
                  ...qrDetails,
                  email: {
                    ...qrDetails.email,
                    prefilled_message: e.target.value,
                  },
                });
              }
            }}
            value={qrDetails.email.prefilled_message}
          />
        </Input.Wrapper>
      </div>
    </div>
  );
};

export default EmailQr;
