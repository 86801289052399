import axios from "axios";
import config from "../../Server/Server";

export const vCardQuery = async ({ queryKey }) => {
  const [_, id] = queryKey;
  return await axios.get(`${config.baseAPI}/fetch_vcardqr.php?id=${id}`);
};

export const sendMessageFromVcardProfile = async (body) => {
  return await axios.post(`${config.baseAPI}/create_message.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getOneUserMessages = async (body) => {
  return await axios.post(`${config.baseAPI}/get_message.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const makeAppoinment = async (body) => {
  return await axios.post(`${config.baseAPI}/make_appoinment.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getOneAppoinment = async (body) => {
  return await axios.post(`${config.baseAPI}/get_appoinment.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};


export const saveVcardInstant = async (body) => {
  return await axios.post(`${config.baseAPI}/save_vcard.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const saveQRInstant = async (body) => {
  return await axios.post(`${config.baseAPI}/save_vcard.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateQRInstant = async (body) => {
  return await axios.post(`${config.baseAPI}/update_qr_data.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};


export const getSavedQrs = async (body) => {
  return await axios.post(`${config.baseAPI}/allqr_codes.php`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};